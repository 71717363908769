import React from 'react';
import CreatePoolSection from '../components/sections/CreatePool';

class CreatePool extends React.Component {
  render() {
    return (
      <CreatePoolSection className="illustration-section-01" />
    );
  }
}

export default CreatePool;