import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
// import Image from '../elements/Image';
import Typewriter from 'typewriter-effect';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroFull extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-hero full-window">
          <div className={innerClasses}>
            <div className="hero-content">
              <h1 className="mt-0 hero-title " data-reveal-delay="200">
                Turn your ideas
              </h1>
              <h1 className="mt-0 hero-title " data-reveal-delay="200">
                into <Typewriter
                  options={{
                    strings: ['a startup.', 'a success.', 'an adoption.'],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>
              <div className="container-xs">
                <p className="m-0 mt-16 mb-16 " data-reveal-delay="400">
                  MaticLaunch is  a decentralized crowdfunding platform for the next generation of ideas built on Polygon.
                </p>
                <div className=" button-container-hero" data-reveal-delay="500">
                  <Button tag="a" color="primary mr-16 d-none" href={require("../../assets/docs/litepaper.pdf")}>
                    Pitch Deck
                  </Button>
                  <Button tag="a" color="primary" href="https://quickswap.exchange/#/swap?inputCurrency=0x3642c3c8ebe0b36a29e37809e1c9a7de06bc0b26&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f" target="_blank">
                    BUY MTCL
                  </Button>
                  <a className="button button-primary-border" href="https://www.dextools.io/app/polygon/pair-explorer/0xa9368765a8855211e613715e5831e90ae1b22eb5" target="_blank">LIVE CHART</a>
                </div>
                <div className=" button-container-hero d-flex justify-content-start align-items-center" data-reveal-delay="500">
                  <a href="https://coinmarketcap.com/currencies/maticlaunch/" target="_blank"><img className="listing-logo" src={require('../../assets/images/logo_coinmarketcap.svg')} width="170" /></a>
                  <a href="https://www.coingecko.com/en/coins/maticlaunch" target="_blank"><img className="listing-logo" src={require('../../assets/images/logo_coingecko.png')} width="150" /></a>
                  {/* <Button tag="a" color="primary" href="https://quickswap.exchange/#/swap?inputCurrency=0x3642c3c8ebe0b36a29e37809e1c9a7de06bc0b26&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f" target="_blank">
                    BUY MTCL
                  </Button> */}
                  {/* <a className="button button-primary-border" href="https://www.dextools.io/app/polygon/pair-explorer/0xa9368765a8855211e613715e5831e90ae1b22eb5" target="_blank">LIVE CHART</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;