import React, { useCallback, useContext, useEffect, useState } from 'react'
import SectionHeader from './partials/SectionHeader';
import DotLoader from "react-spinners/DotLoader";
import { useHistory } from "react-router-dom";
import { stakingPools } from "../../constants/stakingPool"
import { nFormatter } from '../../utils/formatBalance';
import { Button, Form } from 'react-bootstrap';
import BigNumber from 'bignumber.js';
import { useMTCLAllowance, useMTCLApprove, useMTCLBalanceFetch } from '../../hooks/mtcl';
import { useStake, useStakingPoolFetch, useWithdrawStake } from '../../hooks/IDOStakingPool';
import { useInfoStakingFetch } from '../../hooks/info';
import { PropagateLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { Context } from '../../store/store';
import { getAddress } from '../../utils/addressHelpers';

function StakingSection(props: any) {
    let history = useHistory();
    const [state, dispatch] = useContext(Context);
    const [stakingPoolDetails, setStakingPoolDetails] = useState<any>();
    const [stakeFormValidity, setStakeFormValidity] = useState<'INVLAID_AMOUNT' | 'VALID'>('INVLAID_AMOUNT')
    const [unstakeFormValidity, setUnstakeFormValidity] = useState<'INVLAID_AMOUNT' | 'INVALID_TIME' | 'VALID'>('INVLAID_AMOUNT')
    const [tobeStaked, setTobeStaked] = useState<number>(0)
    const [tobeUnStaked, setTobeUnStaked] = useState<number>(0)
    const [requestedApproval, setRequestedApproval] = useState(false);
    const [requestedStake, setRequestedStake] = useState(false);
    const [approveTxhash, setApproveTxhash] = useState('');
    const [stakeTxhash, setStakeTxhash] = useState('');
    const [withdrawStakeTxhash, setWithdrawStakeTxhash] = useState('');
    const [requestedWithdrawStake, setRequestedWithdrawStake] = useState(false);

    const { onFetchStakingPoolDetails } = useStakingPoolFetch();
    const { onMTCLApprove } = useMTCLApprove();
    const { onMTCLBalanceFetch } = useMTCLBalanceFetch();
    const { onStake } = useStake();
    const { onWithdrawStake } = useWithdrawStake();
    const { onFetchInfoStakingPoolDetails } = useInfoStakingFetch()
    const { account, chainId } = useWeb3React()

    const allowance = useMTCLAllowance(getAddress(chainId!, 'staking'));


    const sectionHeader = {
        title: 'IDO Staking',
        paragraph: ''
    };

    const newApySectionHeader = {
        title: 'APY Staking [Live]',
        paragraph: ''
    };

    const apySectionHeader = {
        title: 'APY Staking [Expired]',
        paragraph: ''
    };


    const override = `
        display: block;
        margin: auto;
        border-color: red;
    `;

    useEffect(() => {
        console.log("callllll")
        fetchDetails()
    }, [account, chainId])

    const fetchDetails = useCallback(async (isLoader = true) => {
        try {
            if (isLoader) {
                dispatch({ type: 'SET_LOADING', payload: true })
            }
            let poolDetails = await onFetchStakingPoolDetails()
            let mtclBalance = await onMTCLBalanceFetch()
            let infoStakingDetails = await onFetchInfoStakingPoolDetails()
            if (isLoader) {
                dispatch({ type: 'SET_LOADING', payload: false })
            }
            let finalDetails = {
                ...poolDetails,
                ...infoStakingDetails,
                mtclBalance: mtclBalance && mtclBalance.balance
            }
            console.log(finalDetails)
            setStakingPoolDetails(finalDetails)
        } catch (e) {
            dispatch({ type: 'SET_LOADING', payload: false })
        }
    }, [onFetchStakingPoolDetails, onMTCLBalanceFetch])

    useEffect(() => {
        if (stakingPoolDetails && (tobeStaked == 0 || stakingPoolDetails.mtclBalance < tobeStaked || Number(stakingPoolDetails.minInvestorMTCLBalance) > (Number(tobeStaked) + stakingPoolDetails.stake))) {
            setStakeFormValidity('INVLAID_AMOUNT')
        } else {
            setStakeFormValidity('VALID')
        }
    }, [tobeStaked, stakingPoolDetails])

    useEffect(() => {
        console.log(stakingPoolDetails && stakingPoolDetails.minUnstakeTime, stakingPoolDetails && stakingPoolDetails.lastStakedTimestamp, (Date.now() / 1000))
        if (stakingPoolDetails && (tobeUnStaked == 0)) {
            setUnstakeFormValidity('INVLAID_AMOUNT')
        } else if (stakingPoolDetails && (Number(stakingPoolDetails.minUnstakeTime) + Number(stakingPoolDetails.lastStakedTimestamp) > (Date.now() / 1000))) {
            console.log('INVALID_TIME')
            setUnstakeFormValidity('INVALID_TIME')
        } else {
            setUnstakeFormValidity('VALID')
        }
    }, [tobeUnStaked, stakingPoolDetails])


    // useEffect(() => {
    //     if (stakingPoolDetails && (stakingPoolDetails.mtclBalance < tobeStaked || stakingPoolDetails.min > (Number(tobeStaked)))) {
    //         setFormValidity('INVLAID_AMOUNT')
    //     } else {
    //         setFormValidity('VALID')
    //     }
    //     if (stakingPoolDetails && stakingPoolDetails.mtclBalance === undefined && stakingPoolDetails.min > (Number(tobeStaked))) {
    //         setFormValidity('VALID')
    //     }
    // }, [tobeStaked, stakingPoolDetails])

    const onPoolDetailsClicked = function (poolId: number) {
        history.push(`/staking/${stakingPools[poolId].poolAddress}`)
    }

    const isApproveDone = function () {
        if (!allowance) return false
        if (new BigNumber(allowance)?.comparedTo(tobeStaked) === 1) {
            return true
        } else {
            return false
        }
    }

    const isUnstakeButtonActionInProgress = function () {
        if (requestedWithdrawStake) {
            return true
        } else {
            return false
        }
    }

    const isStakeButtonActionInProgress = function () {
        if (requestedStake || requestedApproval) {
            return true
        } else {
            return false
        }
    }

    const handleApprove = useCallback(async () => {
        try {
            if (requestedApproval) return
            // if (stakeFormValidity !== 'VALID') {
            //     showFormValidityToast(stakeFormValidity)
            //     return
            // }
            setRequestedApproval(true)
            let tx = await onMTCLApprove(getAddress(chainId!, 'staking'))
            if (tx) {
                toast.success("Approve request has been submitted")
                setApproveTxhash(tx.hash)
                let res = await tx.wait(1)
                setRequestedApproval(!(res.status))
            } else {
                setRequestedApproval(false)
            }
        } catch (e: any) {
            setRequestedApproval(false)
            toast.error(e.message)
        }
    }, [onMTCLApprove])

    const handleStake = useCallback(async () => {
        try {
            if (requestedApproval) return
            if (stakeFormValidity !== 'VALID') {
                showFormValidityToast(stakeFormValidity)
                return
            }
            setRequestedStake(true);
            let tx = await onStake(
                new BigNumber(tobeStaked).times(new BigNumber(10).pow(18).toString()).toString()
            )
            if (tx) {
                toast.success("Stake request has been submitted")
                setStakeTxhash(tx.hash)
                let res = await tx.wait(1)
                setTobeStaked(0)
                setRequestedStake(!(res.status))
                fetchDetails(false)
            } else {
                setRequestedStake(false)
            }
        } catch (e: any) {
            setRequestedStake(false)
            toast.error(e.message)
        }

    }, [onStake, stakeFormValidity, tobeStaked])

    const handleWithdrawStake = useCallback(async () => {
        try {
            if (requestedWithdrawStake) return
            if (unstakeFormValidity !== 'VALID') {
                showFormValidityToast(unstakeFormValidity)
                return
            }
            setRequestedWithdrawStake(true)
            let tx = await onWithdrawStake(
                new BigNumber(tobeUnStaked).times(new BigNumber(10).pow(18).toString()).toString()
            )
            if (tx) {
                toast.success("Claim request has been submitted")
                setWithdrawStakeTxhash(tx.hash)
                let res = await tx.wait(1)
                setTobeUnStaked(0)
                setRequestedWithdrawStake(!(res.status))
                fetchDetails(false)
            } else {
                setRequestedWithdrawStake(false)
            }
        } catch (e: any) {
            setRequestedWithdrawStake(false)
            toast.error(e.message)
        }
    }, [tobeStaked, onWithdrawStake, unstakeFormValidity, tobeUnStaked])

    const showFormValidityToast = function (formValidity: any) {
        switch (formValidity) {
            case 'INVLAID_AMOUNT':
                toast.error('Please enter valid token amount')
                break;
            case 'INVALID_TIME':
                toast.error(`Unstake can be done after ${secondsToDHMS((Number(stakingPoolDetails.minUnstakeTime) + Number(stakingPoolDetails.lastStakedTimestamp)) - (Date.now() / 1000))}`)
                break;
        }
    }

    // const secondsToHMS = function (seconds: any) {
    //     const h = Math.floor(seconds / 3600)
    //       .toString()
    //       .padStart(2, "0");
    //     const m = Math.floor((seconds % 3600) / 60)
    //       .toString()
    //       .padStart(2, "0");
    //     const s = Math.floor((seconds % 3600) % 60)
    //       .toString()
    //       .padStart(2, "0");

    //     return `${h}:${m}:${s}`;
    //   }

    const secondsToDHMS = function (seconds: any) {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 ? d + (d == 1 ? " Day, " : " Days, ") : "";
        var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " Minute, " : " Minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }


    return (
        <section className="section illustration-section-01">
            <div className="container-md staking-list-page">
                <SectionHeader tag="h3" data={newApySectionHeader} className="center-content pb-3 mt-32" />
                <div className="staking-table d-flex flex-column align-items-center mb-5">
                    <div className="staking-table-body">
                        <div className="staking-pool-header row">
                            <div className="table-item-header pool-name col-3">Pool</div>
                            {/* <div className="table-item-header pool-apy col-3">State</div> */}
                            <div className="table-item-header pool-size col-3">Size</div>
                            <div className="table-item-header pool-apy col-3">APY</div>
                            <div className="table-item-header pool-action col-3 details-btn-item"></div>
                        </div>
                        {/* -- New Pools starts -- */}
                        <div className="staking-pool-body row align-items-center">
                            <div className="table-item pool-name col-3 h6">{stakingPools[3].name}</div>
                            <div className="table-item pool-size col-3 h6">{nFormatter(stakingPools[3].size, 1)}</div>
                            <div className="table-item pool-apy col-3 h6">{stakingPools[3].apy}%</div>
                            <div className="table-item pool-action col-3 h6 details-btn-item">
                                <div className="details-btn button button-primary" onClick={() => {
                                    onPoolDetailsClicked(3)
                                }}>Details</div>
                            </div>
                            <div className="table-item pool-action col-3 details-btn-item-mobile">
                                <a onClick={() => {
                                    onPoolDetailsClicked(3)
                                }}>Details</a>
                            </div>
                        </div>
                        <div className="staking-pool-body row align-items-center">
                            <div className="table-item pool-name col-3 h6">{stakingPools[4].name}</div>
                            <div className="table-item pool-size col-3 h6">{nFormatter(stakingPools[4].size, 1)}</div>
                            <div className="table-item pool-apy col-3 h6">{stakingPools[4].apy}%</div>
                            <div className="table-item pool-action col-3 h6 details-btn-item">
                                <div className="details-btn button button-primary" onClick={() => {
                                    onPoolDetailsClicked(4)
                                }}>Details</div>
                            </div>
                            <div className="table-item pool-action col-3 details-btn-item-mobile">
                                <a onClick={() => {
                                    onPoolDetailsClicked(4)
                                }}>Details</a>
                            </div>
                        </div>
                        <div className="staking-pool-body row align-items-center">
                            <div className="table-item pool-name col-3 h6">{stakingPools[5].name}</div>
                            <div className="table-item pool-size col-3 h6">{nFormatter(stakingPools[5].size, 1)}</div>
                            <div className="table-item pool-apy col-3 h6">{stakingPools[5].apy}%</div>
                            <div className="table-item pool-action col-3 h6 details-btn-item">
                                <div className="details-btn button button-primary" onClick={() => {
                                    onPoolDetailsClicked(5)
                                }}>Details</div>
                            </div>
                            <div className="table-item pool-action col-3 details-btn-item-mobile">
                                <a onClick={() => {
                                    onPoolDetailsClicked(5)
                                }}>Details</a>
                            </div>
                        </div>
                        {/* -- New Pools ens -- */}
                    </div>
                </div>


                <SectionHeader tag="h3" data={sectionHeader} className="center-content pb-3 mt-32" />
                <div className="staking-pool-container illustration-section-12">
                    {!account && <h5 className="connect-wallet-notice">Connect wallet to continue</h5>}
                    {state.loading && account && !stakingPoolDetails && <div className="loading-container loading-container-pool-details">
                        <DotLoader color={"#C2EAFF"} loading={state.loading} css={override} />
                    </div>}
                    {account && stakingPoolDetails && (
                        <>
                            <div className="pool-staking row justify-content-center align-items-center">
                                <div className='staking-card col-lg-5 col-12'>
                                    <div className="deatils-table">
                                        <div className='table-title'>
                                            <h5 className="text-center m-0">Pool Staking </h5>
                                        </div>
                                        <div className="staking-details">
                                            <div className="my-stake">
                                                <h6 className="m-1">My Stake : {stakingPoolDetails.stake} MTCL </h6>
                                                {/* <h6 className="m-1">Total Staked : {stakingPoolDetails.stake} MTCL </h6> */}
                                                {/* <h6 className="m-1"> My Weightage : 00.000015%</h6> */}
                                            </div>
                                            <Form className="d-flex flex-column stake-form">
                                                <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                                    <Form.Label className="d-flex justify-content-between">
                                                        <div>Amount</div>
                                                        <div>Balance: {stakingPoolDetails.mtclBalance} MTCL</div>
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="form-input"
                                                        type="number"
                                                        placeholder="Enter USDT"
                                                        value={Number(tobeStaked).toString()}
                                                        // disabled={poolDetails.invetment > 0}
                                                        onChange={({ target: { value } }) => {
                                                            let val = Number(value)
                                                            if (val) setTobeStaked(Math.round(val))
                                                            else setTobeStaked(0)
                                                        }} />
                                                </Form.Group>
                                                {(<Button
                                                    className="button button-primary button-max"
                                                    variant="primary"
                                                    onClick={() => {
                                                        setTobeStaked(stakingPoolDetails.mtclBalance)
                                                    }}>MAX</Button>)}
                                                {!isStakeButtonActionInProgress() && (
                                                    <>
                                                        {(!isApproveDone()) && <Button
                                                            className="button button-primary "
                                                            variant="primary"
                                                            onClick={() => handleApprove()}
                                                            disabled={false}>
                                                            Approve
                                                        </Button>}
                                                        {(isApproveDone()) && <Button
                                                            className="button button-primary "
                                                            variant="primary"
                                                            onClick={() => handleStake()}
                                                            disabled={!isApproveDone()}>
                                                            Stake
                                                        </Button>}
                                                        {/* {(isApproveDone()) && <Button
                                                            className="button button-primary "
                                                            variant="primary"
                                                            // onClick={() => handleStake()}
                                                            disabled={true}>
                                                            Stake Closed
                                                        </Button>} */}
                                                    </>
                                                )}
                                                {(isStakeButtonActionInProgress()) && <Button
                                                    className="button button-primary "
                                                    variant="primary"
                                                    // onClick={() => handleApprove()}
                                                    disabled={true}>
                                                    <PropagateLoader color={"#C2EAFF"} loading={true} />
                                                </Button>}
                                            </Form>
                                            <Form className="d-flex flex-column">
                                                <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                                    <Form.Label className="d-flex justify-content-between">
                                                        <div>Amount</div>
                                                        <div>{Number(stakingPoolDetails.lastStakedTimestamp) > 0 && (<>
                                                            Unlock Time: {new Date((Number(stakingPoolDetails.minUnstakeTime) + Number(stakingPoolDetails.lastStakedTimestamp)) * 1000).toLocaleString()} </>)}
                                                        </div>
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="form-input"
                                                        type="number"
                                                        placeholder="Enter USDT"
                                                        value={Number(tobeUnStaked).toString()}
                                                        // disabled={poolDetails.invetment > 0}
                                                        onChange={({ target: { value } }) => {
                                                            let val = Number(value)
                                                            if (val) setTobeUnStaked(Math.round(val))
                                                            else setTobeUnStaked(0)
                                                        }} />
                                                </Form.Group>
                                                {(<Button
                                                    className="button button-primary button-max"
                                                    variant="primary"
                                                    onClick={() => {
                                                        setTobeUnStaked(stakingPoolDetails.stake > 0 ? stakingPoolDetails.stake : 0)
                                                    }}>MAX</Button>)}
                                                {!isUnstakeButtonActionInProgress() && (
                                                    <>
                                                        <Button
                                                            className="button button-primary "
                                                            variant="primary"
                                                            onClick={() => handleWithdrawStake()}
                                                            disabled={!(stakingPoolDetails.stake > 0)}>
                                                            UnStake
                                                        </Button>
                                                        {/* <Button
                                                            className="button button-primary "
                                                            variant="primary"
                                                            disabled={true}>
                                                            UnStake
                                                        </Button> */}
                                                    </>
                                                )}
                                                {(isUnstakeButtonActionInProgress()) && <Button
                                                    className="button button-primary "
                                                    variant="primary"
                                                    // onClick={() => handleApprove()}
                                                    disabled={true}>
                                                    <PropagateLoader color={"#C2EAFF"} loading={true} />
                                                </Button>}
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className='staking-work col-lg-3 col-12'>
                                    <div className="deatils-table mb-4">
                                        <div className='table-title'>
                                            <h5 className="text-center m-0">Total Staked </h5>
                                        </div>
                                        <div className="staking-details">
                                            <div className="my-stake">
                                                <h5 className="m-1 font-bold">{Number(stakingPoolDetails.totalStaked).toLocaleString()} MTCL </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="deatils-table mb-4">
                                        <div className='table-title'>
                                            <h5 className="text-center m-0">Minimum Stake </h5>
                                        </div>
                                        <div className="staking-details">
                                            <div className="my-stake">
                                                <h5 className="m-1">{stakingPoolDetails.minInvestorMTCLBalance} MTCL </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="deatils-table">
                                        <div className='table-title'>
                                            <h5 className="text-center m-0">Minimum Stake Duration </h5>
                                        </div>
                                        <div className="staking-details">
                                            <div className="my-stake">
                                                <h5 className="m-1">{secondsToDHMS(stakingPoolDetails.minUnstakeTime)} </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <SectionHeader tag="h3" data={apySectionHeader} className="center-content pb-3" />
                            <div className="staking-table d-flex flex-column align-items-center">
                                <div className="staking-table-body">
                                    <div className="staking-pool-header row">
                                        <div className="table-item-header pool-name col-3">Pool</div>
                                        {/* <div className="table-item-header pool-apy col-3">State</div> */}
                                        <div className="table-item-header pool-size col-3">Size</div>
                                        <div className="table-item-header pool-apy col-3">APY</div>
                                        <div className="table-item-header pool-action col-3 details-btn-item"></div>
                                    </div>

                                    <div className="staking-pool-body row align-items-center">
                                        <div className="table-item pool-name col-3 h6">{stakingPools[2].name}</div>
                                        <div className="table-item pool-size col-3 h6">{nFormatter(stakingPools[2].size, 1)}</div>
                                        <div className="table-item pool-apy col-3 h6">{stakingPools[2].apy}%</div>
                                        <div className="table-item pool-action col-3 h6 details-btn-item">
                                            <div className="details-btn button button-primary" onClick={() => {
                                                onPoolDetailsClicked(2)
                                            }}>Details</div>
                                        </div>
                                        <div className="table-item pool-action col-3 details-btn-item-mobile">
                                            <a onClick={() => {
                                                onPoolDetailsClicked(2)
                                            }}>Details</a>
                                        </div>
                                    </div>
                                    <div className="staking-pool-body row align-items-center">
                                        <div className="table-item pool-name col-3 h6">{stakingPools[0].name}</div>
                                        <div className="table-item pool-size col-3 h6">{nFormatter(stakingPools[0].size, 1)} </div>
                                        <div className="table-item pool-apy col-3 h6">{stakingPools[0].apy}%</div>
                                        <div className="table-item pool-action col-3 h6 details-btn-item">
                                            <div className="details-btn button button-primary" onClick={() => {
                                                onPoolDetailsClicked(0)
                                            }}>Details</div>
                                        </div>
                                        <div className="table-item pool-action col-3 details-btn-item-mobile">
                                            <a className="details-btn" onClick={() => {
                                                onPoolDetailsClicked(0)
                                            }}>Details</a>
                                        </div>
                                    </div>
                                    <div className="staking-pool-body row align-items-center">
                                        <div className="table-item pool-name col-3 h6">{stakingPools[1].name}</div>
                                        <div className="table-item pool-size col-3 h6">{nFormatter(stakingPools[1].size, 1)}</div>
                                        <div className="table-item pool-apy col-3 h6">{stakingPools[1].apy}%</div>
                                        <div className="table-item pool-action col-3 h6 details-btn-item">
                                            <div className="details-btn button button-primary" onClick={() => {
                                                onPoolDetailsClicked(1)
                                            }}>Details</div>
                                        </div>
                                        <div className="table-item pool-action col-3 details-btn-item-mobile">
                                            <a onClick={() => {
                                                onPoolDetailsClicked(1)
                                            }}>Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

            </div>
        </section>
    )
}

export default StakingSection