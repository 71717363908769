// const vault = {
//     137: "0x",
//     80001: '0xf30d4D358BA6eD173cC4bbE000F905a5eb11593f'
// }

const factory = {
    // 137: "0xCa0C8e3192F5A6ea5cF66053B296Dde61caE216C", // with token - max = 0 - DNU
    // 137: "0x11a2f2368a70efaa38381f682bfd9a6892c5de1b", // without token - diffrenet chain token - max = 0 - DNU
    // 137: "0x3B35c7e6fe32e1214FC95C608Fd9a1a57E3EBCFB", // without token - diffrenet chain token - DNU
    137: "0x2250c8Bc873854932eFEBf31C13492B5121A3C91", // without token - diffrenet chain token - with 3 round
    80001: '0x'
}

const info = {
    137: "0x244E06d4d060F168927786F1287989814b736566",
    80001: '0x'
}

const staking = {
    137: "0x83091d9842f688DDF2e1Cf82029acd06C6fe9084",
    80001: '0x'
}

const mtcl = {
    137: "0x3642c3c8ebe0b36a29e37809e1c9a7de06bc0b26",
    80001: '0x'
}

const usdt = {
    1: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    3: "0x",
    56: "0x55d398326f99059ff775485246999027b3197955",
    97: "0x",
    137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    80001: '0x'
}

//sale token :- 0x420400662c6CA795626df1AD7F2792d866582D9f

export default {
    factory,
    info,
    staking,
    mtcl,
    usdt
}