import BigNumber from 'bignumber.js'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 20,
  ROUNDING_MODE: BigNumber.ROUND_DOWN
})

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = (new BigNumber(balance.toString()).dividedBy(new BigNumber(10).pow(decimals)))
  return displayBalance.decimalPlaces(4).toNumber()
}

// export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
//   const displayBalance = (new BigNumber(balance.toString()).dividedBy(new BigNumber(10).pow(decimals)))
//   return displayBalance.toString()
// }

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString(undefined, options)
}

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
