import React, { useState, useCallback, useContext, useEffect } from 'react'
import SectionHeader from './partials/SectionHeader';
import DotLoader from "react-spinners/DotLoader";
import { useHistory } from "react-router-dom";
import { nFormatter } from '../../utils/formatBalance';
import { formatDuration, intervalToDuration } from 'date-fns';
import { Button, Form, Modal } from 'react-bootstrap';
import { getStakingPoolDetailsbyAddress } from '../../constants/stakingPool';
import { useStake, useStakingPoolFetch, useWithdrawStake } from '../../hooks/stakingPool';
import { Context } from '../../store/store';
import { useWeb3React } from '@web3-react/core';
import { useMTCLAllowance, useMTCLApprove, useMTCLBalanceFetch } from '../../hooks/mtcl';
import { PropagateLoader } from 'react-spinners';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import { rewardCalc } from '../../utils/stakingCalculator'

function StakingDetailsSection(props: any) {
    let history = useHistory();
    const { onFetchStakingPoolDetails } = useStakingPoolFetch();
    const { onMTCLBalanceFetch } = useMTCLBalanceFetch();
    const { onMTCLApprove } = useMTCLApprove();
    const { onStake } = useStake();
    const { onWithdrawStake } = useWithdrawStake();
    const [state, dispatch] = useContext(Context);
    const sectionHeader = {
        title: 'Staking Pool',
        paragraph: ''
    };
    const override = `
        display: block;
        margin: auto;
        border-color: red;
    `;

    const [poolState, setPoolState] = useState<'TO_BE_OPEN' | 'STAKE_OPEN' | 'LOCK_PERIOD' | 'EARLY_UNSTAKE_OPEN' | 'MATURITY_REACHED'>('TO_BE_OPEN');
    const [interfaceModalShow, setInterfaceModalShow] = useState(false);
    const [stakingPoolDetails, setStakingPoolDetails] = useState<any>();
    const [requestedApproval, setRequestedApproval] = useState(false);
    const [requestedStake, setRequestedStake] = useState(false);
    const [requestedWithdrawStake, setRequestedWithdrawStake] = useState(false);
    const [approveTxhash, setApproveTxhash] = useState('');
    const [stakeTxhash, setStakeTxhash] = useState('');
    const [withdrawStakeTxhash, setWithdrawStakeTxhash] = useState('');
    const [formValidity, setFormValidity] = useState<'INVLAID_AMOUNT' | 'VALID' | 'MAX_LIMIT'>('INVLAID_AMOUNT')
    const [tobeStaked, setTobeStaked] = useState<number>(0)
    const { account, chainId } = useWeb3React()
    const allowance = useMTCLAllowance(stakingPoolDetails && stakingPoolDetails.poolAddress)

    useEffect(() => {
        fetchDetails()
    }, [])

    useEffect(() => {
        if (!allowance) return
        if (new BigNumber(allowance)?.comparedTo(tobeStaked) === 1) {
            setRequestedApproval(false)
        }
    }, [allowance])

    useEffect(() => {
        if (stakingPoolDetails && (stakingPoolDetails.mtclBalance < tobeStaked || (stakingPoolDetails.min) > (Number(tobeStaked) + stakingPoolDetails.stakeOf))) {
            setFormValidity('INVLAID_AMOUNT')
        } else {
            setFormValidity('VALID')
        }
        if (stakingPoolDetails && stakingPoolDetails.mtclBalance === undefined && stakingPoolDetails.min > (Number(tobeStaked))) {
            setFormValidity('VALID')
        }
        if (stakingPoolDetails && stakingPoolDetails.max !== undefined && stakingPoolDetails.stakeOf + Number(tobeStaked) > stakingPoolDetails.max) {
            setFormValidity('MAX_LIMIT')
        }
    }, [tobeStaked, stakingPoolDetails])

    const fetchDetails = useCallback(async () => {
        try {
            dispatch({ type: 'SET_LOADING', payload: true })
            let poolDetails = await onFetchStakingPoolDetails((props.stakingPoolAddress).toString())
            let details = getStakingPoolDetailsbyAddress(props.stakingPoolAddress)
            let mtclBalance = await onMTCLBalanceFetch()
            dispatch({ type: 'SET_LOADING', payload: false })
            let finalDetails = {
                ...details,
                ...poolDetails,
                mtclBalance: mtclBalance && mtclBalance.balance
            }
            console.log(finalDetails)
            setStakingPoolDetails(finalDetails)
            calculateAndSetPoolState(finalDetails)
        } catch (e) {
            toast.error("Oops! Something is not right, please refresh the page.")
            dispatch({ type: 'SET_LOADING', payload: true })
        }
    }, [onFetchStakingPoolDetails, onMTCLBalanceFetch])

    const handleApprove = useCallback(async () => {
        try {
            if (requestedApproval) return
            if (formValidity !== 'VALID') {
                showFormValidityToast(formValidity)
                return
            }
            setRequestedApproval(true)
            let tx = await onMTCLApprove(stakingPoolDetails.poolAddress)
            if (tx) {
                toast.success("Approve request has been submitted")
                setApproveTxhash(tx.hash)
            } else {
                setRequestedApproval(false)
            }
        } catch (e: any) {
            setRequestedApproval(false)
            toast.error(e.message)
        }
    }, [stakingPoolDetails, onMTCLApprove, formValidity])

    const handleStake = useCallback(async () => {
        try {
            if (requestedApproval) return
            if (formValidity !== 'VALID') {
                showFormValidityToast(formValidity)
                return
            }
            setRequestedStake(true);
            let tx = await onStake(
                stakingPoolDetails.poolAddress,
                new BigNumber(tobeStaked).times(new BigNumber(10).pow(18).toString()).toString()
            )
            if (tx) {
                toast.success("Stake request has been submitted")
                setStakeTxhash(tx.hash)
                setInterfaceModalShow(false)
            } else {
                setRequestedStake(false)
            }
        } catch (e: any) {
            setRequestedStake(false)
            toast.error(e.data.message || e.message)
        }

    }, [stakingPoolDetails, onStake, formValidity, tobeStaked])

    const handleWithdrawStake = useCallback(async () => {
        try {
            if (requestedWithdrawStake) return
            setRequestedWithdrawStake(true)
            let tx = await onWithdrawStake(
                stakingPoolDetails.poolAddress,
                new BigNumber(tobeStaked).times(new BigNumber(10).pow(18).toString()).toString()
            )
            if (tx) {
                toast.success("Claim request has been submitted")
                setWithdrawStakeTxhash(tx.hash)
                setInterfaceModalShow(false)
            } else {
                setRequestedWithdrawStake(false)
            }
        } catch (e: any) {
            setRequestedWithdrawStake(false)
            toast.error(e.data.message || e.message)
        }
    }, [stakingPoolDetails, tobeStaked, onWithdrawStake, formValidity])

    const showFormValidityToast = function (formValidity: any) {
        switch (formValidity) {
            case 'INVLAID_AMOUNT':
                toast.error('Please enter valid token amount')
                break;
            case 'MAX_LIMIT':
                toast.error('Oops! You have exceeded the maximum staking limit of 5000 MTCL')
                break;
        }
    }

    const calculateAndSetPoolState = function (poolDetails: any) {
        //     stakingStarts: "1630150200000",
        // stakingEnds: "1630236600000",
        // withdrawStarts: "1630236600000",
        // withdrawEnds: "1630236600000",
        if (poolDetails.stakingStarts > Date.now()) {
            setPoolState('TO_BE_OPEN')
        } else if (poolDetails.stakingEnds >= Date.now() && poolDetails.stakingStarts <= Date.now()) {
            setPoolState('STAKE_OPEN')
        } else if (poolDetails.withdrawStarts > Date.now()) {
            setPoolState('LOCK_PERIOD')
        } else if (poolDetails.withdrawStarts <= Date.now() && poolDetails.withdrawEnds > Date.now()) {
            setPoolState('EARLY_UNSTAKE_OPEN')
        } else if (poolDetails.withdrawEnds <= Date.now()) {
            setPoolState('MATURITY_REACHED')
        }
        // setPoolState('STAKE_OPEN')
    }

    const isApproveDone = function () {
        // return false
        if (!allowance) return false
        if (new BigNumber(allowance)?.comparedTo(tobeStaked) === 1) {
            return true
        } else {
            return false
        }
    }

    const isButtonActionInProgress = function () {
        if (requestedStake || requestedApproval || requestedWithdrawStake) {
            return true
        } else {
            return false
        }
    }


    return (
        <section className="section illustration-section-01">
            <div className="container-md staking-details-page illustration-section-02">
                {!account && <h5 className="connect-wallet-notice">Connect wallet to continue</h5>}
                {state.loading && account && !stakingPoolDetails && <div className="loading-container loading-container-pool-details">
                    <DotLoader color={"#C2EAFF"} loading={state.loading} css={override} />
                </div>}
                {/* <SectionHeader tag="h1" data={sectionHeader} className="center-content" /> */}
                {account && stakingPoolDetails && <div className="row justify-content-center illustration-section-11">
                    {/* <div className="col-lg-4"></div> */}
                    <div className="col-lg-5 col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="deatils-table pool-details">
                                    <div className="table-title">
                                        <h5 className="text-center m-0">{stakingPoolDetails.name} | Pool Details</h5>
                                    </div>
                                    <div className="details">
                                        <div className="status">
                                            {poolState === 'TO_BE_OPEN' && (
                                                <h6 className="m-0 text-xxs close">
                                                    Staking Open In {formatDuration(intervalToDuration({ start: new Date(Number(stakingPoolDetails.stakingStarts)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                                                </h6>)}
                                            {poolState === 'STAKE_OPEN' && (
                                                <h6 className="m-0 text-xxs close">
                                                    Staking Closes In {formatDuration(intervalToDuration({ start: new Date(), end: new Date(Number(stakingPoolDetails.stakingEnds)) }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                                                </h6>)}
                                            {(poolState === 'LOCK_PERIOD' || poolState === 'EARLY_UNSTAKE_OPEN') && (
                                                <h6 className="m-0 text-xxs filled">
                                                    Maturity In {formatDuration(intervalToDuration({ start: new Date(), end: new Date(Number(stakingPoolDetails.withdrawEnds)) }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                                                </h6>)}
                                            {(poolState === 'MATURITY_REACHED') && (
                                                <h6 className="m-0 text-xxs filled">
                                                    Maturity Reached
                                                </h6>)}
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>My Staking</p>
                                            <p>{nFormatter(stakingPoolDetails.stakeOf, 1)} MTCL</p>
                                        </div>
                                        {/* <div className="d-flex justify-content-between">
                                            <p>Rewards if un-staked today</p>
                                            <p>{nFormatter(0, 1)} MTCL</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Rewards at maturity</p>
                                            <p>{nFormatter(0, 1)} MTCL</p>
                                        </div> */}
                                        {(poolState === 'TO_BE_OPEN' || poolState === 'STAKE_OPEN') && (
                                            <>
                                                <div className="d-flex justify-content-between">
                                                    <p>Pool State</p>
                                                    <p>{nFormatter(stakingPoolDetails.stakedTotal, 1)} MTCL / {nFormatter(stakingPoolDetails.size, 1)} MTCL</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity Reward</p>
                                                    <p>{stakingPoolDetails.apy}% (Annual)</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Early Withdrawal Reward</p>
                                                    <p>{stakingPoolDetails.earlyApy}% (Annual)</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Minimum Stake</p>
                                                    <p>{stakingPoolDetails.min} MTCL</p>
                                                </div>
                                                {stakingPoolDetails.max && (
                                                    <div className="d-flex justify-content-between">
                                                        <p>Maximum Stake</p>
                                                        <p>{stakingPoolDetails.max} MTCL</p>
                                                    </div>
                                                )}
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity Duration</p>
                                                    <p>{stakingPoolDetails.maturity} Days</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Minimum Lock Duration</p>
                                                    <p>{stakingPoolDetails.earlyOpen} Days</p>
                                                </div>
                                            </>
                                        )}
                                        {(poolState === 'LOCK_PERIOD') && (
                                            <>
                                                {/* <div className="d-flex justify-content-between">
                                                    <p>Pool State</p>
                                                    <p>{nFormatter(stakingPoolDetails.stakedTotal, 1)} MTCL / {nFormatter(stakingPoolDetails.size, 1)} MTCL</p>
                                                </div> */}
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity reward</p>
                                                    <p>{stakingPoolDetails.apy}%</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Early rewards</p>
                                                    <p>{stakingPoolDetails.earlyApy}%</p>
                                                </div>
                                                {/* <div className="d-flex justify-content-between">
                                                    <p>Minimum Stake</p>
                                                    <p>{nFormatter(stakingPoolDetails.min, 1)} MTCL</p>
                                                </div> */}
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity Date</p>
                                                    <p>{stakingPoolDetails.maturity} Days</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Early withdraw Start</p>
                                                    <p>{stakingPoolDetails.earlyOpen} Days</p>
                                                </div>
                                            </>
                                        )}
                                        {(poolState === 'EARLY_UNSTAKE_OPEN') && (
                                            <>
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity reward</p>
                                                    <p>{rewardCalc(
                                                        stakingPoolDetails.stakeOf, 'maturity', stakingPoolDetails.earlyWithdrawReward, stakingPoolDetails.rewardBalance,
                                                        stakingPoolDetails.withdrawEnds, stakingPoolDetails.stakingEnds, stakingPoolDetails.stakedTotal, stakingPoolDetails.stakedBalance
                                                    )}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Early rewards (Till Today)</p>
                                                    <p>{rewardCalc(
                                                        stakingPoolDetails.stakeOf, 'now', stakingPoolDetails.earlyWithdrawReward, stakingPoolDetails.rewardBalance,
                                                        stakingPoolDetails.withdrawEnds, stakingPoolDetails.stakingEnds, stakingPoolDetails.stakedTotal, stakingPoolDetails.stakedBalance
                                                    )}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity Date</p>
                                                    <p>{new Date(Number(stakingPoolDetails.withdrawEnds)).toUTCString().split(',')[1].replace('GMT', 'UTC')}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Early withdraw</p>
                                                    <p>{new Date(Number(stakingPoolDetails.withdrawStarts)).toUTCString().split(',')[1].replace('GMT', 'UTC')}</p>
                                                </div>
                                            </>
                                        )}
                                        {(poolState === 'MATURITY_REACHED') && (
                                            <>
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity reward</p>
                                                    <p>{rewardCalc(
                                                        stakingPoolDetails.stakeOf, 'maturity', stakingPoolDetails.earlyWithdrawReward, stakingPoolDetails.rewardBalance,
                                                        stakingPoolDetails.withdrawEnds, stakingPoolDetails.stakingEnds, stakingPoolDetails.stakedTotal, stakingPoolDetails.stakedBalance
                                                    )}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Maturity Date</p>
                                                    <p>{new Date(Number(stakingPoolDetails.withdrawEnds)).toUTCString().split(',')[1].replace('GMT', 'UTC')}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Early withdraw</p>
                                                    <p>{new Date(Number(stakingPoolDetails.withdrawStarts)).toUTCString().split(',')[1].replace('GMT', 'UTC')}</p>
                                                </div>
                                            </>
                                        )}


                                        {(poolState === 'TO_BE_OPEN') && (<Button
                                            className="button button-primary "
                                            variant="primary"
                                            disabled={true}>
                                            Stake
                                        </Button>)}
                                        {(poolState === 'STAKE_OPEN') && (<Button
                                            className="button button-primary "
                                            variant="primary"
                                            onClick={() => setInterfaceModalShow(true)}
                                            disabled={stakingPoolDetails.stakedTotal >= stakingPoolDetails.size}>
                                            Stake
                                        </Button>)}
                                        {(poolState === 'LOCK_PERIOD') && (<Button
                                            className="button button-primary "
                                            variant="primary"
                                            disabled={true}>
                                            Unstake
                                        </Button>)}
                                        {(poolState === 'EARLY_UNSTAKE_OPEN' || poolState === 'MATURITY_REACHED') && (<Button
                                            className="button button-primary "
                                            variant="primary"
                                            onClick={() => setInterfaceModalShow(true)}
                                            disabled={false}>
                                            Unstake
                                        </Button>)}
                                        {/* <div className="d-flex justify-content-between">
                                            <p>Staked so far</p>
                                            <p>250000</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Maturity reward</p>
                                            <p>175%</p>
                                        </div> */}

                                        {/* <div className="d-flex justify-content-between">
                                            <p>Staking contribution close</p>
                                            <p>In 5 Days</p>
                                        </div> */}
                                        {/* <div className="d-flex justify-content-between">
                                            <p>Early withdraw open</p>
                                            <p>11/10/2021, 19:00:00</p>
                                        </div> */}
                                        {/* <div className="d-flex justify-content-between">
                                            <p>Maturity at</p>
                                            <p>11/10/2021, 19:00:00</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            <Modal
                show={interfaceModalShow}
                onHide={() => setInterfaceModalShow(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                        {(poolState === 'STAKE_OPEN') && "Stake"}
                        {(poolState === 'EARLY_UNSTAKE_OPEN' || poolState === 'MATURITY_REACHED') && "Unstake"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-32">
                    {stakingPoolDetails && (
                        <Form className="d-flex flex-column">
                            <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                <Form.Label className="d-flex justify-content-between">
                                    <div>Amount</div>
                                    {(poolState === 'EARLY_UNSTAKE_OPEN' || poolState === 'MATURITY_REACHED') && (<div>Your Stake: {stakingPoolDetails.stakeOf} MTCL</div>)}
                                    {(poolState === 'STAKE_OPEN') && (<div>Balance: {stakingPoolDetails.mtclBalance} MTCL</div>)}
                                </Form.Label>
                                <Form.Control
                                    className="form-input"
                                    type="number"
                                    placeholder="Enter USDT"
                                    value={Number(tobeStaked).toString()}
                                    // disabled={poolDetails.invetment > 0}
                                    onChange={({ target: { value } }) => {
                                        let val = Number(value)
                                        if (val) setTobeStaked(Math.round(val))
                                        else setTobeStaked(0)
                                    }} />
                            </Form.Group>
                            {(<Button
                                className="button button-primary button-max"
                                variant="primary"
                                onClick={() => {
                                    if (poolState === 'EARLY_UNSTAKE_OPEN' || poolState === 'MATURITY_REACHED') {
                                        setTobeStaked(stakingPoolDetails.stakeOf)
                                    } else {
                                        let maxStake = stakingPoolDetails.max && stakingPoolDetails.mtclBalance > stakingPoolDetails.max 
                                                ? stakingPoolDetails.max 
                                                : stakingPoolDetails.mtclBalance
                                        setTobeStaked(maxStake)
                                    }
                                }}>MAX</Button>)}
                            {/* <Form.Group className="mb-3 d-flex flex-column" controlId="formBasicPassword">
                                <Form.Label>You will get ({poolDetails.tokenSymbol})</Form.Label>
                                <Form.Control
                                    className="form-input"
                                    type="text"
                                    placeholder={poolDetails.tokenSymbol}
                                    value={Number(reciveAmount) + ' ' + poolDetails.tokenSymbol}
                                    disabled={true} />
                            </Form.Group> */}
                            {!isButtonActionInProgress() && poolState === "STAKE_OPEN" && (
                                <>
                                    {(!isApproveDone()) && <Button
                                        className="button button-primary "
                                        variant="primary"
                                        onClick={() => handleApprove()}
                                        disabled={false}>
                                        Approve
                                    </Button>}
                                    {(isApproveDone()) && <Button
                                        className="button button-primary "
                                        variant="primary"
                                        onClick={() => handleStake()}
                                        disabled={!isApproveDone()}>
                                        Stake
                                    </Button>}
                                </>
                            )}
                            {!isButtonActionInProgress() && (poolState === "EARLY_UNSTAKE_OPEN" || poolState === "MATURITY_REACHED") && (
                                <>
                                    <Button
                                        className="button button-primary "
                                        variant="primary"
                                        onClick={() => handleWithdrawStake()}
                                        disabled={!(stakingPoolDetails.stakeOf > 0)}>
                                        UnStake
                                    </Button>
                                    {/* <Button
                                        className="button button-primary "
                                        variant="primary"
                                        disabled={true}>
                                        UnStake
                                    </Button> */}
                                </>
                            )}
                            {(isButtonActionInProgress()) && <Button
                                className="button button-primary "
                                variant="primary"
                                // onClick={() => handleApprove()}
                                disabled={true}>
                                <PropagateLoader color={"#C2EAFF"} loading={true} />
                            </Button>}
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default StakingDetailsSection