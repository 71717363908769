import React from 'react';
import IDOPoolDetailSolchiksSection from '../components/sections/IDOPoolDetailsSolchiks';

class IDOPoolDetailsSolchiks extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.title = "Pool | MaticLaunch"
  }
  render() {
    return (
      <IDOPoolDetailSolchiksSection id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default IDOPoolDetailsSolchiks;