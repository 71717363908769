import React, { useEffect, useState } from 'react'
import PieChart from '../elements/PieChart';
import { formatDistanceToNowStrict } from 'date-fns'

import { formatDuration, intervalToDuration } from 'date-fns'
import { useWeb3React } from '@web3-react/core';
import PuffLoader from "react-spinners/PuffLoader";
import { isMobile } from 'react-device-detect';
import { Button } from 'react-bootstrap';


function PoolCard(props: any) {
    const { account } = useWeb3React()
    const [poolState, setPoolState] = useState<'TBA' | 'WHITELISTING_TO_BE_OPEN' | 'WHITELISTING_OPEN' | 'POOL_TO_BE_OPEN' | 'POOL_OPEN' | 'POOL_FILLED' | 'POOL_CLOSED'>()
    function nFormatter(num: number, digits: number) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    useEffect(() => {
        console.log(props)
        try {
            if (props.openTime === 0) {
                setPoolState('TBA')
            } else if (Number(props.whitelistingOpenTime) > Date.now()) {
                setPoolState('WHITELISTING_TO_BE_OPEN')
            } else if (Number(props.whitelistingOpenTime) < Date.now() && Number(props.whitelistingCloseTime) >= Date.now()) {
                setPoolState('WHITELISTING_OPEN')
            } else if (Number(props.whitelistingCloseTime) <= Date.now() && Number(props.openTime) >= Date.now()) {
                setPoolState('POOL_TO_BE_OPEN')
            } else if (Number(props.openTime) < Date.now() && Number(props.closeTime) > Date.now()) {
                setPoolState('POOL_OPEN')
            } else if (Number(props.closeTime) < Date.now()) {
                setPoolState('POOL_CLOSED')
            }
            if (props.openTime !== 0 && Number(props.closeTime) < Date.now()) {
                setPoolState('POOL_FILLED')
            }
            if (props.openTime !== 0 && (Number(props.totalCollected) + 100) >= props.totalRaise) {
                setPoolState('POOL_FILLED')
            }
        } catch (e) {
            console.log(e)
        }

    }, [props])

    useEffect(() => {
        console.log(props.title, poolState)
    }, [poolState])

    // let duration = intervalToDuration({
    //     start: new Date(Number(props.openTime)),
    //     end: new Date()
    // })

    // formatDuration(intervalToDuration({ start: new Date(Number(props.openTime)), end: new Date()}), {delimiter: ' '})

    return (
        <>
            <div className="pool-card">
                <div className="pool-card-status">
                    {poolState === 'TBA' && (
                        <h6 className="m-0 text-xxs interval">
                            TBA
                        </h6>
                    )}
                    {poolState === 'WHITELISTING_TO_BE_OPEN' && (
                        <h6 className="m-0 text-xxs interval">
                            Whitelist Opens In {formatDuration(intervalToDuration({ start: new Date(Number(props.whitelistingOpenTime)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                        </h6>
                    )}
                    {poolState === 'WHITELISTING_OPEN' && (
                        <h6 className="m-0 text-xxs interval">
                            Whitelist Closes In {formatDuration(intervalToDuration({ start: new Date(), end: new Date(Number(props.whitelistingCloseTime)) }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                        </h6>
                    )}
                    {poolState === 'POOL_TO_BE_OPEN' && (
                        <h6 className="m-0 text-xxs interval">
                            Opens In {formatDuration(intervalToDuration({ start: new Date(Number(props.openTime)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                        </h6>
                    )}
                    {poolState === 'POOL_OPEN' && (
                        <h6 className="m-0 text-xxs open">
                            Open
                        </h6>
                    )}
                    {poolState === 'POOL_FILLED' && (
                        <h6 className="m-0 text-xxs filled">
                            Filled
                        </h6>
                    )}
                    {poolState === 'POOL_CLOSED' && (
                        <h6 className="m-0 text-xxs close">
                            Closed
                        </h6>
                    )}
                    {/* {Number(props.openTime) === 0 && (
                        <h6 className="m-0 text-xxs interval">
                            TBA
                        </h6>
                    )}
                    {Number(props.openTime) !== 0 && Number(props.openTime) >= Date.now() && (Number(props.totalCollected) + 100) < props.totalRaise && (
                        <h6 className="m-0 text-xxs interval">
                            Opens In {formatDuration(intervalToDuration({ start: new Date(Number(props.openTime)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                        </h6>
                    )}
                    {Number(props.openTime) !== 0 && (Number(props.totalCollected) + 100) >= props.totalRaise && (
                        <h6 className="m-0 text-xxs filled">
                            Filled
                        </h6>
                    )}
                    {Number(props.openTime) !== 0 && Number(props.openTime) < Date.now() && Number(props.closeTime) > Date.now() && (Number(props.totalCollected) + 100) < props.totalRaise && (
                        <h6 className="m-0 text-xxs open">
                            Open
                        </h6>
                    )}
                    {Number(props.openTime) !== 0 && Number(props.closeTime) < Date.now() && (Number(props.totalCollected) + 100) < props.totalRaise && (
                        <h6 className="m-0 text-xxs close">
                            Closed
                        </h6>
                    )} */}
                </div>
                <div className="pool-card-body">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-lg-9 col-sm-12 pool-branding d-flex align-items-center">
                            <div className="row align-items-center justify-content-center">
                                <div className="pool-img col-3">
                                    <img src={props.logo !== "" ? props.logo : require("../../assets/images/logo_symbol.png")} />
                                </div>
                                <div className="pool-title col-8">
                                    <h4 className="m-0">{props.title}</h4>
                                    <p className="m-0 text-xs">{props.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 pool-social align-items-center">
                            <ul className="list-reset d-flex mb-0 social-list">
                                <li>
                                    <a href={props.website} target="_blank">
                                        <svg
                                            width="20"
                                            height="19"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <title>Website</title>
                                            <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href={`https://telegram.me/${props.telegram}`} target="_blank">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 26 21.667"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <title>Telegram</title>
                                            <path id="telegram-1" d="M10.2,16.28l-.43,6.049a1.5,1.5,0,0,0,1.2-.582l2.885-2.757,5.978,4.378c1.1.611,1.869.289,2.165-1.009L25.925,3.973h0c.348-1.621-.586-2.254-1.654-1.857L1.207,10.945c-1.574.611-1.55,1.489-.268,1.886l5.9,1.834,13.7-8.57c.645-.427,1.231-.191.749.236Z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href={`https://twitter.com/${props.twitter}`} target="_blank">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <title>Twitter</title>
                                            <path
                                                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                                        </svg>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="pool-details row">
                        <div className="col-lg-9 col-sm-12 pt-16">
                            <div className="rate d-flex">
                                <p className="text-sm m-0">Swap Rate: </p>
                                <h6 className="text-sm m-0 ml-4"> 1 USDT = {(1 / props.price).toFixed(4)} {props.symbol}</h6>
                                {/* <h5>1 USDT = 0.1MTCL</h5> */}
                            </div>
                            <div className="row mt-16 extra-details">
                                <div className="col">
                                    <p className="text-xs m-0">Total Raise</p>
                                    <h6 className="m-0 mt-8">{nFormatter(props.totalRaise, 1)} USDT</h6>
                                </div>
                                <div className="col">
                                    <p className="text-xs m-0">Token For Sale</p>
                                    <h6 className="m-0 mt-8">{nFormatter(props.tokenForSale, 1)} {props.symbol}</h6>
                                </div>
                                {props.guaranteedDuration && Number(props.guaranteedDuration) > 0 && (
                                    <div className="col">
                                        <p className="text-xs m-0">Round 1 Duration</p>
                                        <h6 className="m-0 mt-8">{props.guaranteedDuration} Hours</h6>
                                    </div>
                                )}
                                {!props.guaranteedDuration && (
                                    <div className="col">
                                        <p className="text-xs m-0">Access</p>
                                        <h6 className="m-0 mt-8">{props.access ?? (((Number(props.openTime) + (6 * 60 * 60) > Date.now() / 1000) && ((Number(props.closeTime) > Date.now() / 1000))) || (Number(props.closeTime) === 0) ? "MTCL STAKER" : "PUBLIC")}</h6>
                                    </div>
                                )}
                            </div>
                        </div>
                        {(poolState === 'WHITELISTING_OPEN' || poolState === 'WHITELISTING_TO_BE_OPEN') && (
                            <div className='col-lg-3 whitelist-mobile  col-md-12 pt-16'>
                                <div className='whitelist-container'>
                                    <div className="rate ido-date d-flex">
                                        <p className="text-sm m-0">IDO: </p>
                                        <h6 className="text-sm m-0 ml-4">{new Date(props.openTime).toLocaleDateString()}</h6>
                                        {/* <h5>1 USDT = 0.1MTCL</h5> */}
                                    </div>
                                    <div className="col d-flex flex-column-reverse mt-16 register-container">
                                        {props.whitelistingLink && poolState === 'WHITELISTING_OPEN' && (
                                            <a className="button button-primary whitelist-button" href={props.whitelistingLink} target="_blank">
                                                WHITELIST
                                            </a>
                                        )}
                                        {!props.whitelistingLink || poolState === 'WHITELISTING_TO_BE_OPEN' && (
                                            <a className="button button-primary whitelist-button disabled" href={"#"} target="_blank">
                                                WHITELIST
                                            </a>
                                        )}
                                        {/* <a className="button button-primary whitelist-button" href={props.whitelistingLink} target="_blank">
                                            WHITELIST
                                        </a> */}
                                        <p className="text-xs m-0 register-now-text">Register Now</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(poolState !== 'WHITELISTING_OPEN' && poolState !== 'WHITELISTING_TO_BE_OPEN') && !isMobile && !account && <div className="col-lg-3 col-sm-12">
                            {/* {!isMobile && !account && Number(props.openTime) < Date.now() && Number(props.closeTime) > Date.now() && <div className="col-lg-3 col-sm-12"> */}
                            <PuffLoader color={"#C2EAFF"} loading={true} css={override} />
                            <p className="connect-wallet-notice-pie">Connect Wallet to see progress</p>
                        </div>}
                        {(poolState !== 'WHITELISTING_OPEN' && poolState !== 'WHITELISTING_TO_BE_OPEN') && !isMobile && account && <div className="col-lg-3 col-sm-12 pl-0">
                            {/* {!isMobile
                            && ((account && Number(props.openTime) < Date.now() && Number(props.closeTime) > Date.now())
                                || !(Number(props.openTime) < Date.now() && Number(props.closeTime) > Date.now())) && <div className="col-lg-3 col-sm-12 pl-0"> */}
                            <PieChart filled={Number(props.totalCollected / props.totalRaise * 100).toFixed(2)}></PieChart>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PoolCard