import React from 'react';
import IDOPoolDetailVulturePeakSection from '../components/sections/IDOPoolDetailsVulturePeak';

class IDOPoolDetailsVulturePeak extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.title = "Vulture Peak IDO | MaticLaunch"
  }
  render() {
    return (
      <IDOPoolDetailVulturePeakSection id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default IDOPoolDetailsVulturePeak;