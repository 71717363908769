import React, { useCallback, useContext, useEffect, useState } from 'react'
import SectionHeader from './partials/SectionHeader';
import PoolCard from "../elements/PoolCard";
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { Context } from '../../store/store';
import Input from '../elements/Input';
import { useFactoryApprove, usePoolFetch, useInvest, useIntervalPoolFetch, useClaim } from '../../hooks/pool';
import DotLoader from "react-spinners/DotLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { nFormatter } from "../../utils/formatBalance";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { useAllowance } from '../../hooks/useAllowance';
import { toast } from 'react-toastify';
import { getBalanceNumber } from "../../utils/formatBalance";
import { usePoolUsdtApprove } from '../../hooks/useApprove';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { getChainDetails } from '../../utils';

//DEV
import { useHistory } from "react-router-dom";

function PoolDetailSection(props: any) {
    const sectionHeader = {
        title: 'Pool details',
        paragraph: ''
    };
    let [poolDetails, setPoolDetails] = useState<any>()
    const [state, dispatch] = useContext(Context);
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    //DEV
    let history = useHistory();

    const { account, chainId } = useWeb3React()
    const { onFetchPoolDetails } = usePoolFetch()
    const intervalPoolDetails = useIntervalPoolFetch(poolDetails)
    const [addMoreModalShow, setAddMoreModalShow] = useState(false);
    const [investAmount, setInvestAmount] = useState(0)
    const [reciveAmount, setReciveAmount] = useState(0)
    const [requestedApproval, setRequestedApproval] = useState(false)
    const [requestedInvest, setRequestedInvest] = useState(false)
    const [requestedClaim, setRequestedClaim] = useState(false)
    const [approveTxhash, setApproveTxhash] = useState('')
    const [investTxhash, setInvestTxhash] = useState('')
    const [claimTxhash, setClaimTxhash] = useState('')
    const [formValidity, setFormValidity] = useState<'INVLAID_AMOUNT' | 'VALID'>('INVLAID_AMOUNT')
    // const allowance = useAllowance(poolDetails)
    // const { onApprove } = usePoolUsdtApprove()
    const { onInvest } = useInvest()
    const { onClaim } = useClaim()

    useEffect(() => {
        history.push(`/pools/`)
    }, [])

    useEffect(() => {
        if (!poolDetails) {
            fetchDetails()
        }
    }, [account])

    useEffect(() => {
        if (requestedClaim && intervalPoolDetails.claimed) {
            setRequestedClaim(false)
        }
        if (requestedInvest && intervalPoolDetails.invetment > 0 && !addMoreModalShow) {
            setRequestedInvest(false)
        }
        if (poolDetails && JSON.stringify(poolDetails) != JSON.stringify(intervalPoolDetails)) {
            setPoolDetails(intervalPoolDetails)
        }
    }, [intervalPoolDetails])

    // useEffect(() => {
    //     if (!allowance) return
    //     if (new BigNumber(allowance)?.comparedTo(investAmount) === 1) {
    //         setRequestedApproval(false)
    //     }
    // }, [allowance])

    // const isApproveDoneOrInProgress = function () {
    //     if (!allowance) return false
    //     if (new BigNumber(allowance)?.comparedTo(investAmount) === 1 || requestedApproval) {
    //         // setRequestedApproval(false)
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    // const isApproveDone = function () {
    //     if (!allowance) return false
    //     if (new BigNumber(allowance)?.comparedTo(investAmount) === 1) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    const isApproveOrInvestOrClaimInProgress = function () {
        if (requestedInvest || requestedApproval || requestedClaim) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => () => console.log("unmount"), []);

    // const handleApprove = useCallback(async () => {
    //     try {
    //         if (requestedApproval) return
    //         if (formValidity !== 'VALID') {
    //             showFormValidityToast(formValidity)
    //             return
    //         }
    //         setRequestedApproval(true)
    //         let tx = await onApprove(poolDetails.poolAddress)
    //         if (tx) {
    //             toast.success("Approve request has been submitted")
    //             setApproveTxhash(tx.hash)
    //         } else {
    //             setRequestedApproval(false)
    //         }
    //     } catch (e) {
    //         setRequestedApproval(false)
    //         toast.error(e.message)
    //     }
    // }, [onApprove, formValidity])

    const handleInvest = useCallback(async () => {
        try {
            if (requestedApproval) return
            if (formValidity !== 'VALID') {
                showFormValidityToast(formValidity)
                return
            }
            setRequestedInvest(true)
            let decimal = 6
            if (chainId === 97 || chainId === 56) {
                decimal = 18
            }
            let tx = await onInvest(
                poolDetails.poolAddress,
                new BigNumber(investAmount).times(new BigNumber(10).pow(decimal).toString()).toString()
            )
            if (tx) {
                toast.success("Invest request has been submitted")
                setAddMoreModalShow(false)
                setInvestTxhash(tx.hash)
            } else {
                setRequestedInvest(false)
            }
        } catch (e) {
            setRequestedInvest(false)
            toast.error(e.message)
        }
    }, [onInvest, formValidity, investAmount])

    const handleClaim = useCallback(async () => {
        try {
            if (requestedClaim) return
            setRequestedClaim(true)
            let tx = await onClaim(
                poolDetails.poolAddress
            )
            if (tx) {
                toast.success("Claim request has been submitted")
                setClaimTxhash(tx.hash)
            } else {
                setRequestedClaim(false)
            }
        } catch (e) {
            setRequestedClaim(false)
            toast.error(e.message)
        }
    }, [onClaim, formValidity])

    useEffect(() => {
        if (poolDetails && (investAmount + poolDetails.invetment < poolDetails.minInvest || investAmount + poolDetails.invetment > poolDetails.maxInvest)) {
            setFormValidity('INVLAID_AMOUNT')
        } else {
            setFormValidity('VALID')
        }
    }, [investAmount, poolDetails])

    useEffect(() => {
        if (!poolDetails) { return }
        if (poolDetails.tokenPrice <= 0) { return }
        let amount = investAmount / poolDetails.tokenPrice
        setReciveAmount(amount)
    }, [investAmount, poolDetails])

    const showFormValidityToast = function (formValidity: any) {
        switch (formValidity) {
            case 'INVLAID_AMOUNT':
                toast.error('Please enter valid token amount')
                break;
        }
    }

    // const viewOnExplorer = function() {
    //     console.log(requestedInvest, requestedApproval, requestedClaim)
    //     return requestedInvest || requestedApproval || requestedClaim
    // }

    const fetchDetails = useCallback(async () => {
        try {
            dispatch({ type: 'SET_LOADING', payload: true })
            let poolDetails = await onFetchPoolDetails((props.id).toString())
            dispatch({ type: 'SET_LOADING', payload: false })
            console.log(poolDetails)
            setPoolDetails(poolDetails)
        } catch (e) {
            dispatch({ type: 'SET_LOADING', payload: false })
        }
    }, [onFetchPoolDetails])

    return (
        <section className="section illustration-section-01">
            <div className="container">
                {/* <SectionHeader tag="h1" data={sectionHeader} className="center-content" /> */}
                {!account && <h5 className="connect-wallet-notice">Connect wallet to continue</h5>}
                {state.loading && account && !poolDetails && <div className="loading-container loading-container-pool-details">
                    <DotLoader color={"#C2EAFF"} loading={state.loading} css={override} />
                </div>}
                {/* {account && !poolDetails && <DotLoader color={"#C2EAFF"} loading={state.loading} css={override} />} */}
                {account && poolDetails &&
                    <div className="pool-details-page mt-32">
                        <div className="pool-brand-details">
                            <div className="pool-img">
                                <img src={require("../../assets/images/logo_symbol.png")} />
                            </div>
                            <div className="pool-title">
                                <div className="title-status">
                                    <h4 className="m-0">{poolDetails.saleTitle}</h4>
                                    <div className="pool-status">
                                        {Number(poolDetails.openTime * 1000) >= Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                            <h6 className="m-0 text-xxs interval">
                                                Opens In {formatDuration(intervalToDuration({ start: new Date(Number(poolDetails.openTime * 1000)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                                            </h6>
                                        )}
                                        {poolDetails.totalCollected >= poolDetails.hardCap && (
                                            <h6 className="m-0 text-xxs filled">
                                                Filled
                                            </h6>
                                        )}
                                        {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                            <h6 className="m-0 text-xxs open">
                                                Open
                                            </h6>
                                        )}
                                        {Number(poolDetails.closeTime * 1000) < Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                            <h6 className="m-0 text-xxs close">
                                                Close
                                            </h6>
                                        )}
                                    </div>
                                </div>

                                <p className="m-0 mb-16 pool-description">MaticLaunch is a decentralized fundraising platform that aims to disrupt the traditional crowdfunding methods involving large scale investors by giving back power to the community.</p>
                                <div className="pool-social">
                                    <ul className="list-reset d-flex mb-0 social-list align-items-center">
                                        <li>
                                            <a href="https://maticlaunch.org/">
                                                <svg
                                                    width="20"
                                                    height="19"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <title>Website</title>
                                                    <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://telegram.me/maticlaunch">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 26 21.667"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <title>Telegram</title>
                                                    <path id="telegram-1" d="M10.2,16.28l-.43,6.049a1.5,1.5,0,0,0,1.2-.582l2.885-2.757,5.978,4.378c1.1.611,1.869.289,2.165-1.009L25.925,3.973h0c.348-1.621-.586-2.254-1.654-1.857L1.207,10.945c-1.574.611-1.55,1.489-.268,1.886l5.9,1.834,13.7-8.57c.645-.427,1.231-.191.749.236Z" />
                                                </svg>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://telegram.me/maticlaunch">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <title>Github</title>
                                                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                                </svg>
                                            </a>
                                        </li> */}
                                        {/* <li>
                                        <a href="https://telegram.me/maticlaunch">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 16 16"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <title>Telegram</title>
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                                            </svg>
                                        </a>
                                    </li> */}
                                        <li>
                                            <a href="https://twitter.com/maticlaunch">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <title>Twitter</title>
                                                    <path
                                                        d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://maticlaunch.medium.com">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <title>Medium</title>
                                                    <path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" />
                                                </svg>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="deatils-table liquidity-details">
                                            <div className="table-title mb-16">
                                                <h5 className="text-center m-0">Join Pool</h5>
                                            </div>
                                            <div className="details">
                                                <div className="text-center">
                                                    <h6 className="m-0 mb-8">1 USDT = <span className="primary-color">{1 / Number(poolDetails.tokenPrice)} {poolDetails.tokenSymbol}</span></h6>
                                                </div>
                                                <div className="swap-progress">
                                                    <progress max="100" value={(Number(poolDetails.totalCollected) / Number(poolDetails.hardCap) * 100)}>
                                                        <div className="progress-bar">
                                                            <span style={{ width: Number(poolDetails.totalCollected) / Number(poolDetails.hardCap) * 100 }}>Progress: {(poolDetails.totalCollected / poolDetails.hardCap * 100).toFixed(2)}%</span>
                                                        </div>
                                                    </progress>
                                                    <div className="progress-markups d-flex justify-content-between">
                                                        <p className="text-xxs mt-0">{(Number(poolDetails.totalCollected) / Number(poolDetails.hardCap) * 100).toFixed(2)}% (Min {(poolDetails.softCap / poolDetails.hardCap * 100).toFixed(2)}%)</p>
                                                        <p className="text-xxs mt-0">{nFormatter(poolDetails.totalCollected, 3)} / {nFormatter(poolDetails.hardCap, 1)} USDT</p>
                                                    </div>
                                                </div>
                                                {/* {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && poolDetails.totalCollected < poolDetails.hardCap && ( */}


                                                <div className="swap-interface">
                                                    <div className="form-content">
                                                        <Form className="d-flex flex-column">
                                                            <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                                                <Form.Label>{poolDetails.invetment > 0 ? "You invested (USDT)" : "Enter Amount you want to Invest (USDT)"}</Form.Label>
                                                                <Form.Control
                                                                    className="form-input"
                                                                    type="number"
                                                                    placeholder="Enter USDT"
                                                                    value={poolDetails.invetment > 0 ? poolDetails.invetment : Number(investAmount).toString()}
                                                                    disabled={poolDetails.invetment > 0}
                                                                    onChange={({ target: { value } }) => {
                                                                        let val = Number(value)
                                                                        if (val) setInvestAmount(Math.round(val))
                                                                        else setInvestAmount(0)
                                                                    }} />
                                                                {/* <Form.Text className="text-muted">
                                                                We'll never share your email with anyone else.
                                                            </Form.Text> */}
                                                            </Form.Group>
                                                            {poolDetails.invetment <= 0 && (<Button
                                                                className="button button-primary button-max"
                                                                variant="primary"
                                                                onClick={() => setInvestAmount(poolDetails.maxInvest)}>MAX</Button>)}
                                                            <Form.Group className="mb-3 d-flex flex-column" controlId="formBasicPassword">
                                                                <Form.Label>You will get ({poolDetails.tokenSymbol})</Form.Label>
                                                                <Form.Control
                                                                    className="form-input"
                                                                    type="text"
                                                                    placeholder={poolDetails.tokenSymbol}
                                                                    value={poolDetails.invetment > 0 ? (poolDetails.invetment / poolDetails.tokenPrice) + ' ' + poolDetails.tokenSymbol : Number(reciveAmount) + ' ' + poolDetails.tokenSymbol}
                                                                    disabled={true} />
                                                            </Form.Group>
                                                            {(isApproveOrInvestOrClaimInProgress()) && <Button
                                                                className="button button-primary "
                                                                variant="primary"
                                                                // onClick={() => handleApprove()}
                                                                disabled={true}>
                                                                <PropagateLoader color={"#C2EAFF"} loading={true} />
                                                            </Button>}
                                                            {!requestedInvest && !(Number(poolDetails.closeTime * 1000) < Date.now() && poolDetails.totalCollected < poolDetails.hardCap) && poolDetails.invetment <= 0 && <Button
                                                                className="button button-primary "
                                                                variant="primary"
                                                                onClick={() => handleInvest()}
                                                                disabled={false}>
                                                                Buy
                                                            </Button>}
                                                            {(!isApproveOrInvestOrClaimInProgress()) && !(Number(poolDetails.closeTime * 1000) < Date.now() && poolDetails.totalCollected < poolDetails.hardCap) && poolDetails.invetment > 0 && poolDetails.invetment < poolDetails.maxInvest && <Button
                                                                className="button button-primary "
                                                                variant="primary"
                                                                onClick={() => setAddMoreModalShow(true)}
                                                                disabled={false}>
                                                                Add More
                                                            </Button>}
                                                            {poolDetails.invetment >= poolDetails.maxInvest && <Button
                                                                className="button button-primary "
                                                                variant="primary"
                                                                // onClick={() => handleClaim()}
                                                                disabled={true}>
                                                                Max Cap Filled
                                                            </Button>}
                                                            {!(poolDetails.invetment >= poolDetails.maxInvest) && Number(poolDetails.closeTime * 1000) < Date.now() && poolDetails.totalCollected < poolDetails.hardCap && <Button
                                                                className="button button-primary "
                                                                variant="primary"
                                                                // onClick={() => handleClaim()}
                                                                disabled={true}>
                                                                Sale is Closed
                                                            </Button>}
                                                        </Form>
                                                        {requestedInvest && investTxhash && <div className="view-on-explorer">
                                                            <a className="text-xxs" target="_blank" href={getChainDetails(chainId)?.url + "/tx/" + investTxhash}>View On Explorer</a>
                                                        </div>}
                                                        {requestedApproval && approveTxhash && <div className="view-on-explorer">
                                                            <a className="text-xxs" target="_blank" href={getChainDetails(chainId)?.url + "/tx/" + approveTxhash}>View On Explorer</a>
                                                        </div>}
                                                        {requestedClaim && claimTxhash && <div className="view-on-explorer">
                                                            <a className="text-xxs" target="_blank" href={getChainDetails(chainId)?.url + "/tx/" + claimTxhash}>View On Explorer</a>
                                                        </div>}
                                                    </div>
                                                    {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && poolDetails.totalCollected < poolDetails.hardCap && poolDetails.onlyWhitelistedAddressesAllowed && !poolDetails.isWhitlisted &&
                                                        <div className="overlay-error-content">
                                                            <h6>You are not whitelisted</h6>
                                                        </div>}
                                                    {poolDetails.totalCollected >= poolDetails.hardCap &&
                                                        <div className="overlay-error-content">
                                                            <h6>Sale is filled</h6>
                                                        </div>}
                                                    {Number(poolDetails.openTime * 1000) >= Date.now() && poolDetails.totalCollected < poolDetails.hardCap &&
                                                        <div className="overlay-error-content">
                                                            <div className="d-flex flex-column whitelist-container">
                                                                <h6 className="open-in">Opens In {formatDuration(intervalToDuration({ start: new Date(Number(poolDetails.openTime * 1000)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}</h6>
                                                                {poolDetails.isWhitlisted && (<h6 className="whitelisted-in">You are whitelisted, Keep your fund ready</h6>)}
                                                                {!poolDetails.isWhitlisted && (<h6 className="whitelisted-in">You are not whitelisted</h6>)}
                                                            </div>
                                                        </div>}
                                                    {poolDetails.invetment <= 0 && Number(poolDetails.closeTime * 1000) < Date.now() && poolDetails.totalCollected < poolDetails.hardCap &&
                                                        <div className="overlay-error-content">
                                                            <h6>Sale is Closed</h6>
                                                        </div>}



                                                    {/* {Number(poolDetails.openTime * 1000) >= Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs interval">
                                                                Opens In {formatDuration(intervalToDuration({ start: new Date(Number(poolDetails.openTime * 1000)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                                                            </h6>
                                                        )}
                                                        {poolDetails.totalCollected >= poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs filled">
                                                                Filled
                                                            </h6>
                                                        )}
                                                        {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs open">
                                                                Open
                                                            </h6>
                                                        )}
                                                        {Number(poolDetails.closeTime * 1000) < Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs close">
                                                                Close
                                                            </h6>
                                                        )} */}
                                                    {/* {poolDetails.claimed &&
                                                        <div className="overlay-error-content">
                                                            <h6>Thank you for participation, you claimed you token sucessfully</h6>
                                                        </div>} */}

                                                </div>
                                                {/* )} */}
                                                <div className="row mt-16">
                                                    <div className="col-6 text-center border-right">
                                                        <p className="text-xs m-0">Minimum</p>
                                                        <p>{nFormatter(poolDetails.minInvest, 1)} USDT</p>
                                                    </div>
                                                    <div className="col-6 text-center">
                                                        <p className="text-xs m-0">Maximum</p>
                                                        <p>{nFormatter(poolDetails.maxInvest, 1)} USDT</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="details-table-wrapper">
                                            <div className="deatils-table pool-details">
                                                <div className="table-title mb-16">
                                                    <h5 className="text-center m-0">Governance</h5>
                                                </div>
                                                <div className="governance-details">
                                                    <div className="details">
                                                        <div className="text-center">
                                                            <h6 className="m-0 mb-8">Yes {'>'} No Vote = <span className="primary-color">TRUE</span></h6>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-center border-right">
                                                                <p className="text-xs m-0">Yes Vote</p>
                                                                <p>1000 MTCL</p>
                                                            </div>
                                                            <div className="col-6 text-center">
                                                                <p className="text-xs m-0">No Vote</p>
                                                                <p>255 MTCL</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="overlay-message">
                                                        <h6>Coming soon</h6>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="deatils-table pool-details">
                                            <div className="table-title">
                                                <h5 className="text-center m-0">Pool Details</h5>
                                            </div>
                                            <div className="details">
                                                <div className="d-flex justify-content-between">
                                                    <p>Softcap</p>
                                                    <p>{nFormatter(poolDetails.softCap, 1)} USDT</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Hardcap</p>
                                                    <p>{nFormatter(poolDetails.hardCap, 1)} USDT</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Open Time</p>
                                                    <p>{new Date(Number(poolDetails.openTime) * 1000).toUTCString()}</p>
                                                    {/* <p>{format(new Date(Number(poolDetails.openTime) * 1000), 'hh:mm a, MMMM dd yyyy (xxxxx)')}</p> */}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Close Time</p>
                                                    <p>{new Date(Number(poolDetails.closeTime) * 1000).toUTCString()}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Access</p>
                                                    <p>{poolDetails.onlyWhitelistedAddressesAllowed ? "Private" : "Public"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="deatils-table liquidity-details">
                                            <div className="table-title">
                                                <h5 className="text-center m-0">Liquidity Details</h5>
                                            </div>
                                            <div className="details">
                                                <div className="d-flex justify-content-between">
                                                    <p>Liquidity Rate</p>
                                                    <p>{poolDetails.lpListingPrice} USDT</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Liquidity Allocation</p>
                                                    <p>{poolDetails.lpLiquidityPercentageAllocation}%</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Lock Duration</p>
                                                    <p>{poolDetails.lpLPTokensLockDurationInDays} days</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>Listing Date</p>
                                                    <p>TBA</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p>DEX</p>
                                                    <p>Quickswap</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Modal
                show={addMoreModalShow}
                onHide={() => setAddMoreModalShow(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                        Add More
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-32">
                    {poolDetails && (
                        <Form className="d-flex flex-column">
                            <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                <Form.Label>{poolDetails.invetment > 0 ? "You invested (USDT)" : "Enter Amount you want to Invest (USDT)"}</Form.Label>
                                <Form.Control
                                    className="form-input"
                                    type="number"
                                    placeholder="Enter USDT"
                                    value={Number(investAmount).toString()}
                                    // disabled={poolDetails.invetment > 0}
                                    onChange={({ target: { value } }) => {
                                        let val = Number(value)
                                        if (val) setInvestAmount(Math.round(val))
                                        else setInvestAmount(0)
                                    }} />
                            </Form.Group>
                            {(<Button
                                className="button button-primary button-max"
                                variant="primary"
                                onClick={() => setInvestAmount(poolDetails.maxInvest - poolDetails.invetment)}>MAX</Button>)}
                            <Form.Group className="mb-3 d-flex flex-column" controlId="formBasicPassword">
                                <Form.Label>You will get ({poolDetails.tokenSymbol})</Form.Label>
                                <Form.Control
                                    className="form-input"
                                    type="text"
                                    placeholder={poolDetails.tokenSymbol}
                                    value={Number(reciveAmount) + ' ' + poolDetails.tokenSymbol}
                                    disabled={true} />
                            </Form.Group>
                            {(isApproveOrInvestOrClaimInProgress()) && <Button
                                className="button button-primary "
                                variant="primary"
                                // onClick={() => handleApprove()}
                                disabled={true}>
                                <PropagateLoader color={"#C2EAFF"} loading={true} />
                            </Button>}
                            {!requestedInvest && poolDetails.invetment <= poolDetails.maxInvest && <Button
                                className="button button-primary "
                                variant="primary"
                                onClick={() => handleInvest()}
                                disabled={false}>
                                Buy
                            </Button>}
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default PoolDetailSection