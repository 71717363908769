import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import useRefresh from './useRefresh'
import { isAddress } from '../utils'
import { getTokenContract } from '../utils/contractHelpers'
import { getAddress } from '../utils/addressHelpers'
// import { getVaultAddress } from '../utils/addressHelpers'

export const useAllowance = (poolDetails: any): string | null | undefined => {
    const [allowance, setAllowance] = useState<string>()
    const { account, library, chainId } = useWeb3React()
    
    const { fastRefresh } = useRefresh()

    useEffect(() => {
        const fetchAllowance = async () => {
            // const address = isAddress(tokenAddress)
            if (!account || !chainId) return null
            try {
                const tokenContract = getTokenContract(getAddress(chainId, 'usdt'), library, account)
                const res = await tokenContract?.allowance(account, poolDetails.poolAddress)
                setAllowance(res.toString())
            }
            catch(error) {
                return null
            }
        }

        if (poolDetails && poolDetails.tokenAddress && account && poolDetails.poolAddress) {
            fetchAllowance()
        }
    }, [account, fastRefresh])

    return allowance
}