import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Fundraising Redefined',
      paragraph: 'MaticLaunch aims to disrupt the traditional fundraising method involving large scale investors by giving back power to community'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile " data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    MaticLaunch Infrastructure
                  </h3>
                  <p className="m-0 mb-12 paragraph text-content">
                    Numerous issues faced by launchpads built on multiple
                    blockchains motivated us to dive into layer 2 scaling solution in a
                    decentralized manner. MaticLaunch will be featuring a fully customizable 
                    and autonomous governance event and investment pool
                    creation infrastructure audited by leading firms.
                  </p>
                  <p className="m-0 paragraph text-content">
                    Permissionless investment pool creation functionalities will be
                    provided to blockchain entrepreneurs, diverting their skills into
                    core product development while the community will be vetting
                    projects by autopilot governance events, allowing them to move
                    into investment pools. Thanks to Polygon, the entire process will
                    be effortless while consuming extremely low gas fees.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile ',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  {/* <Image
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 01"
                    width={528}
                    height={396} /> */}
                  <div style={imgStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-matic-launch.png')}
                      alt="Features split top 01"
                      width={300}
                      height={300} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile " data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    MaticLaunch Token - $MTCL
                  </h3>
                  <p className="m-0 text-content">
                    MTCL is a core element to fuel ecosystem and will play a key role
                    in MaticLaunch’s decentralized fundraising platform. It is a native
                    utility token and utilized in the following applications:
                  </p>
                  <ul className="features-list list-reset text-sm mb-32">
                    <li className="is-checked"><span><strong className="text-white">Governance:</strong> MTCL token stakers will be able to participate in voting events determining project approval or rejections.</span></li>
                    <li className="is-checked"><span><strong className="text-white">Allocations:</strong> Based on number of MTCL tokens user have staked for certain period of time, s/he will be offered token sale allocations.</span></li>
                    <li className="is-checked"><span><strong className="text-white">Rewards:</strong> Based on users’ activities and governance and token sale participation, MTCL tokens will be rewarded in definite time frame.</span></li>
                    {/* <li className="is-checked">Yield Farming: To offer yield from token farming, MTCL tokens can also be deployed to various yield farming protocols as incentives.</li> */}
                    <li className="is-checked"><span><strong className="text-white">Liquidity Mining:</strong> Users will also be able to earn additional MTCL tokens from liquidity provision, pushing healthy volume on DEXs.</span></li>
                  </ul>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile ',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  {/* <Image
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} /> */}
                  <div style={imgStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-mtl.svg')}
                      alt="Features split top 02"
                      width={622}
                      height={510} />
                  </div>
                </div>
              </div>

              {/* <div className="split-item">
                <div className="split-item-content center-content-mobile " data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    Advanced features
                  </h3>
                  <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint occaecat cupidatat non proident.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-05 ',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div style={imgStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-03.png')}
                      alt="Features split top 03"
                      width={622}
                      height={510} />
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  // position: 'absolute',
  // width: '117.8%',
  // maxWidth: '117.8%',
  // top: '-9.9%',
  // left: '-5.87%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;