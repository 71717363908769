import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Product Roadmap',
      paragraph: 'Representation of what we have done so far, what are we doing currently and what can be expected from us in the future'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container ">
          <div className={innerClasses}>
            <div className="illustration-section-10 pb-32">
              <SectionHeader data={sectionHeader} className="center-content" />
              <Timeline>
                <TimelineItem title="Jan – March 2021">
                  Market Research <span className="seconadry-color"> || </span> Ideation, R&D <span className="seconadry-color"> || </span> Team Formation
              </TimelineItem>
                <TimelineItem title="April – May 2021">
                  Roadmap Creation <span className="seconadry-color"> || </span> Documentation <span className="seconadry-color"> || </span> Structure Formulation
              </TimelineItem>
                <TimelineItem title="June – August 2021">
                  MVP on Polygon Testnet <span className="seconadry-color"> || </span> Token Generation Event <span className="seconadry-color"> || </span> Pre-Sale and Public Sale <span className="seconadry-color"> || </span> DEX Listings <span className="seconadry-color"> || </span> IDO Onboarding
              </TimelineItem>
                <TimelineItem title="Sept – Dec 2021">
                  Partnerships <span className="seconadry-color"> || </span> Community Campaigns <span className="seconadry-color"> || </span> CEX Listing
              </TimelineItem>
                <TimelineItem title="Year 2022">
                  MaticLaunch v2.0 <span className="seconadry-color"> || </span> Community Feedback <span className="seconadry-color"> || </span> Potential Chains R&D
              </TimelineItem>
              </Timeline>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;