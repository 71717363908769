import React from 'react';
import StakingDetailsSection from '../components/sections/StakingDetails';

class Staking extends React.Component {
  render() {
    return (
      <StakingDetailsSection stakingPoolAddress={this.props.match.params.address} className="illustration-section-01" />
    );
  }
}

export default Staking;