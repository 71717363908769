import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import ConnectWallet from '../sections/WalletConnect';
import AccountDetails from '../sections/AccountDetails';

const ConnectWalletBtn = () => {

    const [connectWalletModalShow, setConnectWalletModalShow] = useState(false);
    const [accountDetailsModalShow, setAccountDetailsModalShow] = useState(false);
    const { account, active } = useWeb3React();
    const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;

    function onConnectWalletClick() {
        if (!active) {
            setConnectWalletModalShow(true)
            return
        }
        setAccountDetailsModalShow(true)
    }

    return (
        <>
            <button className="button button-primary button-wide-mobile button-sm" onClick={onConnectWalletClick}>
            { !active ? 'CONNECT WALLET' : accountEllipsis } 
            </button>
            <ConnectWallet
                show={connectWalletModalShow}
                onHide={() => setConnectWalletModalShow(false)}
            />
            <AccountDetails
                show={accountDetailsModalShow}
                onHide={() => setAccountDetailsModalShow(false)}
            />
        </>
    )
}

export default ConnectWalletBtn