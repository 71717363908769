const baseUrl = "https://maticlaunch.org/api/"
// const baseUrl = "http://localhost:3000/"

export function getPools() {
    let url = baseUrl + "pools/getPools"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
    })
        .then(data => data.json())
}

export function getPoolById(poolId: any) {
    let url = baseUrl + "pools/getPoolById"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({ poolId })
    })
        .then(data => data.json())
}


export function getAddressService(account: string, secreat: string) {
    let url = baseUrl + "address/getAddress"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({ accountAddress: account, secreat: secreat })
    })
        .then(data => data.json())
}


export function addAddress(account: string, solanaAddress: string, secreat: string) {
    let url = baseUrl + "address/addAddress"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({ accountAddress: account, solanaAddress: solanaAddress, secreat: secreat })
    })
        .then(data => data.json())
}
