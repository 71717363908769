import React, { useCallback, useContext, useEffect } from 'react'
import SectionHeader from './partials/SectionHeader';
import PoolCard from "../elements/PoolCard";
import { Context } from '../../store/store';
import ClockLoader from "react-spinners/ClockLoader";
import { useCreatePool } from '../../hooks/factory';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
import { formatBytes32String } from "@ethersproject/strings"
import { useFactoryApprove } from '../../hooks/useApprove';
import { Form } from 'react-bootstrap';

function CreatePoolSection(props: any) {
    const sectionHeader = {
        title: 'Create Pool',
        paragraph: ''
    };

    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    let saleTokenAddress = "0x000000000000000000000000000000000000dead"
    let unsoleReciverAddress = "0xA802F02594f729163443752982931e687CaBaAa3"
    let tokenPrice = "0.05"
    let hardCap = "100000"
    let softCap = "0"
    let maxInvest = "2000"
    let minInvest = "100"
    let saleOpenTime = "1641803400"
    let saleCloseTime = "1641889799"
    let listingPrice = "0.05"
    let lpAddTime = "1643185800"
    let lpLockDurationInDays = "365"
    let lpPercentAllocation = "200"
    let title = "VaultHill IDO"
    let userTelegram = "Vaulthill"
    // let userGithub = "maticlaunch"
    let userTwitter = "vault_hill"
    let website = "vaulthill.io"
    let logoLink = "vaulthill.io"
    let poolWithoutLiquidity = true
    let listingReleasePercent = "15"
    let vestingWindow = "43200"
    let vestingIteration = "9"

    const [state, dispatch] = useContext(Context);
    const { onHandleCreatePool } = useCreatePool()
    const { onApprove } = useFactoryApprove()

    const onCreatePoolClick = useCallback(async () => {
        try {
            let tx = await onHandleCreatePool(
                saleTokenAddress,
                unsoleReciverAddress,
                new BigNumber(tokenPrice).times(new BigNumber(10).pow(6).toString()).toString(),
                new BigNumber(hardCap).times(new BigNumber(10).pow(6).toString()).toString(),
                new BigNumber(softCap).times(new BigNumber(10).pow(6).toString()).toString(),
                new BigNumber(maxInvest).times(new BigNumber(10).pow(6).toString()).toString(),
                new BigNumber(minInvest).times(new BigNumber(10).pow(6).toString()).toString(),
                saleOpenTime,
                saleCloseTime,
                new BigNumber(listingPrice).times(new BigNumber(10).pow(6).toString()).toString(),
                lpAddTime,
                lpLockDurationInDays,
                lpPercentAllocation,
                poolWithoutLiquidity,
                formatBytes32String(title),
                formatBytes32String(userTelegram),
                formatBytes32String(userTwitter),
                formatBytes32String(website),
                formatBytes32String(logoLink),
                listingReleasePercent,
                vestingWindow,
                vestingIteration
            )
            if (tx) {
                console.log("Pool created", tx)
            } else {
                console.log("error")
            }
        } catch (e: any) {
            toast.error(e.message)
        }
    }, [onHandleCreatePool])

    const onApproveClick = useCallback(async () => {
        try {
            let tx = await onApprove(saleTokenAddress)
            if (tx) {
                toast.success("Approve request has been submitted")
            } else {
                console.log("error")
            }
        } catch (e: any) {
            toast.error(e.message)
        }

    }, [onApprove])

    return (
        <section className="section">
            <div className="container-md">
                <SectionHeader tag="h3" data={sectionHeader} className="center-content" />
                <ClockLoader color={"#C2EAFF"} loading={state.loading} css={override} />
                <Form className="row">
                    {/* <div className="row">
                    </div> */}
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Pool Title</Form.Label>
                        <Form.Control type="text" placeholder="Enter Title Address" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Website url</Form.Label>
                        <Form.Control type="url" placeholder="Enter Website URL" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Logo url</Form.Label>
                        <Form.Control type="url" placeholder="Enter Logo URL" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Telegram Username</Form.Label>
                        <Form.Control type="text" placeholder="Enter Telegram Username" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Github Username</Form.Label>
                        <Form.Control type="text" placeholder="Enter Github Username" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Twitter Username</Form.Label>
                        <Form.Control type="text" placeholder="Enter Twitter Username" className="form-input" />
                    </Form.Group>



                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Token Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter Token Address" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Token Price (USDT)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Token Price in USDT" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Hardcap (USDT)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Hardcap in USDT" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Softcap (USDT)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Softcap in USDT" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Min Invest (After Guaranteed allocation Period)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Minimum Investment in USDT" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Max Invest (After Guaranteed allocation Period)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Maximum Investment in USDT" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Sale Open time (In your local timezone)</Form.Label>
                        <Form.Control type="datetime-local" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Sale Close time (In your local timezone)</Form.Label>
                        <Form.Control type="datetime-local" placeholder="Enter sale close time" className="form-input" />
                    </Form.Group>

                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Liquidity Allocation</Form.Label>
                        <Form.Control type="number" placeholder="Enter Liquidity allocation in %" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Liquidity Listing Price (USDT)</Form.Label>
                        <Form.Control type="number" placeholder="Enter Liquidity Listing price in USDT" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Liquidity add time (In your local timezone)</Form.Label>
                        <Form.Control type="datetime-local" placeholder="Enter Liquidity Add time" className="form-input" />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Liquidity Lock duration in days</Form.Label>
                        <Form.Control type="number" placeholder="Enter Liquidity lock time" className="form-input" />
                    </Form.Group>

                    <Form.Group className="mb-3 d-flex flex-column col-6" controlId="formBasicEmail">
                        <Form.Label>Unsold token Reciver address</Form.Label>
                        <Form.Control type="text" placeholder="Enter Unsold token Reciver address" className="form-input" />
                    </Form.Group>



                </Form>
                <button onClick={onApproveClick}>Approve</button>
                <button className="button button-primary" onClick={onCreatePoolClick}>Create</button>
            </div>
        </section>
    )
}

export default CreatePoolSection