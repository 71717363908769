import { useCallback, useEffect, useState, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import { approve, investPool, listingClaim, vestingClaim, whitelistPool } from '../utils/callHelpers'
import { getAddress } from '../utils/addressHelpers'
import { getInfoContract, getPoolContract, getTokenContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify'
import BigNumber from 'bignumber.js';
import { parseBytes32String } from "@ethersproject/strings";
import { getBalanceNumber } from "../utils/formatBalance"
import useRefresh from './useRefresh';
import { Context } from '../store/store';
import { isMobile } from 'react-device-detect'
import { getPoolById } from '../services/pool'

export const useFactoryApprove = () => {
    const { account, library, chainId } = useWeb3React()

    const handleApprove = useCallback(async (tokenAddress) => {
        try {
            if (!library || !account || !chainId) return false
            let tokenContract = getTokenContract(tokenAddress, library, account)
            if (!tokenContract) return false
            const tx = await approve(tokenContract, getAddress(chainId, 'factory'), account)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onApprove: handleApprove }
}

export const useInvest = () => {
    const { account, library, chainId } = useWeb3React()

    const handleInvest = useCallback(async (poolAddress, investAmount) => {
        try {
            if (!library || !account || !chainId) return false
            // let tokenContract = getTokenContract(tokenAddress, library, account)
            let poolContract = getPoolContract(poolAddress, chainId, library, account)
            if (!poolContract) return false
            const tx = await investPool(poolContract, investAmount)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onInvest: handleInvest }
}

export const useWhitlist = () => {
    const { account, library, chainId } = useWeb3React()

    const handleWhitelist = useCallback(async (poolAddress: string, whitelistAddress: string[]) => {
        try {
            if (!library || !account || !chainId) return false
            // let tokenContract = getTokenContract(tokenAddress, library, account)
            let poolContract = getPoolContract(poolAddress, chainId, library, account)
            if (!poolContract) return false
            const tx = await whitelistPool(poolContract, whitelistAddress)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onWhitelist: handleWhitelist }
}

export const useListingClaim = () => {
    const { account, library, chainId } = useWeb3React()

    const handleClaim = useCallback(async (poolAddress) => {
        try {
            if (!library || !account || !chainId) return false
            // let tokenContract = getTokenContract(tokenAddress, library, account)
            let poolContract = getPoolContract(poolAddress, chainId, library, account)
            if (!poolContract) return false
            const tx = await listingClaim(poolContract)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onListingClaim: handleClaim }
}

export const useVestingClaim = () => {
    const { account, library, chainId } = useWeb3React()

    const handleClaim = useCallback(async (poolAddress) => {
        try {
            if (!library || !account || !chainId) return false
            // let tokenContract = getTokenContract(tokenAddress, library, account)
            let poolContract = getPoolContract(poolAddress, chainId, library, account)
            if (!poolContract) return false
            const tx = await vestingClaim(poolContract)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onVestingClaim: handleClaim }
}

export const useIntervalProgressFetch = () => {
    const { account, library, chainId } = useWeb3React()
    const [state, dispatch] = useContext(Context);

    const fetchRaised = useCallback(async (activePool, pools) => {
        if (!account || !chainId || activePool.length <= 0) {
            dispatch({ type: 'SET_POOLS', payload: pools })
            return null
        }
        try {
            let updates: any[] = []
            for (let i = 0; i < activePool.length; i++) {
                if(activePool[i].poolAddress === "0x0000000000000000000000000000000000000000") {
                    updates.push({
                        ...activePool[i],
                        totalCollected: 0
                    })
                } else {
                    const poolContract = getPoolContract(activePool[i].poolAddress, chainId, library, account)
                    if (!poolContract) return false
                    const totalCollected = await poolContract.totalCollectedWei()
                    updates.push({
                        ...activePool[i],
                        totalCollected: getBalanceNumber(totalCollected, 6)
                    })
                }
            }
            let updatedPools = pools
            updates.forEach((updatesPool) => {
                let index = updatedPools.findIndex((pool: any) => pool.poolId === updatesPool.poolId)
                updatedPools[index] = { ...updatesPool }
            })
            dispatch({ type: 'SET_POOLS', payload: pools })
        } catch (e) {
            console.log(e)
        }
    }, [account, chainId])

    return { onFetchRaised: fetchRaised }

    // useEffect(() => {
    //     const fetchRaised = async () => {
    //         if (!account || !chainId || state.activePools.length <= 0) {
    //             return null
    //         }
    //         try {
    //             let updates: any[] = []
    //             state.activePools.forEach(async (pool: any) => {
    //                 const poolContract = getPoolContract(pool.poolAddress, chainId, library, account)
    //                 if (!poolContract) return false
    //                 const totalCollected = await poolContract.totalCollectedWei()
    //                 updates.push({
    //                     ...pool,
    //                     totalCollected: getBalanceNumber(totalCollected)
    //                 })
    //                 let pools = state.pools
    //                 // var foundIndex = items.findIndex(x => x.id == item.id);
    //                 // items[foundIndex] = item;
    //                 updates.forEach((updatesPool) => {
    //                     let index = pools.findIndex((pool: any) => pool.poolId === updatesPool.poolId)
    //                     pools[index] = updatesPool
    //                 })
    //                 dispatch({type: 'SET_POOLS', payload: pools})
    //             });
    //         } catch (e) {
    //             console.log(e)
    //         }
    //     }

    //     if (account && !isMobile) {
    //         fetchRaised()
    //     }
    // }, [account, slowRefresh])

}

export const useIntervalPoolFetch = (poolDetails: any) => {
    // const [tokensLeft, setTokenLeft] = useState(poolDetails?.tokensLeft ?? "0")
    const [totalCollected, setTotalCollected] = useState(poolDetails?.totalCollected ?? "0")
    // const [isWhitlisted, setIsWhitlisted] = useState(poolDetails?.isWhitlisted ?? false)
    const [invetment, setInvetment] = useState(poolDetails?.invetment ?? "0")
    const [claimTracker, setClaimTracker] = useState()
    const { account, library, chainId } = useWeb3React()

    const { slowRefresh } = useRefresh()

    useEffect(() => {
        const fetchAllowance = async () => {
            // const address = isAddress(tokenAddress)
            if (!account || !chainId || !(poolDetails && poolDetails.poolAddress)) {
                return null
            }
            try {
                const poolContract = getPoolContract(poolDetails.poolAddress, chainId, library, account)
                if (!poolContract) return false
                // const tokensLeft = await poolContract.tokensLeft()
                // setTokenLeft(tokensLeft.toString())
                const totalCollected = await poolContract.totalCollectedWei()
                setTotalCollected(getBalanceNumber(totalCollected, 6))
                // const isWhitlisted = await poolContract.whitelistedAddresses(account)
                // setIsWhitlisted(isWhitlisted)
                const invetment = await poolContract.investments(account)
                setInvetment(getBalanceNumber(invetment, 6))
                const claimTracker = await poolContract.claimTracker(account)
                setClaimTracker(claimTracker.toString())
                // const claimed = await poolContract.claimed(account)
                // setClaimed(claimed)
                return {
                    ...poolDetails,
                    // tokensLeft,
                    totalCollected,
                    // isWhitlisted,
                    invetment,
                    claimTracker
                }
                // const tokenContract = getTokenContract(getAddress(chainId, 'usdt'), library, account)
                // const res = await tokenContract?.allowance(account, poolDetails.poolAddress)
                // setAllowance(new BigNumber(res.toString()))
            }
            catch (error) {
                console.log(error)
                return null
            }
        }

        if (account) {
            fetchAllowance()
        }
    }, [account, slowRefresh])

    if (claimTracker === undefined) {
        return poolDetails
    } else {
        return {
            ...poolDetails,
            // tokensLeft,
            totalCollected,
            // isWhitlisted,
            invetment,
            claimTracker
        }
    }

}

export const usePoolFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleFetchPoolDetails = useCallback(async (poolId: string, stakerFCFS = false) => {
        try {
            let res = await getPoolById(poolId)
            console.log(res)
            if(!res.status) throw new Error("Oops! somthing went wrong. please try again")
            let poolData = res.data.pools
            if (!account || !library || !chainId) return false
            // let infoContract = getInfoContract(chainId, library, account)
            // if (!infoContract) return false
            // const poolAddress = await infoContract.getPoolAddress(poolId)
            // if (!poolAddress) return false
            const poolAddress = poolData.poolAddress
            const poolContract = getPoolContract(poolData.poolAddress, chainId, library, account)
            if (!poolContract) return false
            const tokenAddress = poolData.saleTokenAddress
            // if (!tokenAddress) return false
            // const tokenContract = getTokenContract(tokenAddress, library, account)
            // if (!tokenContract) return false
            const tokenSymbol = poolData.saleTokenSymbol
            const totalTokens = poolData.totalTokens
            const tokensLeft = await poolContract.tokensLeft()
            const tokenPriceInWei = poolData.tokenPrice
            const totalCollectedWei = await poolContract.totalCollectedWei()
            const minInvestInWei = await poolContract.minInvestInWei()
            const maxInvestInWei = await poolContract.maxInvestInWei()
            const [minGuaranteedInWei, maxGuaranteedInWei] = await poolContract.getGuaranteedInvestAmount()
            let isRound2Eligible;
            if(stakerFCFS) {
                isRound2Eligible = await poolContract.isRound2Eligible(account)
            }
            
            // console.log(getBalanceNumber(minG), getBalanceNumber(maxG))
            // const isWhitlisted = await poolContract.whitelistedAddresses(account)
            const invetment = await poolContract.investments(account)
            const claimTracker = await poolContract.claimTracker(account)
            console.log(minGuaranteedInWei, maxGuaranteedInWei, claimTracker.toString())

            const listingReleasePercent = poolData.listingReleasePercent
            const vestingWindow = poolData.vestingWindow
            const vestingIteration = poolData.vestingIteration

            const openTime = poolData.saleOpenTime
            const closeTime = poolData.saleCloseTime
            const softCapInWei = poolData.softCap
            const hardCapInWei = poolData.hardCap
            const mtclOwnerApproved = poolData.mtclOwnerApproved
            // const onlyWhitelistedAddressesAllowed = await poolContract.onlyWhitelistedAddressesAllowed()

            const lpListingPriceInWei = poolData.listingPrice
            const lpLiquidityAddingTime = poolData.lpAddTime
            const lpLPTokensLockDurationInDays = poolData.lpLockDurationInDays
            const lpLiquidityPercentageAllocation = poolData.lpPercentAllocation
            const poolWithoutLiquidity = poolData.poolWithoutLiquidity
            const lpLiquidityAdded = await poolContract.lpLiquidityAdded()

            const saleTitle = poolData.saleTitle
            const description = poolData.description
            const linkTelegram = poolData.linkTelegram
            const linkGithub = poolData.linkGithub
            const linkTwitter = poolData.linkTwitter
            const linkWebsite = poolData.linkWebsite
            const linkLogo = poolData.linkLogo
            return {
                poolAddress,
                tokenAddress,
                tokenSymbol,
                totalTokens: totalTokens.toString(),
                tokensLeft: tokensLeft.toString(),
                tokenPrice: getBalanceNumber(tokenPriceInWei, 6),
                totalCollected: getBalanceNumber(totalCollectedWei, 6),
                minInvest: getBalanceNumber(minInvestInWei, 6),
                maxInvest: getBalanceNumber(maxInvestInWei, 6),
                minGuaranteed: getBalanceNumber(minGuaranteedInWei, 6),
                maxGuaranteed: getBalanceNumber(maxGuaranteedInWei, 6),
                // isWhitlisted: isWhitlisted,
                isWhitlisted: getBalanceNumber(maxGuaranteedInWei, 6) > 0,
                invetment: getBalanceNumber(invetment, 6),
                isRound2Eligible,
                claimTracker: claimTracker.toString(),
                // claimed: claimed,

                listingReleasePercent,
                vestingWindow,
                vestingIteration,

                openTime: openTime.toString(),
                closeTime: closeTime.toString(),
                softCap: getBalanceNumber(softCapInWei, 6),
                hardCap: getBalanceNumber(hardCapInWei, 6),
                mtclOwnerApproved: mtclOwnerApproved,
                // onlyWhitelistedAddressesAllowed,

                lpListingPrice: getBalanceNumber(lpListingPriceInWei, 6),
                lpLiquidityAddingTime: lpLiquidityAddingTime.toString(),
                lpLPTokensLockDurationInDays: lpLPTokensLockDurationInDays.toString(),
                lpLiquidityPercentageAllocation: lpLiquidityPercentageAllocation.toString(),
                poolWithoutLiquidity,
                lpLiquidityAdded,

                saleTitle: (saleTitle),
                description: (description),
                linkTelegram: (linkTelegram),
                linkGithub: (linkGithub),
                linkTwitter: (linkTwitter),
                linkWebsite: (linkWebsite),
                linkLogo: (linkLogo)
            }
        } catch (e: any) {
            toast.error(e.message)
        }
    }, [account, chainId])

    return { onFetchPoolDetails: handleFetchPoolDetails }
}