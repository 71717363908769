export const NetworkContextName = 'NETWORK';

export const CHAINID_URL_MAPPING = [{
    chainId: 1,
    chainName: 'Ethereum',
    name: 'Etherscan',
    currency: 'ETH',
    url: 'https://etherscan.io/'
},{
    chainId: 3,
    chainName: 'Ropsten Testnet',
    name: 'Etherscan',
    currency: 'ETH',
    url: 'https://ropsten.etherscan.io/'
},{
    chainId: 56,
    chainName: 'BSC',
    name: 'Bscscan',
    currency: 'BNB',
    url: 'https://bscscan.com/'
},{
    chainId: 97,
    chainName: 'BSC Testnet',
    name: 'Bscscan',
    currency: 'BNB',
    url: 'https://testnet.bscscan.com/'
},{
    chainId: 137,
    chainName: 'MATIC',
    name: 'Polygonscan',
    currency: 'MATIC',
    url: 'https://polygonscan.com/'
},{
    chainId: 80001,
    chainName: 'MATIC Testnet',
    name: 'Polygonscan',
    currency: 'MATIC',
    url: 'https://mumbai.polygonscan.com/'
}]