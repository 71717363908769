const baseUrl = "https://api.maticlaunch.org/"
// const baseUrl = "http://localhost:3000/"

export function getPoolProgress() {
    let url = baseUrl + "presale/getPoolProgress"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
    })
        .then(data => data.json())
}

export function getAddressDetails(address: string) {
    let url = baseUrl + "presale/getAccountDetails"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({ address: address })
    })
        .then(data => data.json())
}

export function getPublicPoolProgress() {
    let url = baseUrl + "public/getPoolProgress"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
    })
        .then(data => data.json())
}

export function getPublicAddressDetails(address: string) {
    let url = baseUrl + "public/getAccountDetails"
    return fetch(url, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({ address: address })
    })
        .then(data => data.json())
}

// export function getLockDetails(tokenAddress: string) {
//     let url = baseUrl + "vault/lockdata"
//     return fetch(url, {
//         method: "POST",
//         headers: {
//             'Content-type': 'application/json'
//         },
//         body: JSON.stringify({ tokenAddress: tokenAddress })
//     })
//         .then(data => data.json())
// }