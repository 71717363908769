import React from 'react'
import './pie-chart.scss';

function PieChart(props: any) {

    return (
        <>
            {/* <div className="container p-5"> */}
                <div className="progress-pie" data-percentage={Number(props.filled) > 100 ? 100 : Number(props.filled).toFixed(0)}>
                    <span className="progress-pie-left">
                        <span className="progress-pie-bar"></span>
                    </span>
                    <span className="progress-pie-right">
                        <span className="progress-pie-bar"></span>
                    </span>
                    <div className="progress-pie-value">
                        <h6 className="m-0">
                            {props.filled}%
                        </h6>
                        <p className="text-xs mb-0">Completed</p>
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default PieChart