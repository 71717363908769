import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { approve } from '../utils/callHelpers'
import { getAddress } from '../utils/addressHelpers'
import { getTokenContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify'

export const useFactoryApprove = () => {
    const { account, library, chainId } = useWeb3React()

    const handleApprove = useCallback(async (tokenAddress) => {
        try {
            if (!library || !account || !chainId) return false
            let tokenContract = getTokenContract(tokenAddress, library, account)
            if (!tokenContract) return false
            const tx = await approve(tokenContract, getAddress(chainId, 'factory'), account)
            return tx
        } catch (e) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onApprove: handleApprove }
}

export const usePoolUsdtApprove = () => {
    const { account, library, chainId } = useWeb3React()

    const handleApprove = useCallback(async (poolAddress) => {
        try {
            if (!library || !account || !chainId) return false
            let tokenContract = getTokenContract(getAddress(chainId, 'usdt'), library, account)
            if (!tokenContract) return false
            const tx = await approve(tokenContract, poolAddress, account)
            return tx
        } catch (e) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onApprove: handleApprove }
}