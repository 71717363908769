import React from 'react'
import { Modal } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'
// import connectors from './config'
import { injected } from '../../../connectors'
import { toast } from 'react-toastify'

// export declare type loginStatus = "authereum" | "fortmatic" | "frame" | "injected" | "portis" | "squarelink" | "torus" | "walletconnect" | "walletlink" | "bsc";

function ConnectWallet(props: any) {

    const { activate } = useWeb3React()

    const handleLogin = () => {

        props.onHide()
        return activate(injected, undefined, true).catch((e) => {
            // alert(e.message)
            toast.error(e.message)
        })
    }

    return (
        <Modal
            {...props}
            size="md"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                    Connect wallet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-32">
                <div className="wallet-item"
                    onClick={() => {
                        window.localStorage.setItem('loginStatus', 'injected');
                        handleLogin()
                    }}>
                    <div className="d-flex p-3 align-items-center">
                        <img alt="metamask" width="35px" src={require("../../../assets/images/metamask.svg")} />
                        <h5 className="m-0 ml-16">Metamask</h5>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConnectWallet