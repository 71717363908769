import { useCallback, useEffect, useState, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import { isAddress } from '../utils'
import { toast } from 'react-toastify'
import  bcrypt from 'bcryptjs';

import { getAddressService, addAddress } from '../services/pool'

export const useAddressFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleAddressFetch = useCallback(async (poolAddress: string) => {
        try {
            if (!account || !library || !chainId) return false
            let salt = await bcrypt.genSalt(10)
            let hash = await bcrypt.hash(poolAddress, salt)
            let res = await getAddressService(account, hash)
            console.log(res)
            if (res.status === "error") throw new Error("Oops! somthing went wrong. please try again")
            let address = res.data.solanaAddress

            return {
                address
            }
        } catch (e: any) {
            toast.error(e.message)
        }
    }, [account, chainId])

    return { onAddressFetch: handleAddressFetch }
}

export const useInsertAddress = () => {
    const { account, library, chainId } = useWeb3React()

    const handleInsertAddress = useCallback(async (poolAddress: string, solanaAddress: string) => {
        // try {
            if (!account || !library || !chainId) return false
            let salt = await bcrypt.genSalt(10)
            let hash = await bcrypt.hash(poolAddress, salt)
            let res = await addAddress(account, solanaAddress, hash)
            console.log(res)
            if (res.status === "error") throw new Error(res.message)
            toast.success(res.message)
            return {
                status: res.status
            }
        // }
    }, [account, chainId])

    return { onAddressInsert: handleInsertAddress }
}