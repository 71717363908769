import React from 'react'
import { Modal } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'
import { getChainDetails } from '../../../utils/index'

function AccountDetails(props: any) {
    const { account, deactivate, chainId } = useWeb3React()
    return (
        <Modal
            {...props}
            size="lg"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                    Connected Wallet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-center text-truncate">{account}</h6>
                <div className="d-flex justify-content-around mt-4 mb-3">
                    <button className='button button-secondary ml-5' onClick={() => {
                        deactivate()
                        window.localStorage.removeItem('loginStatus');
                        props.onHide()
                        window.location.reload()
                    }}>Logout</button>
                    <a className="button button-primary mr-5" target="_blank" href={getChainDetails(chainId)?.url + 'address/' + account} >{"View on " + getChainDetails(chainId)?.name}</a>
                    {/* <button className='btn btn-view-on mr-5'>View</button> */}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AccountDetails