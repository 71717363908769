const Reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SET_POOLS':
            return {
                ...state,
                pools: action.payload
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload
            }
        case 'SET_ACTIVE_POOLS': 
            return {
                ...state,
                activePools: action.payload
            }
        // case 'ADD_POST':
        //     return {
        //         ...state,
        //         posts: state.posts.concat(action.payload)
        //     };
        // case 'SET_ERROR':
        //     return {
        //         ...state,
        //         error: action.payload
        //     };
        default:
            return state;
    }
};

export default Reducer;