import React, { useState } from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
// import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import { Modal } from 'react-bootstrap';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

// class Footer extends React.Component {
function Footer(props) {

  const [disclaimerModalShow, setDisclaimerModalShow] = useState(false);

  // render() {
  // const {
  //   className,
  //   topOuterDivider,
  //   topDivider,
  //   ...props
  // } = props;

  const classes = classNames(
    'site-footer center-content-mobile',
    props.topOuterDivider && 'has-top-divider',
    props.className
  );

  return (
    <>
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              props.topDivider && 'has-top-divider'
            )}>
            <div className="footer-top space-between text-xxs">
              <Logo />
              <FooterSocial />
            </div>
            <div className="footer-bottom space-between text-xxs invert-order-desktop">
              {/* <FooterNav /> */}
              <div className="footer-copyright">
                <a className="h6" onClick={() => setDisclaimerModalShow(true)}>Disclaimer</a>
              </div>
              <div className="footer-copyright">&copy; 2022 MaticLaunch, all rights reserved</div>
            </div>
          </div>
        </div>
      </footer>
      <Modal
        show={disclaimerModalShow}
        onHide={() => setDisclaimerModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
            Disclaimer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-32">
          <ul>
            <li>
              MaticLaunch team neither endorse projects nor recommend the community to invest in projects listed on the platform.
            </li>
            <li>
              MaticLaunch always suggests <b>doing your own research</b> before investing as cryptocurrency investments are subject to high risks.
            </li>
            <li>
              Do not invest in projects listed on MaticLaunch if your applicable <b>jurisdiction prohibits</b> participation or ownership of cryptocurrencies.
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  )
}
// }

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;