import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState, useContext } from 'react'
import { stakeInIDOStakingPool, withdrawstakeInIDOStakingPool } from '../utils/callHelpers'
import { getInfoContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify'
import { getBalanceNumber } from '../utils/formatBalance'

export const useInfoStakingFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleFetchInfoStakingPoolDetails = useCallback(async () => {
        try {
            if (!account || !library || !chainId) return false
            const infoContract = getInfoContract(chainId, library, account)
            if (!infoContract) return false

            const minInvestorMTCLBalance = await infoContract.getMinInvestorMTCLBalance()
            const minUnstakeTime = await infoContract.getMinUnstakeTime()

            return {
                minInvestorMTCLBalance: getBalanceNumber(minInvestorMTCLBalance),
                minUnstakeTime: (minUnstakeTime).toString()
            }
        } catch (e: any) {
            console.log(e)
            toast.error(e.message)
        }
    }, [account, chainId])

    return { onFetchInfoStakingPoolDetails: handleFetchInfoStakingPoolDetails }
}