import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Switch from '../elements/Switch';
// import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types,
  builtSwitcher: PropTypes.bool,
  builtSlider: PropTypes.bool
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  builtSwitcher: false,
  builtSlider: false
}

class Built extends React.Component {

  // state = {
  //   priceChangerValue: "1",
  //   priceInput: {
  //     0: "1,000",
  //     1: "1,250",
  //     2: "1,500",
  //     3: "2,000",
  //     4: "2,500",
  //     5: "3,500",
  //     6: "6,000",
  //     7: "15,000",
  //     8: "50,000"
  //   },
  //   priceOutput: {
  //     plan1: {
  //       0: ["", "Free", ""],
  //       1: ["$", "13", "/m"],
  //       2: ["$", "17", "/m"],
  //       3: ["$", "21", "/m"],
  //       4: ["$", "25", "/m"],
  //       5: ["$", "42", "/m"],
  //       6: ["$", "58", "/m"],
  //       7: ["$", "117", "/m"],
  //       8: ["$", "208", "/m"]
  //     },
  //     plan2: {
  //       0: ["$", "13", "/m"],
  //       1: ["$", "17", "/m"],
  //       2: ["$", "21", "/m"],
  //       3: ["$", "25", "/m"],
  //       4: ["$", "42", "/m"],
  //       5: ["$", "58", "/m"],
  //       6: ["$", "117", "/m"],
  //       7: ["$", "208", "/m"],
  //       8: ["$", "299", "/m"]
  //     },
  //     plan3: {
  //       0: ["$", "17", "/m"],
  //       1: ["$", "21", "/m"],
  //       2: ["$", "25", "/m"],
  //       3: ["$", "42", "/m"],
  //       4: ["$", "58", "/m"],
  //       5: ["$", "117", "/m"],
  //       6: ["$", "208", "/m"],
  //       7: ["$", "299", "/m"],
  //       8: ["$", "499", "/m"]
  //     }
  //   }
  // }

  // slider = React.createRef();
  // sliderValue = React.createRef();

  // componentDidMount() {
  //   if (this.props.builtSlider) {
  //     this.slider.current.setAttribute('min', 0);
  //     this.slider.current.setAttribute('max', Object.keys(this.state.priceInput).length - 1);
  //     this.thumbSize = parseInt(window.getComputedStyle(this.sliderValue.current).getPropertyValue('--thumb-size'), 10);
  //     this.handleSliderValuePosition(this.slider.current);
  //   }
  // }

  // handleBuiltSwitch = (e) => {
  //   this.setState({ priceChangerValue: e.target.checked ? '1' : '0' });
  // }

  // handleBuiltSlide = (e) => {
  //   this.setState({ priceChangerValue: e.target.value });
  //   this.handleSliderValuePosition(e.target);
  // }

  // handleSliderValuePosition = (input) => {
  //   const multiplier = input.value / input.max;
  //   const thumbOffset = this.thumbSize * multiplier;
  //   const priceInputOffset = (this.thumbSize - this.sliderValue.current.clientWidth) / 2;
  //   this.sliderValue.current.style.left = input.clientWidth * multiplier - thumbOffset + priceInputOffset + 'px';
  // }

  // getBuiltData = (values, set) => {
  //   return set !== undefined ? values[this.state.priceChangerValue][set] : values[this.state.priceChangerValue];
  // }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      builtSwitcher,
      builtSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'built section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'built-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-08',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Built For',
      // paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore aliqua.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            {/* {builtSwitcher &&
              <div className="built-switcher center-content">
                <Switch
                  checked={this.state.priceChangerValue === '1' ? true : false}
                  onChange={this.handleBuiltSwitch}
                  rightLabel="Billed Annually">
                    Billed Monthly
                </Switch>
              </div>
            } */}
            {/* {builtSlider &&
              <div className="built-slider center-content">
                <label className="form-slider">
                  <span className="mb-16">How many users do you have?</span>
                  <input
                    type="range"
                    ref={this.slider}
                    defaultValue={this.state.priceChangerValue}
                    onChange={this.handleBuiltSlide}
                  />
                </label>
                <div ref={this.sliderValue} className="built-slider-value">
                  {this.getBuiltData(this.state.priceInput)}
                </div>
              </div>
            }             */}
            <div className={tilesClasses}>

              <div className="tiles-item" data-reveal-delay="100">
                <div className="tiles-item-inner">
                  <div className="built-item-content">
                    <div className="built-item-header pb-24 mb-24">
                      <div className="built-item-price mb-4">
                        <span className="built-item-price-currency h2">
                          #
                        </span>
                        <span className="built-item-price-amount h2">
                          Projects
                        </span>
                      </div>
                      <div className="text-xs">
                        Ingenious ideas built or building on Polygon will offered a permissionless fundraising platform for a successful launch. 
                      </div>
                    </div>
                    <div className="built-item-features mb-40">
                      <div className="built-item-features-title h5 text-color-high mb-24">
                        Key advantages to Projects
                      </div>
                      <ul className="built-item-features-list list-reset text-xs">
                        <li className="is-checked">Permissionless pool creation</li>
                        <li className="is-checked">Customizable token offerings</li>
                        <li className="is-checked">Effortless decentralized fundraising</li>
                        <li className="is-checked">Autonomous liquidity provision</li>
                        <li className="is-checked">Ape-minded community support</li>
                        <li className="is-checked">Marketing and technical advisory</li>
                        <li className="is-checked">Post-fundraising incubation grant</li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="built-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="http://cruip.com/">Start free trial</Button>
                  </div> */}
                </div>
              </div>

              {/* <div className="tiles-item reveal-scale-down">
                <div className="tiles-item-inner">
                  <div className="built-item-content">
                    <div className="built-item-header pb-24 mb-24">
                      <div className="built-item-price mb-4">
                        <span className="built-item-price-currency h2">
                          {this.getBuiltData(this.state.priceOutput.plan2, 0)}
                        </span>
                        <span className="built-item-price-amount h1">
                          {this.getBuiltData(this.state.priceOutput.plan2, 1)}
                        </span>
                      </div>
                      <div className="text-xs">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                      </div>
                    </div>
                    <div className="built-item-features mb-40">
                      <div className="built-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="built-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Excepteur sint occaecat velit</li>
                        <li className="is-checked">Excepteur sint occaecat velit</li>
                        <li className="is-checked">Excepteur sint occaecat velit</li>
                        <li className="is-checked">Excepteur sint occaecat velit</li>
                        <li>Excepteur sint occaecat velit</li>
                      </ul>
                    </div>
                  </div>
                  <div className="built-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="http://cruip.com/">Start free trial</Button>
                  </div>
                </div>
              </div> */}

              <div className="tiles-item" data-reveal-delay="100">
                <div className="tiles-item-inner">
                  <div className="built-item-content">
                    <div className="built-item-header pb-24 mb-24">
                      <div className="built-item-price mb-4">
                        <span className="built-item-price-currency h2">
                          #
                        </span>
                        <span className="built-item-price-amount h2">
                          Community
                        </span>
                      </div>
                      <div className="text-xs">
                        A fair chance to participate in Polygon-based early stage projects will be offered directly to the community.
                      </div>
                    </div>
                    <div className="built-item-features mb-40">
                      <div className="built-item-features-title h5 text-color-high mb-24">
                        Key advantages to Community
                      </div>
                      <ul className="built-item-features-list list-reset text-xs">
                        <li className="is-checked">Ability to approve/reject projects</li>
                        <li className="is-checked">Fair token sale allocations</li>
                        <li className="is-checked">Faster and economic transactions</li>
                        <li className="is-checked">Multi-currencies supported pools</li>
                        <li className="is-checked">Incentives for holding tokens</li>
                        <li className="is-checked">Accessibility to early stage ideas</li>
                        <li className="is-checked">Direct involvement with the projects</li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="built-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="http://cruip.com/">Start free trial</Button>
                  </div> */}
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Built.propTypes = propTypes;
Built.defaultProps = defaultProps;

export default Built;