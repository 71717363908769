import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Staking from './views/Staking';
import StakingDetails from './views/StakingDetails';
import Pools from './views/Pools';
import Secondary from './views/Secondary';
import PoolDetails from './views/PoolDetails';
import IDOPoolDetails from './views/IDOPoolDetails';
import IDOPoolDetailsSolchiks from './views/IDOPoolDetailsSolchiks';
import IDOPoolDetailsVaultHill from './views/IDOPoolDetailsVaulthill';
import IDOPoolDetailsTankWars from './views/IDOPoolDetailsTankWars';
import IDOPoolDetailsGameUp from './views/IDOPoolDetailsGameUp';
import IDOPoolDetailsVulturePeak from './views/IDOPoolDetailsVulturePeak'
import IDOPoolDetailsAssetFi from './views/IDOPoolDetailsAssetFi'
import IDOPoolDetailsGameYoo from './views/IDOPoolDetailsGameYoo'
import IDOPoolDetailsMollector from './views/IDOPoolDetailsMollector'
// import IdoPoolDetails from './views/PoolDetailsIdo'
import CreatePool from './views/CreatePool';
import Login from './views/Login';
import Signup from './views/Signup';

import Web3ReactManager from './components/Web3ReactManager'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BigNumber from 'bignumber.js'


// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 800,
})


toast.configure(
  {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  }
)

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Web3ReactManager>
            <Switch>
              <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
              <AppRoute exact path="/pools" component={Pools} layout={LayoutDefault} />
              {/* <AppRoute exact path="/pool/presale" component={Pools} layout={LayoutDefault} /> */}
              {/* <AppRoute exact path="/pool/ido" component={IdoPoolDetails} layout={LayoutDefault} /> */}
              <AppRoute exact path="/pool/:id/:address" component={IDOPoolDetails} layout={LayoutDefault} />  
              <AppRoute exact path="/pool/solchiks" component={IDOPoolDetailsSolchiks} layout={LayoutDefault} />   
              <AppRoute exact path="/pool/vaulthill" component={IDOPoolDetailsVaultHill} layout={LayoutDefault} />
              <AppRoute exact path="/pool/tankwars" component={IDOPoolDetailsTankWars} layout={LayoutDefault} /> 
              <AppRoute exact path="/pool/gameup" component={IDOPoolDetailsGameUp} layout={LayoutDefault} />  
              <AppRoute exact path="/pool/vulturepeak" component={IDOPoolDetailsVulturePeak} layout={LayoutDefault} /> 
              <AppRoute exact path="/pool/assetfi" component={IDOPoolDetailsAssetFi} layout={LayoutDefault} />
              <AppRoute exact path="/pool/gameyoo" component={IDOPoolDetailsGameYoo} layout={LayoutDefault} /> 
              <AppRoute exact path="/pool/mollector" component={IDOPoolDetailsMollector} layout={LayoutDefault} />         
              <AppRoute exact path="/staking" component={Staking} layout={LayoutDefault} />
              <AppRoute exact path="/staking/:address" component={StakingDetails} layout={LayoutDefault} />
              {/* <AppRoute exact path="/create-pool" component={CreatePool} layout={LayoutDefault} /> */}
              {/* <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} /> */}
              {/* <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} /> */}
            </Switch>
          </Web3ReactManager>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);