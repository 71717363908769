import React from 'react';
import StakingSection from '../components/sections/Staking';

class Staking extends React.Component {
  render() {
    return (
      <StakingSection className="illustration-section-01" />
    );
  }
}

export default Staking;