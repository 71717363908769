import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState, useContext } from 'react'
import { stakeInPool, withdrawstakeInPool } from '../utils/callHelpers'
import { getStakingPoolContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify'
import { getBalanceNumber } from '../utils/formatBalance'

export const useStake = () => {
    const { account, library, chainId } = useWeb3React()

    const handleStake = useCallback(async (stakePoolAddress, stakeAmount) => {
        try {
            if (!library || !account || !chainId) return false
            let stakePoolContract = getStakingPoolContract(stakePoolAddress, chainId, library, account)
            if (!stakePoolContract) return false
            const tx = await stakeInPool(stakePoolContract, stakeAmount)
            return tx
        } catch (e) {
            toast.error(e.data.message || e.message)
            return false
        }
    }, [account, chainId])

    return { onStake: handleStake }
}

export const useWithdrawStake = () => {
    const { account, library, chainId } = useWeb3React()

    const handleWithdrawStake = useCallback(async (stakePoolAddress, unstakeAmount) => {
        try {
            if (!library || !account || !chainId) return false
            let stakePoolContract = getStakingPoolContract(stakePoolAddress, chainId, library, account)
            if (!stakePoolContract) return false
            console.log(stakePoolContract, unstakeAmount)
            const tx = await withdrawstakeInPool(stakePoolContract, unstakeAmount)
            return tx
        } catch (e) {
            toast.error(e.data.message || e.message)
            return false
        }
    }, [account, chainId])

    return { onWithdrawStake: handleWithdrawStake }
}

export const useStakingPoolFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleFetchStakingPoolDetails = useCallback(async (stakePoolAddress: string) => {
        try {
            if (!account || !library || !chainId) return false
            const stakingPoolContract = getStakingPoolContract(stakePoolAddress, chainId, library, account)
            if (!stakingPoolContract) return false

            const stakedTotal = await stakingPoolContract.stakedTotal()
            const stakeOf = await stakingPoolContract.stakeOf(account)
            const earlyWithdrawReward = await stakingPoolContract.earlyWithdrawReward()
            const rewardBalance = await stakingPoolContract.rewardBalance()
            const stakedBalance = await stakingPoolContract.stakedBalance()

            return {
                stakedTotal: getBalanceNumber(stakedTotal),
                stakeOf: getBalanceNumber(stakeOf),
                earlyWithdrawReward: getBalanceNumber(earlyWithdrawReward),
                rewardBalance: getBalanceNumber(rewardBalance),
                stakedBalance: getBalanceNumber(stakedBalance),
            }
        } catch (e: any) {
            toast.error(e.message)
        }
    }, [account, chainId])

    return { onFetchStakingPoolDetails: handleFetchStakingPoolDetails }
}