import React from 'react';
import PoolsSection from '../components/sections/pools';

class Pools extends React.Component {
  componentDidMount(){
    document.title = "Pools | MaticLaunch"
  }
  render(props) {
    return (
      <PoolsSection className="illustration-section-01" />
    );
  }
}

export default Pools;