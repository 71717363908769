import { useCallback, useEffect, useState } from 'react'
// import BigNumber from 'bignumber.js'
// import { formatEther } from '@ethersproject/units'
// import { isAddress } from '../utils'
// import { approve, createLock, createMultipleLock, withdrawLock } from '../utils/callHelpers'
import { createPool } from '../utils/callHelpers'

import { useActiveWeb3React } from './index'
import { getFactoryContract } from '../utils/contractHelpers'
import { isAddress } from '../utils'
import { toast } from 'react-toastify';

export const useCreatePool = () => {
    const { library, account, chainId } = useActiveWeb3React()
    const factoryContract = getFactoryContract(chainId, library, account)

    const handleCreatePool = useCallback(async (
        saleTokenAddress: string,
        unsoleReciverAddress: string,
        tokenPrice: string,
        hardCap: string,
        softCap: string,
        maxInvest: string,
        minInvest: string,
        saleOpenTime: string,
        saleCloseTime: string,
        listingPrice: string,
        lpAddTime: string,
        lpLockDurationInDays: string,
        lpPercentAllocation: string,
        poolWithoutLiquidity: boolean,
        title: string,
        userTelegram: string,
        userTwitter: string,
        website: string,
        logoLink: string,
        listingReleasePercent: string,
        vestingWindow: string,
        vestingIteration: string) => {
        try {
            let _saleTokenAddress = isAddress(saleTokenAddress)
            let _unsoleReciverAddress = isAddress(unsoleReciverAddress)
            if (!factoryContract || !_saleTokenAddress || !_unsoleReciverAddress) { return false }
            const tx = await createPool(
                factoryContract, [
                saleTokenAddress,
                unsoleReciverAddress,
                tokenPrice,
                hardCap,
                softCap,
                maxInvest,
                minInvest,
                saleOpenTime,
                saleCloseTime
            ], [
                listingPrice,
                lpAddTime,
                lpLockDurationInDays,
                lpPercentAllocation,
                poolWithoutLiquidity
            ], [
                title,
                userTelegram,
                userTwitter,
                website,
                logoLink
            ], [
                listingReleasePercent,
                vestingWindow,
                vestingIteration
            ])
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId, factoryContract, library])

    return { onHandleCreatePool: handleCreatePool }
}

// export const useCreateLock = () => {
//     const { library, account, chainId } = useActiveWeb3React()
//     const vaultContract = getVaultContract(chainId, library, account)

//     const handleCreateLock = useCallback(async (saleTokenAddress: string, withdrawalAddress: string, amount: string, unlockTime: number, fees: string | null, purpose: number) => {
//         try {
//             let _saleTokenAddress = isAddress(saleTokenAddress)
//             let _withdrawalAddress = isAddress(withdrawalAddress)
//             if (!library || !account || !chainId || !vaultContract || !_saleTokenAddress || !_withdrawalAddress || !fees || purpose < 0) return false
//             const tx = await createLock(vaultContract, saleTokenAddress, _withdrawalAddress, amount, unlockTime, fees, purpose)
//             return tx
//         } catch (e) {
//             toast.error(e.message)
//             return false
//         }
//     }, [account, chainId, vaultContract, library])

//     return { onHandleLock: handleCreateLock }
// }

