import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Features',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item ">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/feature-decentralized.svg')}
                        alt="Features Decentralized"
                        className="p-4"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Decentralized
                    </h4>
                    <p className="m-0 text-sm">
                      Permissionless fundraising and trustless token distribution to help give wings to your next-gen ideas
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item " data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/feature-transactions.svg')}
                        alt="Features Transactions"
                        className="p-4"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Cheap Transactions
                    </h4>
                    <p className="m-0 text-sm">
                      Launchpad built on layer 2 blockchain solution enabling number of transactions with immensely low gas fees
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item " data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/feature-liquidity.svg')}
                        alt="Features Liquidity"
                        className="p-4"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Autopilot Liquidity
                    </h4>
                    <p className="m-0 text-sm">
                      Post-sale sustainable liquidity provision and automatic LP tokens custody to create trust and avoid rugs materially
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item ">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/feature-community.svg')}
                        alt="Features Community"
                        className="p-4"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Community-Driven
                    </h4>
                    <p className="m-0 text-sm">
                      Filters innovations by the mighty community directly with the help of blockchain-based governance mechanism.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item " data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/feature-deal.svg')}
                        alt="Features Fair Allocations"
                        className="p-4"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Fair Allocations
                    </h4>
                    <p className="m-0 text-sm">
                      Fair dispersion of token sale allocations utilizing unique participation method based on mandatory staking procedure
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item " data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/feature-growth.svg')}
                        alt="Features Inevitable Growth"
                        className="p-4"
                        width={80}
                        height={80} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Inevitable Growth
                    </h4>
                    <p className="m-0 text-sm">
                      Preprogrammed token burn function upon early unstaking events to entertain healthy token price and volume
                    </p>
                  </div>
                </div>
              </div>              

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;