// import BigNumber from 'bignumber.js'
// import { ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { MaxUint256 } from '@ethersproject/constants'
import { parseEther } from '@ethersproject/units'

export const approve = async (tokenContract: Contract, vaultAddress: string, account: string) => {
    return tokenContract
        .approve(vaultAddress, MaxUint256)
}

export const createPool = async (factoryContract: Contract, info: (string | string[])[], lpDetails: any[], linkInfo: string[], vestingInfo: string[]) => {
    console.log(info, lpDetails, linkInfo, vestingInfo)
    return factoryContract
        .createPool(info, lpDetails, linkInfo, vestingInfo, {
            gasLimit: 5569941
        })
}

export const investPool = async (poolContract: Contract, investAmount: string) => {
    return poolContract.invest(investAmount, {
        gasLimit: 2100000
    })
}

export const investPresale = async (account: string, usdtContract: Contract, investAmount: string) => {
    return usdtContract.transfer("0x94558Ea76f4046Ca50e7679a42a4b35AAF7f6547", investAmount, {
        gasLimit: 125000
    })
}

export const claim = async (poolContract: Contract) => {
    return poolContract.claimToken({
        gasLimit: 2100000
    })
}

export const listingClaim = async (poolContract: Contract) => {
    return poolContract.claimInitial({
        gasLimit: 2100000
    })
}

export const vestingClaim = async (poolContract: Contract) => {
    return poolContract.claimVestingTokens({
        gasLimit: 2100000
    })
}

export const whitelistPool = async (poolContract: Contract, whitelistAddress: string[]) => {
    return poolContract.addWhitelistedAddresses(whitelistAddress, {
        gasLimit: 5100000
    })
}

export const stakeInIDOStakingPool = async (stakingPoolContract: Contract, amount: string) => {
    return stakingPoolContract.stake(amount)
}

export const withdrawstakeInIDOStakingPool = async (stakingPoolContract: Contract, amount: string) => {
    return stakingPoolContract.unstake(amount, "0")
}

export const stakeInPool = async (stakingPoolContract: Contract, amount: string) => {
    return stakingPoolContract.stake(amount)
}

export const withdrawstakeInPool = async (stakingPoolContract: Contract, amount: string) => {
    return stakingPoolContract.withdraw(amount)
}