import { Contract } from '@ethersproject/contracts'
import ERC20_ABI from '../constants/abis/erc20.json'
import POOL_ABI from '../constants/abis/pool.json'
import STAKING_POOL_ABI from '../constants/abis/stakingPool.json'
import FACTORY_ABI from '../constants/abis/factory.json'
import STAKING_ABI from '../constants/abis/staking.json'
import INFO_ABI from '../constants/abis/info.json'
import { getAddress } from './addressHelpers'
// import VAULT_ABI from '../constants/abis/vault.json'
import { getContract } from './index'

export function getTokenContract(tokenAddress?: string, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    // const { library, account } = useActiveWeb3React()
    if (!tokenAddress || !library || !account) return null
    return getContract(tokenAddress, ERC20_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getPoolContract(poolAddress: string, chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    if (!chainId || !library || !account) return null
    return getContract(poolAddress, POOL_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getStakingPoolContract(stakingPoolAddress: string, chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    if (!chainId || !library || !account) return null
    return getContract(stakingPoolAddress, STAKING_POOL_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getFactoryContract(chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    if (!chainId || !library || !account) return null
    return getContract(getAddress(chainId, 'factory'), FACTORY_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getInfoContract(chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    if (!chainId || !library || !account) return null
    return getContract(getAddress(chainId, 'info'), INFO_ABI, library ,withSignerIfPossible && account ? account : undefined)
}

export function getIDOStakingContract(chainId?: number, library?: any, account?: string | null, withSignerIfPossible = true): Contract | null {
    if (!chainId || !library || !account) return null
    return getContract(getAddress(chainId, 'staking'), STAKING_ABI, library ,withSignerIfPossible && account ? account : undefined)
}