import React from 'react';
import IDOPoolDetailTankWarsSection from '../components/sections/IDOPoolDetailsTankWars';

class IDOPoolDetailsTankWars extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.title = "TankWars IDO | MaticLaunch"
  }
  render() {
    return (
      <IDOPoolDetailTankWarsSection id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default IDOPoolDetailsTankWars;