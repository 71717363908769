import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState, useContext } from 'react'
import { stakeInIDOStakingPool, withdrawstakeInIDOStakingPool } from '../utils/callHelpers'
import { getIDOStakingContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify'
import { getBalanceNumber } from '../utils/formatBalance'

export const useStake = () => {
    const { account, library, chainId } = useWeb3React()

    const handleStake = useCallback(async (stakeAmount) => {
        try {
            if (!library || !account || !chainId) return false
            let stakePoolContract = getIDOStakingContract(chainId, library, account)
            if (!stakePoolContract) return false
            const tx = await stakeInIDOStakingPool(stakePoolContract, stakeAmount)
            return tx
        } catch (e: any) {
            toast.error((e.data && e.data.message) || e.message)
            return false
        }
    }, [account, chainId])

    return { onStake: handleStake }
}

export const useWithdrawStake = () => {
    const { account, library, chainId } = useWeb3React()

    const handleWithdrawStake = useCallback(async (unstakeAmount) => {
        try {
            if (!library || !account || !chainId) return false
            let stakePoolContract = getIDOStakingContract(chainId, library, account)
            if (!stakePoolContract) return false
            console.log(stakePoolContract, unstakeAmount)
            const tx = await withdrawstakeInIDOStakingPool(stakePoolContract, unstakeAmount)
            return tx
        } catch (e: any) {
            toast.error((e.data && e.data.message) || e.message)
            return false
        }
    }, [account, chainId])

    return { onWithdrawStake: handleWithdrawStake }
}

export const useStakingPoolFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleFetchStakingPoolDetails = useCallback(async () => {
        try {
            if (!account || !library || !chainId) return false
            const stakingPoolContract = getIDOStakingContract(chainId, library, account)
            if (!stakingPoolContract) return false

            const totalStaked = await stakingPoolContract.totalStaked()
            const stakerInfos = await stakingPoolContract.stakerInfos(account)

            return {
                totalStaked: getBalanceNumber(totalStaked),
                stake: getBalanceNumber(stakerInfos.balance),
                lastStakedTimestamp: (stakerInfos.lastStakedTimestamp).toString(),
                lastUnstakedTimestamp: (stakerInfos.lastUnstakedTimestamp).toString()
            }
        } catch (e: any) {
            toast.error(e.message)
        }
    }, [account, chainId])

    return { onFetchStakingPoolDetails: handleFetchStakingPoolDetails }
}