import React from 'react';
import IDOPoolDetailMollectorSection from '../components/sections/IDOPoolDetailsMollector';

class IDOPoolDetailsMollector extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.title = "Mollector IDO | MaticLaunch"
  }
  render() {
    return (
      <IDOPoolDetailMollectorSection id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default IDOPoolDetailsMollector;