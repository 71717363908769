import React, { useCallback, useContext, useEffect } from 'react'
import SectionHeader from './partials/SectionHeader';
import PoolCard from "../elements/PoolCard";
import { Context } from '../../store/store';
import DotLoader from "react-spinners/DotLoader";
import { useHistory } from "react-router-dom";
import { useIntervalProgressFetch } from '../../hooks/IDOPools';
import { MTCLFundPools } from '../../constants/MTCLFundPools';
import { getPools } from '../../services/pool';
import { toast } from 'react-toastify';
import { getBalanceNumber } from '../../utils/formatBalance';

function PoolsSection(props: any) {
    let history = useHistory();
    const upcomingPoolsHeader = {
        title: 'Upcoming Pools',
        paragraph: ''
    };
    const pastPoolsHeader = {
        title: 'Closed Pools',
        paragraph: ''
    };
    const override = `
        display: block;
        margin: auto;
        border-color: red;
    `;

    const [state, dispatch] = useContext(Context);
    const { onFetchRaised } = useIntervalProgressFetch()

    const fetchRaisedAmount = useCallback(async (activePool, pools) => {
        try {
            console.log("calleds")
            dispatch({ type: 'SET_LOADING', payload: true })
            let poolDetails = await onFetchRaised(activePool, pools)
            console.log(poolDetails)
            dispatch({ type: 'SET_LOADING', payload: false })
        } catch (e) {
            dispatch({ type: 'SET_LOADING', payload: false })
        }
    }, [onFetchRaised])

    useEffect(() => {
        async function fetchDetails() {
            let res = await getPools()
            if (!res.status) toast.error("Oopps!, something went wrong")
            const pools: any = res.data.pools;
            console.log(pools)
            let activePool = pools.filter((item: any) => {
                // console.log(Number(item.saleOpenTime) * 1000 < Date.now(), Number(item.saleCloseTime) * 1000 > Date.now(), (item.totalCollected ?? 0) < item.hardCap, item.hardcap)
                // if (Number(item.saleOpenTime) * 1000 < Date.now() && Number(item.saleCloseTime) * 1000 > Date.now() && (item.totalCollected ?? 0) < getBalanceNumber(item.hardCap)) return item
                return item
            })
            activePool = activePool.map((item: any) => {
                if (Number(item.saleCloseTime) === 0 || Number(item.saleCloseTime) * 1000 > Date.now()) {
                    item.isUpcoming = true
                    return item
                } else {
                    item.isUpcoming = false
                    return item
                }
            })
            console.log(activePool)
            fetchRaisedAmount(activePool, pools)
        }
        fetchDetails()
    }, [])

    const onPoolClick = function (poolId: string, poolAddress: string) {
        // if(poolId === "1") {
        //     history.push('/pool/ido')
        //     return
        // }
        if (poolId === "1") {
            history.push(`/pool/solchiks`)
        } else if (poolId === "2") {
            history.push(`/pool/vaulthill`)
        } else if (poolId === "3") {
            history.push(`/pool/tankwars`)
        } else if (poolId === "5") {
            history.push(`/pool/gameup`)
        } else if (poolId === "6") {
            history.push(`/pool/vulturepeak`)
        }else if (poolId === "7") {
            history.push(`/pool/assetfi`)
        }else if (poolId === "8") {
            history.push(`/pool/gameyoo`)
        }else if (poolId === "9") {
            history.push(`/pool/mollector`)
        } else {
            history.push(`/pool/${poolId}/${poolAddress}`)
        }
    }


    return (
        <section className="section illustration-section-01">
            <div className="container-md pool-list-page">

                {state.loading && <div className="loading-container">
                    <DotLoader color={"#C2EAFF"} loading={state.loading} css={override} />
                </div>}


                {!state.loading && <div className="row justify-content-center">
                    <SectionHeader tag="h3" data={upcomingPoolsHeader} className="center-content" />
                    {state.pools.map((pool: any, index: number) => (
                        <>
                            {pool.isUpcoming && (
                                <>
                                    {/* <div className="col-lg-3"></div> */}
                                    < div key={`tr-${index}`} className="col-lg-6 col-sm-12"
                                        onClick={() => { if (pool.isPoolDetailsOpenable) onPoolClick(pool.poolId, pool.poolAddress) }}
                                    >

                                        <PoolCard
                                            openTime={pool.saleOpenTime * 1000}
                                            closeTime={pool.saleCloseTime * 1000}
                                            title={pool.saleTitle}
                                            description={pool.description.slice(0, 70) + "..."}
                                            price={getBalanceNumber(pool.tokenPrice, 6)}
                                            symbol={pool.saleTokenSymbol}
                                            totalRaise={getBalanceNumber(pool.hardCap, 6)}
                                            tokenForSale={getBalanceNumber(pool.totalTokens, pool.saleTokenDecimals)}
                                            access={pool.access}
                                            saleFilled="75"
                                            totalCollected={pool.totalCollected ?? 0}
                                            twitter={pool.linkTwitter}
                                            telegram={pool.linkTelegram}
                                            website={pool.linkWebsite}
                                            poolAddress={pool.poolAddress}
                                            logo={pool.linkLogo}
                                            whitelistingCloseTime={pool.whitelistingCloseTime * 1000}
                                            whitelistingOpenTime={pool.whitelistingOpenTime * 1000}
                                            guaranteedDuration={pool.guaranteedDuration}
                                            whitelistingLink={pool.whitelistingLink}>
                                        </PoolCard>


                                    </div>
                                </>
                            )}
                        </>
                    ))}
                    <SectionHeader tag="h3" data={pastPoolsHeader} className="center-content mt-5" />
                    {state.pools.map((pool: any, index: number) => (
                        <>
                            {!pool.isUpcoming && (
                                <>
                                    {/* <div className="col-lg-3"></div> */}
                                    < div key={`tr-${index}`} className="col-lg-6 col-sm-12"
                                        onClick={() => { if (pool.isPoolDetailsOpenable) onPoolClick(pool.poolId, pool.poolAddress) }}
                                    >

                                        <PoolCard
                                            openTime={pool.saleOpenTime * 1000}
                                            closeTime={pool.saleCloseTime * 1000}
                                            title={pool.saleTitle}
                                            description={pool.description.slice(0, 70) + "..."}
                                            price={getBalanceNumber(pool.tokenPrice, 6)}
                                            symbol={pool.saleTokenSymbol}
                                            totalRaise={getBalanceNumber(pool.hardCap, 6)}
                                            tokenForSale={getBalanceNumber(pool.totalTokens, pool.saleTokenDecimals)}
                                            access={pool.access}
                                            saleFilled="75"
                                            totalCollected={pool.totalCollected ?? 0}
                                            twitter={pool.linkTwitter}
                                            telegram={pool.linkTelegram}
                                            website={pool.linkWebsite}
                                            poolAddress={pool.poolAddress}
                                            logo={pool.linkLogo}>
                                        </PoolCard>


                                    </div>
                                </>
                            )}
                        </>
                    ))}
                    {MTCLFundPools.map((pool: any, index: number) => (
                        <>
                            {/* <div className="col-lg-3"></div> */}
                            <div key={`tr-${index}`} className="col-lg-6 col-sm-12"
                            // onClick={() => { if (pool.poolId !== '0' ) onPoolClick(pool.poolId) }}
                            >
                                <PoolCard
                                    openIn="Opens In July 2021 (TBA)"
                                    openTime={pool.openTime}
                                    closeTime={pool.closeTime}
                                    title={pool.title}
                                    description={pool.description}
                                    price={pool.price}
                                    symbol={pool.symbol}
                                    totalRaise={pool.totalRaise}
                                    tokenForSale={pool.tokenForSale}
                                    access={pool.access}
                                    saleFilled="75"
                                    totalCollected={pool.totalCollected}
                                    liquidity={pool.liquidity}
                                    twitter="maticlaunch"
                                    telegram="maticlaunch"
                                    website="https://maticlaunch.org/"
                                    poolAddress=""
                                    logo="">
                                </PoolCard>
                            </div>
                        </>
                    ))}
                </div>
                }
            </div>
        </section >
    )
}

export default PoolsSection