import { useCallback, useEffect, useState, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import { approve, claim, investPool, investPresale } from '../utils/callHelpers'
import { getAddress } from '../utils/addressHelpers'
import { getInfoContract, getPoolContract, getTokenContract } from '../utils/contractHelpers'
import { toast } from 'react-toastify'
import BigNumber from 'bignumber.js';
import { parseBytes32String } from "@ethersproject/strings";
import { getBalanceNumber } from "../utils/formatBalance"
import useRefresh from './useRefresh';
import { Context } from '../store/store';
import { isMobile } from 'react-device-detect';
import { getAddressDetails, getPoolProgress, getPublicAddressDetails, getPublicPoolProgress } from '../services/presale'

export const useFactoryApprove = () => {
    const { account, library, chainId } = useWeb3React()

    const handleApprove = useCallback(async (tokenAddress) => {
        try {
            if (!library || !account || !chainId) return false
            let tokenContract = getTokenContract(tokenAddress, library, account)
            if (!tokenContract) return false
            const tx = await approve(tokenContract, getAddress(chainId, 'factory'), account)
            return tx
        } catch (e) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onApprove: handleApprove }
}

export const useInvest = () => {
    const { account, library, chainId } = useWeb3React()

    const handleInvest = useCallback(async (poolAddress, investAmount) => {
        try {
            if (!library || !account || !chainId) return false
            // let tokenContract = getTokenContract(tokenAddress, library, account)
            const tokenContract = getTokenContract(getAddress(chainId, 'usdt'), library, account)
            // let poolContract = getPoolContract(poolAddress, chainId, library, account)
            if (!tokenContract) return false
            const tx = await investPresale(account, tokenContract, investAmount)
            return tx
        } catch (e) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onInvest: handleInvest }
}

export const useClaim = () => {
    const { account, library, chainId } = useWeb3React()

    const handleClaim = useCallback(async (poolAddress) => {
        try {
            if (!library || !account || !chainId) return false
            // let tokenContract = getTokenContract(tokenAddress, library, account)
            let poolContract = getPoolContract(poolAddress, chainId, library, account)
            if (!poolContract) return false
            const tx = await claim(poolContract)
            return tx
        } catch (e) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onClaim: handleClaim }
}

export const useIntervalProgressFetch = () => {
    const { account, library, chainId } = useWeb3React()
    const [state, dispatch] = useContext(Context);

    const fetchRaised = useCallback(async (activePool, pools) => {
        console.log(activePool)
        if (!account || !chainId || activePool.length <= 0) {
            dispatch({ type: 'SET_POOLS', payload: pools })
            return null
        }
        try {
            let updates: any[] = []
            for (let i = 0; i < activePool.length; i++) {
                if(activePool[i].poolAddress === "0x0000000000000000000000000000000000000000") {
                    updates.push({
                        ...activePool[i],
                        totalCollected: 0
                    })
                } else {
                    const poolContract = getPoolContract(activePool[i].poolAddress, chainId, library, account)
                    if (!poolContract) return false
                    const totalCollected = await poolContract.totalCollectedWei()
                    updates.push({
                        ...activePool[i],
                        totalCollected: getBalanceNumber(totalCollected, 6)
                    })
                }
            }
            let updatedPools = pools
            updates.forEach((updatesPool) => {
                let index = updatedPools.findIndex((pool: any) => pool.poolId === updatesPool.poolId)
                updatedPools[index] = { ...updatesPool }
            })
            dispatch({ type: 'SET_POOLS', payload: pools })
        } catch (e) {
            console.log(e)
        }
    }, [account, chainId])

    return { onFetchRaised: fetchRaised }

    // useEffect(() => {
    //     const fetchRaised = async () => {
    // if (!account || !chainId || state.activePools.length <= 0) {
    //     return null
    // }
    // try {
    //     let updates: any[] = []
    //     state.activePools.forEach(async (pool: any) => {
    //         const poolContract = getPoolContract(pool.poolAddress, chainId, library, account)
    //         if (!poolContract) return false
    //         const totalCollected = await poolContract.totalCollectedWei()
    //         updates.push({
    //             ...pool,
    //             totalCollected: getBalanceNumber(totalCollected)
    //         })
    //         let pools = state.pools
    //         // var foundIndex = items.findIndex(x => x.id == item.id);
    //         // items[foundIndex] = item;
    //         updates.forEach((updatesPool) => {
    //             let index = pools.findIndex((pool: any) => pool.poolId === updatesPool.poolId)
    //             pools[index] = updatesPool
    //         })
    //         dispatch({type: 'SET_POOLS', payload: pools})
    //     });
    // } catch (e) {
    //     console.log(e)
    // }
    // }

    //     if (account && !isMobile) {
    //         fetchRaised()
    //     }
    // }, [account, slowRefresh])

}

export const useIntervalPoolFetch = (poolDetails: any) => {
    const [tokensLeft, setTokenLeft] = useState(poolDetails?.tokensLeft ?? "0")
    const [totalCollected, setTotalCollected] = useState(poolDetails?.totalCollected ?? "0")
    const [isWhitlisted, setIsWhitlisted] = useState(poolDetails?.isWhitlisted ?? false)
    const [invetment, setInvetment] = useState(poolDetails?.invetment ?? "0")
    const [claimed, setClaimed] = useState<any>();
    const { account, library, chainId } = useWeb3React()

    const { slowRefresh } = useRefresh()

    useEffect(() => {
        const fetchAllowance = async () => {
            // const address = isAddress(tokenAddress)
            if (!account || !chainId || !(poolDetails)) {
                return null
            }
            try {
                // const poolContract = getPoolContract(poolDetails.poolAddress, chainId, library, account)
                // if (!poolContract) return false
                // const tokensLeft = await poolContract.tokensLeft()
                // setTokenLeft(tokensLeft.toString())
                const totalCollectedWei = await getPoolProgress()
                setTotalCollected(totalCollectedWei.data.invetment)
                // const minInvestInWei = await poolContract.minInvestInWei()
                // const maxInvestInWei = await poolContract.maxInvestInWei()
                const addressDetails = await getAddressDetails(account)
                console.log(addressDetails)
                const isWhitlisted = addressDetails.data.isWhitelisted
                setIsWhitlisted(isWhitlisted)
                const invetment = addressDetails.data.invetment
                setInvetment(invetment)
                setClaimed(true)
                console.log(totalCollected,
                    isWhitlisted,
                    invetment,
                    claimed)
                // const totalCollected = await poolContract.totalCollectedWei()
                // setTotalCollected(getBalanceNumber(totalCollected))
                // const isWhitlisted = await poolContract.whitelistedAddresses(account)
                // setIsWhitlisted(isWhitlisted)
                // const invetment = await poolContract.investments(account)
                // setInvetment(getBalanceNumber(invetment))
                // const claimed = await poolContract.claimed(account)
                // setClaimed(claimed)
                return {
                    ...poolDetails,
                    totalCollected,
                    isWhitlisted,
                    invetment,
                    claimed
                }
                // const tokenContract = getTokenContract(getAddress(chainId, 'usdt'), library, account)
                // const res = await tokenContract?.allowance(account, poolDetails.poolAddress)
                // setAllowance(new BigNumber(res.toString()))
            }
            catch (error) {
                console.log(error)
                return null
            }
        }

        if (account) {
            fetchAllowance()
        }
    }, [account, slowRefresh])

    if (claimed === undefined) {
        console.log("asdfs")
        return poolDetails
    } else {
        return {
            ...poolDetails,
            totalCollected,
            isWhitlisted,
            invetment,
            claimed
        }
    }

}

export const usePublicIntervalPoolFetch = (poolDetails: any) => {
    const [tokensLeft, setTokenLeft] = useState(poolDetails?.tokensLeft ?? "0")
    const [totalCollected, setTotalCollected] = useState(poolDetails?.totalCollected ?? "0")
    const [isWhitlisted, setIsWhitlisted] = useState(poolDetails?.isWhitlisted ?? false)
    const [invetment, setInvetment] = useState(poolDetails?.invetment ?? "0")
    const [claimed, setClaimed] = useState<any>();
    const [serverTime, setServerTime] = useState(Date.now());
    const { account, library, chainId } = useWeb3React()

    const { slowRefresh } = useRefresh()

    useEffect(() => {
        const fetchAllowance = async () => {
            if (!account || !chainId || !(poolDetails)) {
                return null
            }
            try {
                const totalCollectedWei = await getPublicPoolProgress()
                setTotalCollected(totalCollectedWei.data.invetment)
                const addressDetails = await getPublicAddressDetails(account)
                console.log(addressDetails)
                const isWhitlisted = addressDetails.data.isWhitelisted
                setIsWhitlisted(isWhitlisted)
                const invetment = addressDetails.data.invetment
                setInvetment(invetment)
                const serverTime = addressDetails.data.serverTime
                setServerTime(serverTime)
                setClaimed(true)
                return {
                    ...poolDetails,
                    totalCollected,
                    isWhitlisted,
                    invetment,
                    claimed,
                    serverTime
                }
            }
            catch (error) {
                console.log(error)
                return null
            }
        }

        if (account) {
            fetchAllowance()
        }
    }, [account, slowRefresh])

    if (claimed === undefined) {
        return poolDetails
    } else {
        return {
            ...poolDetails,
            totalCollected,
            isWhitlisted,
            invetment,
            claimed,
            serverTime
        }
    }

}

export const usePoolFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleFetchPoolDetails = useCallback(async (poolId: string) => {
        try {
            if (!account || !library || !chainId) return false
            // let infoContract = getInfoContract(chainId, library, account)
            // if (!infoContract) return false
            // const poolAddress = await infoContract.getPoolAddress(poolId)
            // if (!poolAddress) return false
            // const poolContract = getPoolContract(poolAddress, chainId, library, account)
            // if (!poolContract) return false
            // const tokenAddress = await poolContract.token()
            // if (!tokenAddress) return false
            // const tokenContract = getTokenContract(tokenAddress, library, account)
            // if (!tokenContract) return false
            // const tokenSymbol = await tokenContract.symbol()
            // const totalTokens = await poolContract.totalTokens()
            // const tokensLeft = await poolContract.tokensLeft()
            // const tokenPriceInWei = await poolContract.tokenPriceInWei()
            const totalCollectedWei = await getPoolProgress()
            // const minInvestInWei = await poolContract.minInvestInWei()
            // const maxInvestInWei = await poolContract.maxInvestInWei()
            const addressDetails = await getAddressDetails(account)
            const isWhitlisted = addressDetails.data.isWhitelisted
            const invetment = addressDetails.data.invetment
            // const claimed = await poolContract.claimed(account)

            // const openTime = await poolContract.openTime()
            // const closeTime = await poolContract.closeTime()
            // const softCapInWei = await poolContract.softCapInWei()
            // const hardCapInWei = await poolContract.hardCapInWei()
            // const onlyWhitelistedAddressesAllowed = await poolContract.onlyWhitelistedAddressesAllowed()

            // const lpListingPriceInWei = await poolContract.lpListingPriceInWei()
            // const lpLiquidityAddingTime = await poolContract.lpLiquidityAddingTime()
            // const lpLPTokensLockDurationInDays = await poolContract.lpLPTokensLockDurationInDays()
            // const lpLiquidityPercentageAllocation = await poolContract.lpLiquidityPercentageAllocation()

            // const saleTitle = await poolContract.saleTitle()
            // const linkTelegram = await poolContract.linkTelegram()
            // const linkGithub = await poolContract.linkGithub()
            // const linkTwitter = await poolContract.linkTwitter()
            // const linkWebsite = await poolContract.linkWebsite()
            // const linkLogo = await poolContract.linkLogo()
            return {
                // poolAddress,
                // tokenAddress,
                tokenSymbol: 'MTCL',
                // totalTokens: totalTokens.toString(),
                // tokensLeft: tokensLeft.toString(),
                tokenPrice: 0.065,
                totalCollected: totalCollectedWei.data.invetment,
                minInvest: 200,
                maxInvest: 3000,
                isWhitlisted: isWhitlisted,
                invetment: invetment,
                claimed: false,

                openTime: "1627979400",
                closeTime: "1628238600",
                softCap: 100000,
                hardCap: 200000,
                onlyWhitelistedAddressesAllowed: false,

                lpListingPrice: "0.1",
                lpLiquidityAddingTime: "TBA",
                lpLPTokensLockDurationInDays: "365",
                lpLiquidityPercentageAllocation: "25",

                saleTitle: "MaticLaunch | Presale",
                // linkTelegram: parseBytes32String(linkTelegram),
                // linkGithub: parseBytes32String(linkGithub),
                // linkTwitter: parseBytes32String(linkTwitter),
                // linkWebsite: parseBytes32String(linkWebsite),
                // linkLogo: parseBytes32String(linkLogo)
            }
        } catch (e) {
            toast.error(e.message)
        }
    }, [account, chainId])

    return { onFetchPoolDetails: handleFetchPoolDetails }
}


export const usePublicPoolFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleFetchPoolDetails = useCallback(async (poolId: string) => {
        try {
            if (!account || !library || !chainId) return false
            const totalCollectedWei = await getPublicPoolProgress()
            const addressDetails = await getPublicAddressDetails(account)
            const isWhitlisted = addressDetails.data.isWhitelisted
            const invetment = addressDetails.data.invetment
            const serverTime = addressDetails.data.serverTime
            return {
                // poolAddress,
                // tokenAddress,
                tokenSymbol: 'MTCL',
                // totalTokens: totalTokens.toString(),
                // tokensLeft: tokensLeft.toString(),
                tokenPrice: 0.095,
                totalCollected: totalCollectedWei.data.invetment,
                minInvest: 200,
                maxInvest: 700,
                isWhitlisted: isWhitlisted,
                invetment: invetment,
                claimed: false,
                serverTime: serverTime,

                openTime: "1630150200",
                closeTime: "1630236600",
                softCap: 20000,
                hardCap: 50000,
                onlyWhitelistedAddressesAllowed: true,

                lpListingPrice: "0.1",
                lpLiquidityAddingTime: "Sun, 29 Aug 2021 15:30:00 GMT",
                lpLPTokensLockDurationInDays: "365",
                lpLiquidityPercentageAllocation: "25",

                saleTitle: "MaticLaunch | IDO",
            }
        } catch (e) {
            toast.error(e.message)
        }
    }, [account, chainId])

    return { onFetchPoolDetails: handleFetchPoolDetails }
}

function poolContract(poolContract: any, investAmount: any) {
    throw new Error('Function not implemented.')
}
