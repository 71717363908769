import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Built from '../components/sections/Built';
// import Testimonial from '../components/sections/TestimonialCarousel';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
import Image from '../components/elements/Image';

class Home extends React.Component {
  render() {

    const genericSectionTokonomics = {
      title: 'Tokenomics',
      paragraph: 'A quick overview of our native token MTCL on Polygon, fulfilling the purpose of decentralization in disruptive fundraising platform'
    }

    const genericSectionPartner = {
      title: 'Backers & Partners'
    }

    const genericSectionMedia = {
      title: 'Media Coverage'
    }

    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" />
        <GenericSection className="illustration-section-07">
          <div className="container">
            <SectionHeader data={genericSectionPartner} className="center-content" />
            <div className="partner-row">
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_partner-polygon.svg')}
                  alt="Tokenomics chart"
                  className="partner-logo"
                  />
              </div>
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_partner-scorpio.png')}
                  alt="Tokenomics chart"
                  className="partner-logo"
                  />
              </div>
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_partner-async.png')}
                  alt="Tokenomics chart"
                  className="partner-logo"
                  />
              </div>
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_partner-ahc.svg')}
                  alt="Tokenomics chart"
                  className="partner-logo-ahc"
                  />
              </div>
            </div>
          </div>
        </GenericSection>
        <FeaturesTiles className="illustration-section-02" />
        <Built  />
        <FeaturesSplit invertMobile imageFill />
        {/* <GenericSection className="illustration-section-07">
          <div className="container">
            <SectionHeader data={genericSectionTokonomics} className="center-content" />
            <div className="d-flex-token">
              <Image
                src={require('./../assets/images/tokenomics_chart.png')}
                alt="Tokenomics chart"
                className="p-16"
                 />
              <Image
                src={require('./../assets/images/tokenomics_summary.png')}
                alt="Tokenomics summary"
                className="p-16"
                 />
            </div>
          </div>
        </GenericSection> */}
        <GenericSection className="illustration-section-07">
          <div className="container">
            <SectionHeader data={genericSectionMedia} className="center-content" />
            <div className="partner-row">
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_media-yahoo_finance.png')}
                  alt="Tokenomics chart"
                  className="media-logo-yahoo"
                  />
              </div>
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_media-market-watch.svg')}
                  alt="Tokenomics chart"
                  className="media-logo"
                  />
              </div>
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_media-digital-journal.png')}
                  alt="Tokenomics chart"
                  className="media-logo"
                  />
              </div>
              <div className="col-lg-3 col-md-6">
                <Image
                  src={require('./../assets/images/logo_media-benzinga.png')}
                  alt="Tokenomics chart"
                  className="media-logo-benzinga"
                  />
              </div>
            </div>
          </div>
        </GenericSection>
        <Roadmap />
        <Cta className="illustration-section-06 center-content-mobile" />
      </React.Fragment>
    );
  }
}

export default Home;