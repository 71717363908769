export function rewardCalc(
    amount: number,
    type: 'now' | 'maturity',
    earlyWithdrawReward: number,
    rewardBalance: number,
    withdrawEnd: number,
    stakingEnd: number,
    stakedTotal: number,
    stakedBalance: number) {
    if (amount === 0 || stakedTotal === 0) {
        return 0;
    }
    if (type === 'maturity' || Date.now() > withdrawEnd) {
        return (rewardBalance * (amount) / (stakedBalance)).toFixed(4);
    } else {
        if (earlyWithdrawReward > 0) {
            const yearMili = 365 * 24 * 60 * 60 * 1000;
            const rate = (earlyWithdrawReward / stakedTotal) * yearMili / (withdrawEnd - stakingEnd);
            return (rate * ((Date.now() - (stakingEnd)) / yearMili) * amount).toFixed(4)
        }
        return 0
    }
}
