import React from 'react';
import IDOPoolDetailVaultHillSection from '../components/sections/IDOPoolDetailsVaultHill';

class IDOPoolDetailsVaultHill extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.title = "VaultHill IDO | MaticLaunch"
  }
  render() {
    return (
      <IDOPoolDetailVaultHillSection id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default IDOPoolDetailsVaultHill;