import { isAddress } from "@ethersproject/address"
import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getAddress } from "../utils/addressHelpers"
import { approve } from "../utils/callHelpers"
import { getTokenContract } from "../utils/contractHelpers"
import { getBalanceNumber } from "../utils/formatBalance"
import useRefresh from "./useRefresh"


export const useMTCLApprove = () => {
    const { account, library, chainId } = useWeb3React()

    const handleMTCLApprove = useCallback(async (spenderAddress) => {
        try {
            if (!library || !account || !chainId) return false
            let mtclTokenContract = getTokenContract(getAddress(chainId, 'mtcl'), library, account)
            if (!mtclTokenContract) return false
            const tx = await approve(mtclTokenContract, spenderAddress, account)
            return tx
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onMTCLApprove: handleMTCLApprove }
}

export const useMTCLBalanceFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleMTCLBalanceFetch = useCallback(async () => {
        try {
            if (!library || !account || !chainId) return false
            let mtclTokenContract = getTokenContract(getAddress(chainId, 'mtcl'), library, account)
            if (!mtclTokenContract) return false
            const balance = await mtclTokenContract.balanceOf(account)
            return {
                balance: getBalanceNumber(balance)
            }
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onMTCLBalanceFetch: handleMTCLBalanceFetch }
}

export const useUSDTBalanceFetch = () => {
    const { account, library, chainId } = useWeb3React()

    const handleUSDTBalanceFetch = useCallback(async () => {
        try {
            if (!library || !account || !chainId) return false
            let usdtTokenContract = getTokenContract(getAddress(chainId, 'usdt'), library, account)
            if (!usdtTokenContract) return false
            const balance = await usdtTokenContract.balanceOf(account)
            console.log(balance.toString())
            return {
                balance: getBalanceNumber(balance, 6)
            }
        } catch (e: any) {
            toast.error(e.message)
            return false
        }
    }, [account, chainId])

    return { onUSDTBalanceFetch: handleUSDTBalanceFetch }
}

export const useMTCLAllowance = (spender: any): string | null | undefined => {
    const [allowance, setAllowance] = useState<string>()
    const { account, library, chainId } = useWeb3React()
    
    const { fastRefresh } = useRefresh()

    useEffect(() => {
        const fetchAllowance = async () => {
            const spenderAddress = isAddress(spender)
            if (!account || !chainId || !spenderAddress || !library) return null
            try {
                const tokenContract = getTokenContract(getAddress(chainId, 'mtcl'), library, account)
                console.log(tokenContract)
                const res = await tokenContract?.allowance(account, spender)
                setAllowance(res.toString())
            }
            catch(error) {
                console.log(error)
                return null
            }
        }

        if (spender && account) {
            console.log(spender, account)
            fetchAllowance()
        }
    }, [spender, account, fastRefresh])

    return allowance
}