export const MTCLFundPools = [{
    poolId: "1",
    openTime: "1630150200000",
    closeTime: "1630236600000",
    title: "MaticLaunch | IDO",
    description: "MaticLaunch is a decentralized crowdfunding platform for the next ....",
    price: "0.095",
    symbol: "MTCL",
    totalRaise: "50000",
    totalCollected: "50000",
    tokenForSale: "526315.79",
    access: "Public",
    liquidity: "25",
    website: "",
    telegram: "",
    twitter: "",
    logoLink: "",
    poolAddress: "0xCDEA0F0C5011dD13EBD2faBdEE474D0269E99adE"
}, {
    poolId: "0",
    openTime: "1627979400000",
    closeTime: "1628238600000",
    title: "MaticLaunch | Presale",
    description: "MaticLaunch is a decentralized crowdfunding platform for the next ....",
    price: "0.065",
    symbol: "MTCL",
    totalRaise: "130229",
    totalCollected: "130229",
    tokenForSale: "2003523",
    access: "Private",
    liquidity: "25",
    website: "",
    telegram: "",
    twitter: "",
    logoLink: "",
    poolAddress: "0xCDEA0F0C5011dD13EBD2faBdEE474D0269E99adE"
}]