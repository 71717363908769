export const stakingPools = [{
    poolId: "1",
    poolAddress: "0x9282a48d9a06e5261039384c90227f4806f8467a",
    name: "Gold - I",
    size: 300000,
    apy: 175,
    earlyApy: 75,
    stakingStarts: "1631475000000",
    stakingEnds: "1632079800000",
    withdrawStarts: "1634671800000",
    withdrawEnds: "1637263800000",
    localEarlyWithdrawReward: 50000,
    localRewardBalance: 150000,
    maturity: 60,
    earlyOpen: 30,
    min: 3000
}, {
    poolId: "2",
    poolAddress: "0x03492e763b4576c85d529462478887060d1a3f05",
    name: "Silver",
    size: 150000,
    apy: 80,
    earlyApy: 45,
    stakingStarts: "1631475000000",
    stakingEnds: "1632079800000",
    withdrawStarts: "1633375800000",
    withdrawEnds: "1634671800000",
    localEarlyWithdrawReward: 50000,
    localRewardBalance: 150000,
    maturity: 30,
    earlyOpen: 15,
    min: 500
}, {
    poolId: "3",
    poolAddress: "0xc31583edcfa6a33c5b62e0896705e219b13232ec",
    name: "Gold - II",
    size: 600000,
    apy: 175,
    earlyApy: 75,
    stakingStarts: "1631647800000",
    stakingEnds: "1632079800000",
    withdrawStarts: "1634671800000",
    withdrawEnds: "1637263800000",
    localEarlyWithdrawReward: 50000,
    localRewardBalance: 150000,
    maturity: 60,
    earlyOpen: 30,
    min: 3000
}, {
    poolId: "4",
    poolAddress: "0x221Bbd305953ABA4a520699D4c3226eE68B1645B",
    name: "Platinum",
    size: 1000000,
    apy: 100,
    earlyApy: 45,
    stakingStarts: "1643385600000",
    stakingEnds: "1643990400000",
    withdrawStarts: "1648224000000",
    withdrawEnds: "1652457600000",
    localEarlyWithdrawReward: 50000,
    localRewardBalance: 150000,
    maturity: 98,
    earlyOpen: 49,
    min: 5000
}, {
    poolId: "5",
    poolAddress: "0x0e0F1E52C9633A2E7cBb848A99a780f5b25648E4",
    name: "Gold",
    size: 850000,
    apy: 65,
    earlyApy: 30,
    stakingStarts: "1643385600000",
    stakingEnds: "1643990400000",
    withdrawStarts: "1647014400000",
    withdrawEnds: "1650038400000",
    localEarlyWithdrawReward: 50000,
    localRewardBalance: 150000,
    maturity: 70,
    earlyOpen: 35,
    min: 1000
}, {
    poolId: "6",
    poolAddress: "0x2896c4fb0732B3D1664F9DC0Ae15cDb9A9A14e6C",
    name: "Silver",
    size: 650000,
    apy: 30,
    earlyApy: 13,
    stakingStarts: "1643385600000",
    stakingEnds: "1643990400000",
    withdrawStarts: "1645804800000",
    withdrawEnds: "1647619200000",
    localEarlyWithdrawReward: 50000,
    localRewardBalance: 150000,
    maturity: 42,
    earlyOpen: 21,
    min: 500,
    max: 5000
}]

export function getStakingPoolDetailsbyAddress(stakingPoolAddress) {
    let pool = stakingPools.filter((item) => item.poolAddress.toLowerCase() === stakingPoolAddress.toLowerCase())
    if (pool.length === 0) return undefined
    return pool[0]
}