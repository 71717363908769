import React, { useCallback, useContext, useEffect, useState } from 'react'
import SectionHeader from './partials/SectionHeader';
import PoolCard from "../elements/PoolCard";
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { Context } from '../../store/store';
import Input from '../elements/Input';
import { useFactoryApprove, usePoolFetch, useInvest, useIntervalPoolFetch, useWhitlist, useListingClaim, useVestingClaim } from '../../hooks/IDOPools';
import { useUSDTBalanceFetch } from '../../hooks/mtcl'
import DotLoader from "react-spinners/DotLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { nFormatter } from "../../utils/formatBalance";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { useAllowance } from '../../hooks/useAllowance';
import { toast } from 'react-toastify';
import { getBalanceNumber } from "../../utils/formatBalance";
import { usePoolUsdtApprove } from '../../hooks/useApprove';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { getChainDetails } from '../../utils';
import { isAddress } from '@ethersproject/address';

function IDOPoolDetailVaultHillSection(props: any) {
    const sectionHeader = {
        title: 'Pool details',
        paragraph: ''
    };
    let [poolDetails, setPoolDetails] = useState<any>()
    const [state, dispatch] = useContext(Context);
    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    const { account, chainId } = useWeb3React()
    const { onFetchPoolDetails } = usePoolFetch()
    const { onUSDTBalanceFetch } = useUSDTBalanceFetch()
    const intervalPoolDetails = useIntervalPoolFetch(poolDetails)
    const [investAmount, setInvestAmount] = useState(0)
    const [reciveAmount, setReciveAmount] = useState(0)
    const [requestedApproval, setRequestedApproval] = useState(false)
    const [requestedInvest, setRequestedInvest] = useState(false)
    const [requestedClaim, setRequestedClaim] = useState(false)
    const [requestedWhitelist, setRequestedWhitelist] = useState(false)
    const [approveTxhash, setApproveTxhash] = useState('')
    const [investTxhash, setInvestTxhash] = useState('')
    const [claimTxhash, setClaimTxhash] = useState('')
    const [addMoreModalShow, setAddMoreModalShow] = useState(false);
    const [whitelistTxhash, setWhitelistTxhash] = useState('')
    const [whitelistAddress, setWhitelistAddress] = useState('')
    const [formValidity, setFormValidity] = useState<'INVLAID_AMOUNT' | 'INSUFFICIENT_BALANCE' | 'VALID'>('INVLAID_AMOUNT')
    const [whitelistFormValidity, setWhitelistFormValidity] = useState<'INVLAID_ADDRESS' | 'VALID'>('INVLAID_ADDRESS')
    const [poolState, setPoolState] = useState<'TO_BE_OPEN' | 'OPEN_INVEST_STAKER' | 'OPEN_INVEST_PUBLIC' | 'OPEN_FILLED' | 'CLOSED' | 'CLAIM_TO_BE_OPEN' | 'CLAIM_OPEN' | 'CANCELLED'>('TO_BE_OPEN')
    const allowance = useAllowance(poolDetails)
    const { onApprove } = usePoolUsdtApprove()
    const { onInvest } = useInvest()
    const { onListingClaim } = useListingClaim()
    const { onVestingClaim } = useVestingClaim()
    const { onWhitelist } = useWhitlist()

    useEffect(() => {
        if (!poolDetails) {
            fetchDetails()
        }
    }, [account])

    useEffect(() => {
        if (requestedClaim && intervalPoolDetails.claimed) {
            setRequestedClaim(false)
        }
        if (requestedInvest && intervalPoolDetails.invetment > 0) {
            setRequestedInvest(false)
        }
        if (requestedInvest && addMoreModalShow) {
            setRequestedInvest(true)
        }
        if (poolDetails && JSON.stringify(poolDetails) != JSON.stringify(intervalPoolDetails)) {
            setPoolDetails(intervalPoolDetails)
        }
    }, [intervalPoolDetails])

    useEffect(() => {
        if (!allowance) return
        if (new BigNumber(allowance)?.comparedTo(investAmount) === 1) {
            setRequestedApproval(false)
        }
    }, [allowance])

    const isApproveDoneOrInProgress = function (investment: any, poolState: any) {
        if (poolState === 'CLAIM_OPEN' && investment && investment > 0) {
            return true
        }
        if (!allowance) return false
        if (new BigNumber(allowance)?.comparedTo(investAmount) === 1 || requestedApproval) {
            // setRequestedApproval(false)
            return true
        } else {
            return false
        }
    }

    const isApproveDone = function (investment: any, poolState: any) {
        if (poolState === 'CLAIM_OPEN' && investment && investment > 0) {
            return true
        }
        if (!allowance) return false
        if (new BigNumber(allowance)?.comparedTo(investAmount) === 1) {
            return true
        } else {
            return false
        }
    }

    const isApproveOrInvestOrClaimInProgress = function () {
        if (requestedInvest || requestedApproval || requestedClaim) {
            return true
        } else {
            return false
        }
    }

    const isWhitelistInProgress = function () {
        return requestedWhitelist
    }

    useEffect(() => () => console.log("unmount"), []);

    const handleApprove = useCallback(async () => {
        try {
            if (requestedApproval) return
            if (formValidity !== 'VALID') {
                showFormValidityToast(formValidity)
                return
            }
            setRequestedApproval(true)
            let tx = await onApprove(poolDetails.poolAddress)
            if (tx) {
                toast.success("Approve request has been submitted")
                setApproveTxhash(tx.hash)
                let res = await tx.wait(1)
                setRequestedApproval(!(res.status))
            } else {
                setRequestedApproval(false)
            }
        } catch (e: any) {
            setRequestedApproval(false)
            toast.error(e.message)
        }
    }, [onApprove, formValidity, poolDetails])

    const handleInvest = useCallback(async () => {
        try {
            if (requestedApproval) return
            if (formValidity !== 'VALID') {
                showFormValidityToast(formValidity)
                return
            }
            setRequestedInvest(true)
            console.log(investAmount)
            let tx = await onInvest(
                poolDetails.poolAddress,
                new BigNumber(investAmount).times(new BigNumber(10).pow(6).toString()).toString()
            )
            if (tx) {
                toast.success("Invest request has been submitted")
                setInvestTxhash(tx.hash)
                let res = await tx.wait(1)
                setRequestedInvest(!(res.status))
                fetchDetails(false)
                setAddMoreModalShow(false)
            } else {
                // toast.error("hello")
                setRequestedInvest(false)
            }
        } catch (e: any) {
            setRequestedInvest(false)
            toast.error(e.message)
        }
    }, [onInvest, formValidity, investAmount, poolDetails])

    const handleListingClaim = useCallback(async () => {
        try {
            if (requestedClaim) return
            setRequestedClaim(true)
            let tx = await onListingClaim(
                poolDetails.poolAddress
            )
            if (tx) {
                toast.success("Claim request has been submitted")
                setClaimTxhash(tx.hash)
                let res = await tx.wait(1)
                setRequestedClaim(!(res.status))
                fetchDetails(false)
            } else {
                setRequestedClaim(false)
            }
        } catch (e: any) {
            setRequestedClaim(false)
            toast.error(e.message)
        }
    }, [onListingClaim, formValidity, poolDetails])

    const handleVestingClaim = useCallback(async () => {
        try {
            if (requestedClaim) return
            setRequestedClaim(true)
            let tx = await onVestingClaim(
                poolDetails.poolAddress
            )
            if (tx) {
                toast.success("Claim request has been submitted")
                setClaimTxhash(tx.hash)
                let res = await tx.wait(1)
                setRequestedClaim(!(res.status))
                fetchDetails(false)
            } else {
                setRequestedClaim(false)
            }
        } catch (e: any) {
            setRequestedClaim(false)
            toast.error(e.message)
        }
    }, [onVestingClaim, formValidity, poolDetails])

    useEffect(() => {
        console.log(formValidity)
    }, [formValidity])

    useEffect(() => {
        console.log(poolDetails && poolDetails.usdt < investAmount, poolDetails && poolDetails!.usdt, investAmount)
        console.log(poolState, investAmount, poolDetails && poolDetails.maxGuaranteed)
        if (poolDetails && (poolState === 'OPEN_INVEST_STAKER' && ((investAmount + poolDetails.invetment) > (poolDetails.maxGuaranteed) || investAmount < 0.01))) {
            setFormValidity('INVLAID_AMOUNT')
        } else if (poolDetails && (poolState === 'OPEN_INVEST_PUBLIC' && ((investAmount + poolDetails.invetment) < poolDetails.minInvest || (investAmount + poolDetails.invetment) > poolDetails.maxInvest))) {
            setFormValidity('INVLAID_AMOUNT')
        } else if (poolDetails && (poolDetails.usdt < investAmount)) {
            setFormValidity('INSUFFICIENT_BALANCE')
        } else {
            setFormValidity('VALID')
        }
        if (investAmount === 0) {
            setFormValidity('INVLAID_AMOUNT')
        }
    }, [investAmount, poolDetails, poolState, addMoreModalShow])

    useEffect(() => {
        if (whitelistAddress) {
            let addressArray = whitelistAddress.split("\n")
            console.log(addressArray)
            let allValidAddress = true
            for (let i = 0; i < addressArray.length; i++) {
                if (!isAddress(addressArray[i])) {
                    allValidAddress = false
                    break;
                }
            }
            if (!allValidAddress) {
                setWhitelistFormValidity('INVLAID_ADDRESS')
            } else {
                setWhitelistFormValidity('VALID')
            }
        } else {
            setWhitelistFormValidity('INVLAID_ADDRESS')
        }
    }, [whitelistAddress])

    useEffect(() => {
        if (!poolDetails) { return }
        if (poolDetails.tokenPrice <= 0) { return }
        let amount = investAmount / poolDetails.tokenPrice
        setReciveAmount(amount)
    }, [investAmount, poolDetails])

    useEffect(() => {
        if (poolDetails)
            poolStateCalculate(poolDetails)
    }, [poolDetails])

    // useEffect(() => {
    //     alert(poolState)
    // }, [poolState])

    const showFormValidityToast = function (formValidity: any) {
        switch (formValidity) {
            case 'INVLAID_AMOUNT':
                toast.error('Please enter valid token amount')
                break;
            case 'INSUFFICIENT_BALANCE':
                toast.error('You don\'t have sufficient usdt')
                break;
        }
    }

    const showWhitelistFormValidityToast = function (formValidity: any) {
        switch (formValidity) {
            case 'INVLAID_ADDRESS':
                toast.error('Please enter valid address')
                break;
        }
    }

    // const viewOnExplorer = function() {
    //     console.log(requestedInvest, requestedApproval, requestedClaim)
    //     return requestedInvest || requestedApproval || requestedClaim
    // }

    const poolStateCalculate = function (poolDetails: any) {
        // 'TO_BE_OPEN' | 'OPEN_INVEST_STAKER' | 'OPEN_INVEST_PUBLIC' | 'OPEN_FILLED' | 'CLOSED' | 'CLAIM_TO_BE_OPEN' | 'CLAIM_OPEN' | 'CANCELLED'
        let poolOpen = Number(poolDetails.openTime) * 1000
        let poolClose = Number(poolDetails.closeTime) * 1000
        let poolOpenStaker = Number(poolDetails.openTime) * 1000 + (3 * 60 * 60 * 1000)
        let lpLiquidityAddingTime = Number(poolDetails.lpLiquidityAddingTime) * 1000
        let nowDate = Date.now()
        if (nowDate < poolOpen) {
            setPoolState('TO_BE_OPEN')
        } else if (nowDate < poolOpenStaker && nowDate < poolClose) {
            setPoolState('OPEN_INVEST_STAKER')
        } else if (nowDate > poolOpenStaker && nowDate < poolClose) {
            setPoolState('OPEN_INVEST_PUBLIC')
        } else if (nowDate > poolClose && nowDate < lpLiquidityAddingTime) {
            setPoolState('CLOSED')
        } else if (nowDate > lpLiquidityAddingTime && !poolDetails.lpLiquidityAdded) {
            setPoolState('CLAIM_TO_BE_OPEN')
        }

        if ((Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) >= poolDetails.hardCap) {
            setPoolState('CLOSED')
        }

        if (poolDetails.lpLiquidityAdded) {
            setPoolState('CLAIM_OPEN')
        }
    }

    const fetchDetails = useCallback(async (isLoader = true) => {
        try {
            if (isLoader) dispatch({ type: 'SET_LOADING', payload: true })
            let poolDetails: any = await onFetchPoolDetails((2).toString())
            let USDTBalance = await onUSDTBalanceFetch()

            // let solana = await on
            if (poolDetails && USDTBalance)
                poolDetails["usdt"] = USDTBalance.balance
            console.log(poolDetails)
            if (isLoader) dispatch({ type: 'SET_LOADING', payload: false })
            setPoolDetails(poolDetails)
        } catch (e) {
            dispatch({ type: 'SET_LOADING', payload: false })
        }
    }, [onFetchPoolDetails])

    const handleMaxButtonClick = function (poolState: any) {
        if (poolState !== "OPEN_INVEST_PUBLIC") {
            setInvestAmount(poolDetails.maxGuaranteed)
        } else if (poolState === "OPEN_INVEST_PUBLIC") {
            setInvestAmount(poolDetails.maxInvest)
        }
    }

    const getClaimableVesting = function (poolDetails: any) {
        let totalClaimable = (poolDetails.invetment / poolDetails.tokenPrice)
        let initialClaimable = (poolDetails.invetment / poolDetails.tokenPrice) * (poolDetails.listingReleasePercent / 100)
        let vestedToken = totalClaimable - initialClaimable
        let claimableVestCount = Math.floor(((Date.now() / 1000) - Number(poolDetails.lpLiquidityAddingTime)) / (Number(poolDetails.vestingWindow) * 60))

        if (claimableVestCount > 0) {
            claimableVestCount = claimableVestCount > Number(poolDetails.vestingIteration) ? Number(poolDetails.vestingIteration) : claimableVestCount
            claimableVestCount = claimableVestCount - (Number(poolDetails.claimTracker) - 1)
            console.log(claimableVestCount)
            if (claimableVestCount > 0) {
                return (vestedToken / Number(poolDetails.vestingIteration) * claimableVestCount)
            } else {
                return 0
            }
        } else {
            return 0
        }
    }

    return (
        <section className="section illustration-section-01">
            <div className="container">
                {/* <SectionHeader tag="h1" data={sectionHeader} className="center-content" /> */}
                {!account && <h5 className="connect-wallet-notice">Connect wallet to continue</h5>}
                {state.loading && account && !poolDetails && <div className="loading-container loading-container-pool-details">
                    <DotLoader color={"#C2EAFF"} loading={state.loading} css={override} />
                </div>}
                {/* {account && !poolDetails && <DotLoader color={"#C2EAFF"} loading={state.loading} css={override} />} */}
                {account && poolDetails &&
                    <>
                        <div className="pool-details-page mt-32">
                            <div className="pool-brand-details">
                                <div className="pool-img">
                                    <img src={poolDetails.linkLogo} />
                                </div>
                                <div className="pool-title">
                                    <div className="title-status">
                                        <h4 className="m-0">{poolDetails.saleTitle}</h4>
                                        <div className="pool-status">
                                            {Number(poolDetails.openTime * 1000) >= Date.now() &&
                                                ((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected < poolDetails.hardCap)
                                                    || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) < poolDetails.hardCap)) && (
                                                    <h6 className="m-0 text-xxs interval">
                                                        Opens In {formatDuration(intervalToDuration({ start: new Date(Number(poolDetails.openTime * 1000)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                                                    </h6>
                                                )}

                                            {(((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected >= poolDetails.hardCap)
                                                || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) >= poolDetails.hardCap)))
                                                && (
                                                    <h6 className="m-0 text-xxs filled">
                                                        Filled
                                                    </h6>
                                                )}
                                            {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && ((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected < poolDetails.hardCap)
                                                || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) < poolDetails.hardCap)) && (
                                                    <h6 className="m-0 text-xxs open">
                                                        Open
                                                    </h6>
                                                )}
                                            {Number(poolDetails.closeTime * 1000) < Date.now() && ((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected < poolDetails.hardCap)
                                                || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) < poolDetails.hardCap)) && (
                                                    <h6 className="m-0 text-xxs close">
                                                        Close
                                                    </h6>
                                                )}
                                        </div>
                                    </div>

                                    <p className="m-0 pool-description">{poolDetails.description}</p>
                                    <div className="d-flex justify-content-between flex-wrap flex-md-column flex-lg-row social-center">
                                        <div className="pool-social mt-16">
                                            <ul className="list-reset d-flex mb-0 social-list align-items-center">
                                                <li>
                                                    <a href={poolDetails.linkWebsite}>
                                                        <svg
                                                            width="20"
                                                            height="19"
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <title>Website</title>
                                                            <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
                                                        </svg>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={`https://telegram.me/${poolDetails.linkTelegram}`}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 26 21.667"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <title>Telegram</title>
                                                            <path id="telegram-1" d="M10.2,16.28l-.43,6.049a1.5,1.5,0,0,0,1.2-.582l2.885-2.757,5.978,4.378c1.1.611,1.869.289,2.165-1.009L25.925,3.973h0c.348-1.621-.586-2.254-1.654-1.857L1.207,10.945c-1.574.611-1.55,1.489-.268,1.886l5.9,1.834,13.7-8.57c.645-.427,1.231-.191.749.236Z" />
                                                        </svg>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={`https://twitter.com/${poolDetails.linkTwitter}`}>
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 16 16"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <title>Twitter</title>
                                                            <path
                                                                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <Form className="d-flex flex-column mt-16 align-items-center">
                            <Form.Group className="m-0 d-flex flex-md-column flex-lg-row align-items-center" controlId="formBasicEmail">
                                <div className="d-flex flex-row">
                                    <Form.Label className="text-nowrap">Your Solana Address : </Form.Label>
                                    <Form.Control
                                        className="form-input mr-0"
                                        type="text"
                                        placeholder="Enter Your Solana Address"
                                        disabled={!isSolanaEditable}
                                        value={solanaAddress}
                                        onChange={({ target: { value } }) => {
                                            setSolanaAddress(value)
                                        }} />
                                </div>
                                <Button
                                    className="button button-primary "
                                    variant="primary"
                                    onClick={() => {
                                        isSolanaEditable ? handleSolanaSubmit() : setSolanaEditable(true)
                                    }}
                                >
                                    {isSolanaEditable ? "Submit" : "Edit"}
                                </Button>
                            </Form.Group>
                        </Form> */}
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="deatils-table liquidity-details">
                                                <div className="table-title mb-16">
                                                    <h5 className="text-center m-0">Join Pool</h5>
                                                </div>
                                                <div className="details">
                                                    <div className="text-center">
                                                        <h6 className="m-0 mb-8">1 USDT = <span className="primary-color">{1 / Number(poolDetails.tokenPrice)} <a className="primary-color token-symbol-link" href="#">{poolDetails.tokenSymbol}</a></span></h6>
                                                    </div>
                                                    <div className="swap-progress">
                                                        <progress max="100" value={(poolDetails.totalCollected / poolDetails.hardCap * 100)}>
                                                            <div className="progress-bar">
                                                                <span style={{ width: poolDetails.totalCollected / poolDetails.hardCap * 100 }}>Progress: {(poolDetails.totalCollected / poolDetails.hardCap * 100).toFixed(2)}%</span>
                                                            </div>
                                                        </progress>
                                                        <div className="progress-markups d-flex justify-content-between">
                                                            <p className="text-xxs mt-0">{(poolDetails.totalCollected / poolDetails.hardCap * 100).toFixed(2)}% (Min {(poolDetails.softCap / poolDetails.hardCap * 100).toFixed(2)}%)</p>
                                                            <p className="text-xxs mt-0">{poolDetails.totalCollected} / {poolDetails.hardCap} USDT</p>
                                                        </div>
                                                    </div>
                                                    {/* {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && poolDetails.totalCollected < poolDetails.hardCap && ( */}


                                                    <div className="swap-interface">
                                                        <div className="form-content">
                                                            <Form className="d-flex flex-column">
                                                                <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                                                    <Form.Label>{poolDetails.invetment > 0 ? "You invested (USDT)" : "Enter Amount you want to Invest (USDT)"}</Form.Label>
                                                                    <Form.Control
                                                                        className="form-input"
                                                                        type="number"
                                                                        placeholder="Enter USDT"
                                                                        value={poolDetails.invetment > 0 ? poolDetails.invetment : Number(investAmount)}
                                                                        disabled={poolDetails.invetment > 0}
                                                                        onChange={({ target: { value } }) => {
                                                                            let val = Number(value)
                                                                            setInvestAmount(val)
                                                                        }} />
                                                                    {/* <Form.Text className="text-muted">
                                                                We'll never share your email with anyone else.
                                                            </Form.Text> */}
                                                                </Form.Group>
                                                                {poolDetails.invetment <= 0 && (<Button
                                                                    className="button button-primary button-max"
                                                                    variant="primary"
                                                                    onClick={() => handleMaxButtonClick(poolState)}>MAX</Button>)}
                                                                <Form.Group className="mb-3 d-flex flex-column" controlId="formBasicPassword">
                                                                    <Form.Label>You will get ({poolDetails.tokenSymbol})</Form.Label>
                                                                    <Form.Control
                                                                        className="form-input"
                                                                        type="text"
                                                                        placeholder={poolDetails.tokenSymbol}
                                                                        value={poolDetails.invetment > 0 ? (poolDetails.invetment / poolDetails.tokenPrice) + ' ' + poolDetails.tokenSymbol : Number(reciveAmount) + ' ' + poolDetails.tokenSymbol}
                                                                        disabled={true} />
                                                                </Form.Group>
                                                                {allowance && !isApproveDoneOrInProgress(poolDetails.invetment, poolState) && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    onClick={() => handleApprove()}
                                                                    disabled={isApproveDoneOrInProgress(poolDetails.invetment, poolState)}>
                                                                    Approve
                                                                </Button>}
                                                                {(!allowance || isApproveOrInvestOrClaimInProgress()) && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    // onClick={() => handleApprove()}
                                                                    disabled={true}>
                                                                    <PropagateLoader color={"#C2EAFF"} loading={true} />
                                                                </Button>}
                                                                {allowance && isApproveDone(poolDetails.invetment, poolState) && !requestedInvest && poolDetails.invetment <= 0 && !poolDetails.claimed && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    onClick={() => handleInvest()}
                                                                    disabled={!isApproveDone(poolDetails.invetment, poolState)}>
                                                                    Buy
                                                                </Button>}
                                                                {allowance && isApproveDone(poolDetails.invetment, poolState) && !requestedInvest && poolDetails.invetment > 0 && poolDetails.invetment < poolDetails.maxGuaranteed && (poolState === "OPEN_INVEST_STAKER") && !poolDetails.claimed && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    onClick={() => {
                                                                        setInvestAmount(0)
                                                                        setAddMoreModalShow(true)
                                                                    }}
                                                                    disabled={!isApproveDone(poolDetails.invetment, poolState)}>
                                                                    Add More
                                                                </Button>}
                                                                {allowance && isApproveDone(poolDetails.invetment, poolState) && !requestedInvest && poolDetails.invetment > 0 && poolDetails.invetment < poolDetails.maxInvest && (poolState === "OPEN_INVEST_PUBLIC") && !poolDetails.claimed && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    onClick={() => {
                                                                        setInvestAmount(0)
                                                                        setAddMoreModalShow(true)
                                                                    }}
                                                                    disabled={!isApproveDone(poolDetails.invetment, poolState)}>
                                                                    Add More
                                                                </Button>}
                                                                {allowance && isApproveDone(poolDetails.invetment, poolState) && !requestedInvest && poolDetails.invetment > 0 && poolDetails.invetment >= poolDetails.maxGuaranteed && (poolState === "OPEN_INVEST_STAKER") && !poolDetails.claimed && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    // onClick={() => handleListingClaim()}
                                                                    onClick={() => window.open("https://claim.maticlaunch.org/vaulthill", "_blank")}
                                                                    disabled={false}>
                                                                    Claim
                                                                </Button>}
                                                                {allowance && isApproveDone(poolDetails.invetment, poolState) && !requestedInvest && poolDetails.invetment > 0 && poolDetails.invetment >= poolDetails.maxInvest && (poolState === "OPEN_INVEST_PUBLIC") && !poolDetails.claimed && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    // onClick={() => handleListingClaim()}
                                                                    onClick={() => window.open("https://claim.maticlaunch.org/vaulthill", "_blank")}
                                                                    disabled={false}>
                                                                    Claim
                                                                </Button>}
                                                                {allowance && isApproveDone(poolDetails.invetment, poolState) && !requestedClaim && poolDetails.invetment > 0 && (poolState !== "OPEN_INVEST_STAKER" && poolState !== "OPEN_INVEST_PUBLIC") && poolDetails.claimTracker === "0" && <Button
                                                                    className="button button-primary "
                                                                    variant="primary"
                                                                    // onClick={() => handleListingClaim()}
                                                                    onClick={() => window.open("https://claim.maticlaunch.org/vaulthill", "_blank")}
                                                                    disabled={false}>
                                                                    Claim
                                                                </Button>}
                                                                {/* {allowance && isApproveDone() && !requestedClaim && poolDetails.invetment > 0 && Number(poolDetails.claimTracker) > 0 && Number(poolDetails.claimTracker) < (Number(poolDetails.vestingIteration) + 1) && <Button
                                                                className="button button-primary "
                                                                variant="primary"
                                                                onClick={() => handleVestingClaim()}
                                                                disabled={!isApproveDone() || poolState !== 'CLAIM_OPEN' || getClaimableVesting(poolDetails) === 0}>
                                                                Claim
                                                            </Button>}
                                                            {allowance && isApproveDone() && !requestedClaim && poolDetails.invetment > 0 && Number(poolDetails.claimTracker) === (Number(poolDetails.vestingIteration) + 1) && <Button
                                                                className="button button-primary "
                                                                variant="primary"
                                                                // onClick={() => handleClaim()}
                                                                disabled={true}>
                                                                Claimed Sucessfully
                                                            </Button>} */}
                                                            </Form>
                                                            {requestedInvest && investTxhash && <div className="view-on-explorer">
                                                                <a className="text-xxs" target="_blank" href={getChainDetails(chainId)?.url + "/tx/" + investTxhash}>View On Explorer</a>
                                                            </div>}
                                                            {requestedApproval && approveTxhash && <div className="view-on-explorer">
                                                                <a className="text-xxs" target="_blank" href={getChainDetails(chainId)?.url + "/tx/" + approveTxhash}>View On Explorer</a>
                                                            </div>}
                                                            {requestedClaim && claimTxhash && <div className="view-on-explorer">
                                                                <a className="text-xxs" target="_blank" href={getChainDetails(chainId)?.url + "/tx/" + claimTxhash}>View On Explorer</a>
                                                            </div>}
                                                        </div>
                                                        {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && ((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected < poolDetails.hardCap)
                                                            || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) < poolDetails.hardCap)) && !poolDetails.isWhitlisted && poolState === "OPEN_INVEST_STAKER" &&
                                                            <div className="overlay-error-content">
                                                                <h6>You are not whitelisted</h6>
                                                            </div>}
                                                        {poolDetails.invetment <= 0 && ((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected >= poolDetails.hardCap)
                                                            || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) >= poolDetails.hardCap)) &&
                                                            <div className="overlay-error-content">
                                                                <h6>Sale is filled</h6>
                                                            </div>}
                                                        {Number(poolDetails.openTime * 1000) >= Date.now() && ((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected < poolDetails.hardCap)
                                                            || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) < poolDetails.hardCap)) &&
                                                            <div className="overlay-error-content">
                                                                <div className="d-flex flex-column whitelist-container">
                                                                    <h6 className="open-in">Opens In {formatDuration(intervalToDuration({ start: new Date(Number(poolDetails.openTime * 1000)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}</h6>
                                                                    {poolDetails.isWhitlisted && (<h6 className="whitelisted-in">You are whitelisted, Keep your fund ready</h6>)}
                                                                    {!poolDetails.isWhitlisted && (<h6 className="whitelisted-in">You are not whitelisted</h6>)}
                                                                </div>
                                                            </div>}
                                                        {poolDetails.invetment <= 0 && Number(poolDetails.closeTime * 1000) < Date.now() && ((poolState === 'OPEN_INVEST_STAKER' && poolDetails.totalCollected < poolDetails.hardCap)
                                                            || (poolState !== 'OPEN_INVEST_STAKER' && (Number(poolDetails.totalCollected) + Number(poolDetails.minInvest)) < poolDetails.hardCap)) &&
                                                            <div className="overlay-error-content">
                                                                <h6>Sale is Closed</h6>
                                                            </div>}



                                                        {/* {Number(poolDetails.openTime * 1000) >= Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs interval">
                                                                Opens In {formatDuration(intervalToDuration({ start: new Date(Number(poolDetails.openTime * 1000)), end: new Date() }), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'], delimiter: ' ' })}
                                                            </h6>
                                                        )}
                                                        {poolDetails.totalCollected >= poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs filled">
                                                                Filled
                                                            </h6>
                                                        )}
                                                        {Number(poolDetails.openTime * 1000) < Date.now() && Number(poolDetails.closeTime * 1000) > Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs open">
                                                                Open
                                                            </h6>
                                                        )}
                                                        {Number(poolDetails.closeTime * 1000) < Date.now() && poolDetails.totalCollected < poolDetails.hardCap && (
                                                            <h6 className="m-0 text-xxs close">
                                                                Close
                                                            </h6>
                                                        )} */}
                                                        {/* {poolDetails.claimed &&
                                                        <div className="overlay-error-content">
                                                            <h6>Thank you for participation, you claimed you token sucessfully</h6>
                                                        </div>} */}

                                                    </div>
                                                    {/* )} */}
                                                    <div className="row mt-16">
                                                        {poolState !== 'OPEN_INVEST_PUBLIC' && poolState !== 'CLAIM_OPEN' && (
                                                            <>
                                                                {/* <div className="col-6 text-center border-right">
                                                                <p className="text-xs m-0">Minimum</p>
                                                                <p>{nFormatter(poolDetails.minGuaranteed, 1)} USDT</p>
                                                            </div> */}
                                                                <div className="col-6 text-center border-right">
                                                                    <p className="text-xs m-0">Your allocation</p>
                                                                    <p>{(poolDetails.maxGuaranteed / poolDetails.tokenPrice).toFixed(4)} {poolDetails.tokenSymbol}</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="text-xs m-0">Listing Unlock</p>
                                                                    {poolState === 'TO_BE_OPEN' && (<p>{poolDetails.listingReleasePercent}%</p>)}
                                                                    {poolState !== 'TO_BE_OPEN' && (<p>{poolDetails.invetment > 0 ? ((poolDetails.invetment / poolDetails.tokenPrice) * poolDetails.listingReleasePercent / 100).toFixed(4) + ' ' + poolDetails.tokenSymbol : (Number(reciveAmount) * poolDetails.listingReleasePercent / 100).toFixed(4) + ' ' + poolDetails.tokenSymbol}</p>)}
                                                                </div>
                                                            </>
                                                        )}
                                                        {poolState === 'OPEN_INVEST_PUBLIC' && (
                                                            <>
                                                                <div className="col-6 text-center border-right">
                                                                    <p className="text-xs m-0">Minimum</p>
                                                                    <p>{nFormatter(poolDetails.minInvest, 1)} USDT</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="text-xs m-0">Maximum</p>
                                                                    <p>{nFormatter(poolDetails.maxInvest, 1)} USDT</p>
                                                                </div>
                                                            </>
                                                        )}
                                                        {poolState === 'CLAIM_OPEN' && (
                                                            <>
                                                                <div className="col-6 text-center border-right">
                                                                    <p className="text-xs m-0">Your Token</p>
                                                                    <p>{(poolDetails.invetment / poolDetails.tokenPrice).toFixed(4) + ' ' + poolDetails.tokenSymbol}</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="text-xs m-0">Total Airdropped </p>
                                                                    <p>{((poolDetails.invetment / poolDetails.tokenPrice) * 0 / 100).toFixed(4) + ' ' + poolDetails.tokenSymbol}</p>
                                                                    {/* {(<p>
                                                                    {poolDetails.claimTracker === "0"
                                                                        ? ((poolDetails.invetment / poolDetails.tokenPrice) * poolDetails.listingReleasePercent / 100).toFixed(4) + ' ' + poolDetails.tokenSymbol
                                                                        : getClaimableVesting(poolDetails)}
                                                                </p>)} */}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="details-table-wrapper">
                                                <div className="deatils-table pool-details">
                                                    <div className="table-title mb-16">
                                                        <h5 className="text-center m-0">Governance</h5>
                                                    </div>
                                                    <div className="governance-details">
                                                        <div className="details">
                                                            <div className="text-center">
                                                                <h6 className="m-0 mb-8">Yes {'>'} No Vote = <span className="primary-color">TRUE</span></h6>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6 text-center border-right">
                                                                    <p className="text-xs m-0">Yes Vote</p>
                                                                    <p>0 MTCL</p>
                                                                </div>
                                                                <div className="col-6 text-center">
                                                                    <p className="text-xs m-0">No Vote</p>
                                                                    <p>0 MTCL</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="overlay-message d-flex align-items-center">
                                                            <img src={require('../../assets/images/animated-qualified.gif')} width="40" className="mr-4" />
                                                            <h6>Qualified Pool</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="deatils-table pool-details">
                                                <div className="table-title">
                                                    <h5 className="text-center m-0">Pool Details</h5>
                                                </div>
                                                <div className="details">
                                                    <div className="d-flex justify-content-between">
                                                        <p>Open Time</p>
                                                        <p>{new Date(Number(poolDetails.openTime) * 1000).toUTCString()}</p>
                                                        {/* <p>{format(new Date(Number(poolDetails.openTime) * 1000), 'hh:mm a, MMMM dd yyyy (xxxxx)')}</p> */}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Close Time</p>
                                                        <p>{new Date(Number(poolDetails.closeTime) * 1000).toUTCString()}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Hardcap</p>
                                                        <p>{poolDetails.hardCap} USDT</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Vesting</p>
                                                        <small className="small">Listing: {poolDetails.listingReleasePercent}%; 10% monthly, 5% last month </small>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Access</p>
                                                        <p>{(Number(poolDetails.openTime) + (3 * 60 * 60) > Date.now() / 1000) ? "MTCL STAKER" : "PUBLIC"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="deatils-table liquidity-details">
                                                <div className="table-title">
                                                    <h5 className="text-center m-0">Liquidity Details</h5>
                                                </div>
                                                <div className="details">
                                                    <div className="d-flex justify-content-between">
                                                        <p>Liquidity Rate</p>
                                                        <p>{poolDetails.lpListingPrice} USDT</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Liquidity Allocation</p>
                                                        {poolDetails.poolWithoutLiquidity && <p>{poolDetails.lpLiquidityPercentageAllocation}k</p>}
                                                        {!poolDetails.poolWithoutLiquidity && <p>{poolDetails.lpLiquidityPercentageAllocation}%</p>}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Lock Duration</p>
                                                        <p>{poolDetails.lpLPTokensLockDurationInDays} days</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Listing Date</p>
                                                        <p>{new Date(Number(poolDetails.lpLiquidityAddingTime) * 1000).toDateString()}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p>Listing</p>
                                                        <small>Uniswap, MEXC, Bitmart, Coinsbit</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            show={addMoreModalShow}
                            onHide={() => setAddMoreModalShow(false)}
                            size="lg"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                                    Add More
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-32">
                                {poolDetails && (
                                    <Form className="d-flex flex-column">
                                        <Form.Group className="mb-2 d-flex flex-column" controlId="formBasicEmail">
                                            <Form.Label>{"Enter Amount you want to Invest (USDT)"}</Form.Label>
                                            <Form.Control
                                                className="form-input"
                                                type="number"
                                                placeholder="Enter USDT"
                                                value={Number(investAmount).toString()}
                                                // disabled={poolDetails.invetment > 0}
                                                onChange={({ target: { value } }) => {
                                                    let val = Number(value)
                                                    if (val) setInvestAmount(val)
                                                    else setInvestAmount(0)
                                                }} />
                                        </Form.Group>
                                        {(<Button
                                            className="button button-primary button-max"
                                            variant="primary"
                                            onClick={() => setInvestAmount(poolDetails.maxInvest - poolDetails.invetment)}>MAX</Button>)}
                                        <Form.Group className="mb-3 d-flex flex-column" controlId="formBasicPassword">
                                            <Form.Label>You will get ({poolDetails.tokenSymbol})</Form.Label>
                                            <Form.Control
                                                className="form-input"
                                                type="text"
                                                placeholder={poolDetails.tokenSymbol}
                                                value={Number(reciveAmount) + ' ' + poolDetails.tokenSymbol}
                                                disabled={true} />
                                        </Form.Group>
                                        {allowance && isApproveDone(poolDetails.invetment, poolState) && !requestedInvest && <Button
                                            className="button button-primary "
                                            variant="primary"
                                            onClick={() => handleInvest()}
                                            disabled={false}>
                                            Buy
                                        </Button>}
                                        {(!allowance || isApproveOrInvestOrClaimInProgress()) && <Button
                                            className="button button-primary "
                                            variant="primary"
                                            // onClick={() => handleApprove()}
                                            disabled={true}>
                                            <PropagateLoader color={"#C2EAFF"} loading={true} />
                                        </Button>}
                                    </Form>
                                )}
                            </Modal.Body>
                        </Modal>
                    </>
                }
            </div>
        </section>
    )
}

export default IDOPoolDetailVaultHillSection