import React from 'react';
import IDOPoolDetailAssetFiSection from '../components/sections/IDOPoolDetailsAssetFi';

class IDOPoolDetailsAssetFi extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.title = "AssetFi IDO | MaticLaunch"
  }
  render() {
    return (
      <IDOPoolDetailAssetFiSection id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default IDOPoolDetailsAssetFi;