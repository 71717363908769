import React from 'react';
import IDOPoolDetailGameUpSection from '../components/sections/IDOPoolDetailsGameUp';

class IDOPoolDetailsGameUp extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.title = "GameUp IDO | MaticLaunch"
  }
  render() {
    return (
      <IDOPoolDetailGameUpSection id={this.props.match.params.id} className="illustration-section-01" />
    );
  }
}

export default IDOPoolDetailsGameUp;